import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  addCardCompany,
  setCvc,
  setExp,
  setModalCardOpen,
  setNumber,
} from "../../redux/company";
import { getCardType, isValidCardNumber } from "../../utils";
import useFirestore from "../../hook/firestore";
import useProfileUpdate from "../../hook/company";
import { CiCreditCard1 } from "react-icons/ci";
import { SpinnerLoadData } from "../utils/spinner-load";

export default function ModalCard() {
  const { callableFunction, loading } = useFirestore("companies");
  const [payementMethod, setPaymentMethods] = useState();
  const [error, setError] = useState(false);
  const { profile } = useProfileUpdate();
  const [exp, setExp] = useState(null);
  const [cvc, setCvc] = useState(null);
  const [number, setNumber] = useState(null);
  const { modalCardOpen } = useSelector((state) => state.companySlice);
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);

  const icon = {
    Visa: require("../../assets/img/card/visa.png"),
    MasterCard: require("../../assets/img/card/mastecard.png"),
    Amex: require("../../assets/img/card/amex.png"),
    Discover: require("../../assets/img/card/discover.png"),
  };

  function handleExp() {
    const isValid = new RegExp("^(0[1-9]|1[0-2])/[0-9]{2}$").test(exp);
    if (!isValid) {
      setExp("");
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const [exp_month, exp_year] = exp.split("/");

    if (!exp || !number || !cvc || !isValidCardNumber(number)) {
      setError(true);
      return null;
    }

    await callableFunction("addCard", {
      stripeCustomer: profile.stripeCustomer,
      source: {
        object: "card",
        number: number,
        exp_month: exp_month,
        exp_year: exp_year,
        cvc: cvc,
      },
    }).then(() => dispatch(setModalCardOpen()));
  }

  useEffect(() => {
    if (profile && profile.stripeAccount) {
      (async () => {
        const result_payments = await callableFunction(
          "retrieveStripeCustomerPaymentMethod",
          {
            stripeCustomer: profile.stripeCustomer,
          }
        );

        setPaymentMethods(result_payments.paymentMethods.data);
      })();
    }
  }, [profile, modalCardOpen]);

  const handleDelete = async (cardId) => {
    const deletion = await callableFunction("deleteCard", {
      stripeCustomer: profile.stripeCustomer,
      cardId,
    }).then(() => dispatch(setModalCardOpen()));
  };

  return (
    <Transition.Root show={modalCardOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(setModalCardOpen())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative  mb-20 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {loading && <SpinnerLoadData />}
                <form onSubmit={handleSubmit}>
                  <fieldset className="">
                    <legend className="block text-sm font-medium leading-6 text-gray-900">
                      Card Details
                    </legend>
                    {payementMethod &&
                      payementMethod.map((item) => (
                        <div className=" grid grid-cols-12">
                          <div
                            onClick={() => dispatch(setModalCardOpen())}
                            className=" border-y p-2 col-span-11 text-sm font-semibold my-4 text-sky-900 flex justify-between"
                          >
                            <div className=" inline-flex gap-2 items-center">
                              <CiCreditCard1 className="size-6 text-gray-500" />
                              <span>{item.card.brand}</span>
                            </div>

                            <div className=" inline-flex items-center gap-3">
                              <span>****{item.card.last4}</span>
                            </div>
                          </div>
                          <div
                            onClick={() => handleDelete(item.id)}
                            className=" border-y p-2 col-span-1 cursor-pointer text-sm font-semibold my-4 text-sky-900 flex justify-between"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6 text-red-800"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>
                      ))}
                    <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                      <div className="relative">
                        <label htmlFor="card-number" className="sr-only">
                          Card number
                        </label>
                        <input
                          type="text"
                          name="card-number"
                          id="card-number"
                          onFocus={() => setError(false)}
                          required
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          className={`relative block w-full rounded-none pr-12 rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ${
                            !error ? "ring-gray-300" : "ring-red-600"
                          }  placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6`}
                          placeholder="Card number"
                        />
                        {number && (
                          <img
                            className="w-9 h-9 absolute top-0 right-2"
                            src={icon[getCardType(number)]}
                            alt=""
                          />
                        )}
                      </div>

                      <div className="flex -space-x-px">
                        <div className="w-1/2 min-w-0 flex-1">
                          <label
                            htmlFor="card-expiration-date"
                            className="sr-only"
                          >
                            Expiration date
                          </label>
                          <input
                            type="text"
                            name="card-expiration-date"
                            id="card-expiration-date"
                            pattern="^(0[1-9]|1[0-2])/[0-9]{2}$"
                            value={exp}
                            required
                            onChange={(e) => setExp(e.target.value)}
                            onBlur={handleExp}
                            className={`relative block w-full rounded-none rounded-bl-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ${
                              !error ? "ring-gray-300" : "ring-red-600"
                            }  placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6`}
                            placeholder="MM / YY"
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <label htmlFor="card-cvc" className="sr-only">
                            CVC
                          </label>
                          <input
                            type="text"
                            name="card-cvc"
                            required
                            value={cvc}
                            pattern="^[0-9]{3}$"
                            onChange={(e) => setCvc(e.target.value)}
                            id="card-cvc"
                            className={`relative block w-full rounded-none rounded-br-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ${
                              !error ? "ring-gray-300" : "ring-red-600"
                            }  placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6`}
                            placeholder="CVC"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => dispatch(setModalCardOpen())}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

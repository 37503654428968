import { PlusIcon, ArchiveBoxIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import GoBack from "../../../components/utils/goBack";
import useIndustries from "../../../hook/industries";
import { Eye } from "lucide-react";
import Details from "./Details";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";

function Item({ item }) {
  return (
    <Link
      to={`/industries?id=${encodeURIComponent(item.id)}`}
      className={`rounded-lg p-8 border border-dashed   hover:bg-slate-50 hover:text-sky-600 inline-flex w-full `}
    >
      <h1 className="text-base font-medium w-full shrink">{item.id}</h1>
      <span>
        <Eye />
      </span>
    </Link>
  );
}

function Industries() {
  const { readAllIndustries, createIndustry, loading } =
    useIndustries("industries");
  const [industries, setIndustries] = useState([]);
  const [name, setName] = useState();

  const fetchIndustries = async () => {
    const result = await readAllIndustries();
    setIndustries(result);
  };

  const handleAddIndustry = async (e) => {
    e.preventDefault();

    await createIndustry(
      {
        name,
      },
      [],
      []
    );
  };

  useEffect(() => {
    fetchIndustries();

    const unsubscribe = onSnapshot(query(collection(db, "industries")), () => {
      fetchIndustries();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="px-4 text-sky-800 text-2xl">Industries</div>
      <hr className="my-3" />
      <div className=" grid grid-cols-12  divide-x ">
        <div className=" col-span-4 h-[90vh] overflow-y-scroll relative">
          {loading && <SpinnerLoadData />}
          <div className="flex flex-col gap-4 px-4">
            <form onSubmit={handleAddIndustry} className="relative">
              <button
                type="submit"
                className=" absolute top-1/2 -translate-y-1/2 right-2 bg-sky-100 text-sky-800 font-semibold px-8 text-sm rounded-lg py-1"
              >
                Add
              </button>
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder="Industry..."
                className=" border-none text-sm p-4 w-full rounded-lg bg-gray-100"
              />
            </form>

            {industries.map((item) => (
              <Item item={item} />
            ))}
          </div>
        </div>
        <div className="col-span-8 h-[90vh] px-4 flex justify-center items-center">
          <Details />
        </div>
      </div>
    </>
  );
}

export default Industries;

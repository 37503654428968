import React, { useState, useEffect } from "react";

import useFirestore from "../../hook/firestore";
import { classNames } from "../utils/className";

const AutoSuggest = ({ selected, setSelected, setForm, form }) => {
  const { callableFunction } = useFirestore("jobs");
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (input.length > 2) {
        try {
          const result = await callableFunction("getFilteredSubsectors", {
            input,
          });

          if (result.suggestions.length === 0) {
            result.suggestions = [
              {
                name: "No data to load ! (Choose an available item)",
                not_clickable: true,
              },
            ];
          }

          setSuggestions(result.suggestions);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [input]);

  const handleClickSuggestion = (e) => {
    setInput(e.name);
    setSelected(e);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setInput(value);

    setSelected("");
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        value={input}
        name="jobName"
        required
        className={classNames(
          " p-4 w-full text-sm border-none ring-0  outline-0 rounded-lg "
        )}
        onChange={(e) => handleChange(e)}
        placeholder="Warehouser"
      />

      {suggestions.length > 0 && !selected && (
        <div className=" absolute   w-full mt-2 z-50 p-4 bg-gray-50 rounded-lg border  max-h-52 overflow-y-scroll ">
          <ul className="flex flex-col gap-3   ">
            {suggestions.map((suggestion, index) => (
              <li
                onClick={() =>
                  !suggestion.not_clickable
                    ? handleClickSuggestion(suggestion)
                    : undefined
                }
                className={classNames(
                  "hover:bg-white p-3 truncate hover:text-sky-900 rounded-lg  text-sm font-semibold ",
                  suggestion.not_clickable
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                )}
                key={index}
              >
                {suggestion.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AutoSuggest;

import React, { useEffect, useRef, useState } from "react";
import useFirestore from "../../hook/firestore";
import Textarea from "./textArea";
import { classNames } from "../utils/className";
import { auth, db } from "../../firebase";
import Roles from "../../Enum/UserRoles";
import { useSelector } from "react-redux";
import GenericImg from "../layout/generic-user-img";
import moment from "moment";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { SpinnerLoadData } from "../utils/spinner-load";
import { mood } from "../../Enum/mood";
import GoBack from "../utils/goBack";

function MessagesItem({ item, user, sender = false, last = false }) {
  const { role } = useSelector((state) => state.authenticationSlice);
  const isCandidate = role === Roles.candidate;
  const isAdmin = role === Roles.admin;
  const isCompany = [Roles.company, Roles.member, Roles.manager];

  const imageUrl = !isAdmin
    ? isCandidate
      ? user && user.logoUrl
      : isCompany
      ? user && user.avatarUrl
      : null
    : user?.role === Roles.candidate
    ? user && user.avatarUrl
    : user?.role === Roles.company
    ? user && user.logoUrl
    : null;

  return (
    <div
      className={classNames(
        "flex gap-2.5 items-start",
        sender && " justify-end"
      )}
    >
      {!sender && (
        <div className=" size-8 bg-slate-50 rounded-full shrink-0">
          {imageUrl ? (
            <img
              className=" size-full object-cover rounded-full"
              src={imageUrl}
              alt="img"
            />
          ) : (
            <GenericImg />
          )}
        </div>
      )}

      <div className="flex flex-col gap-1 w-full max-w-[320px]">
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          {!sender && (
            <span className="text-xs font-semibold text-sky-900 dark:text-white">
              {user.name}
            </span>
          )}

          <span className={classNames("text-xs  font-normal text-gray-500 ")}>
            {moment.unix(item.creatAt).local().fromNow()}
          </span>
        </div>
        <div
          className={classNames(
            "flex flex-col leading-1.5 p-4 border-gray-200 ",
            !sender && "bg-gray-100 rounded-e-xl rounded-es-xl",
            sender && "bg-sky-900  rounded-s-xl rounded-se-xl",
            item.mood === mood.loved &&
              " bg-gradient-to-br from-pink-400  to-sky-600",
            item.mood === mood.excited &&
              "bg-gradient-to-br from-red-500 to-sky-600 ",
            item.mood === mood.happy &&
              "bg-gradient-to-br from-green-400 to-sky-600 ",
            item.mood === mood.sad &&
              "bg-gradient-to-br from-yellow-400  to-sky-600",
            item.mood === mood.thumbsy &&
              "bg-gradient-to-br from-blue-500 to-sky-600 "
          )}
        >
          <p
            className={classNames(
              "text-sm font-normal",
              !sender && "text-gray-900",
              sender && "text-sky-50",
              item.mood && " text-sky-50"
            )}
          >
            {item.content}
          </p>
        </div>

        {sender && last && (
          <span className="text-xs text-end font-normal text-gray-500 dark:text-gray-400">
            Delivered
          </span>
        )}
      </div>
    </div>
  );
}

function Details(props) {
  const messagesEndRef = useRef(null);
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;

  const {
    readMessagesFromConversation,
    markLastMessageAsRead,
    markMessagesAsRead,
    loading,
  } = useFirestore("conversations");
  const [messages, setMessages] = useState([]);

  const fetchConversation = async () => {
    const result = await readMessagesFromConversation(props.select);
    await markMessagesAsRead(props.select, currentUser.uid);
    await markLastMessageAsRead(props.select, currentUser.uid);
    setMessages(result);
  };

  useEffect(() => {
    if (props.select) {
      fetchConversation();

      const messagesRef = collection(
        db,
        "conversations",
        props.select,
        "messages"
      );

      const q = query(messagesRef);

      // Mise en place de l'écoute des changements en temps réel
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          fetchConversation();
        },
        (err) => {
          console.error(err);
        }
      );

      // Nettoyage : désinscrire l'écoute lorsqu'on démonte le composant
      return () => unsubscribe();
    }
  }, [props.select]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages.length]);

  return (
    <div {...props}>
      {loading && messages.length === 0 && <SpinnerLoadData />}
      <h1 className=" shrink-0  text-sm font-semibold text-sky-900 flex  items-center   max-xl:justify-between justify-center bg-gray-100 p-2 rounded ">
        <span
          onClick={() => props.setSelected()}
          className="max-xl:block hidden hover:bg-sky-200/35 px-8 rounded-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
            />
          </svg>
        </span>
        <span>{props.user.name || "ADMIN"}</span>
      </h1>
      <div className="  flex flex-col flex-1 h-full overflow-y-auto gap-2    py-2 pt-6   ">
        {messages &&
          messages.map((item, index) => (
            <MessagesItem
              id={props.select}
              item={item}
              user={props.user}
              sender={item.senderId === currentUser.uid}
              last={messages.length === index + 1}
            />
          ))}

        <div ref={messagesEndRef}></div>
      </div>

      <div className=" shrink-0 ">
        <Textarea id={props.select} />
      </div>
    </div>
  );
}

export default Details;

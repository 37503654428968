import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import Stars from "../../../components/rating/Stars";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCandidate } from "../../../redux/candidate";
import { baseUrl } from "../../../utils";
import Map from "./Map";
import GenericImg from "../../../components/layout/generic-user-img";
import { setCandidateLaunch } from "../../../redux/application";

export default function Candidate() {
  const { candidates } = useSelector((state) => state.candidateSlice);
  const dispatch = useDispatch();
  const fetchAllCandidates = useCallback(() => {
    dispatch(fetchAllCandidate());
  }, [dispatch]);

  useEffect(() => {
    fetchAllCandidates();
  }, [fetchAllCandidates]);

  return (
    <div className="h-96 w-full ">
      <Map home />
    </div>
  );
}

export function CandidateItem({ item }) {
  const dispatch = useDispatch();
  return (
    <div className=" w-96 flex-shrink-0 p-2 rounded-lg  bg-white  border">
      <div className=" grid  p-4 grid-cols-12">
        <div className=" col-span-4 ">
          <div className="size-16 rounded-full border ">
            {item.avatarUrl && (
              <img
                src={item.avatarUrl}
                alt=""
                className="size-full object-cover rounded-full"
              />
            )}

            {!item.avatarUrl && <GenericImg />}
          </div>
        </div>

        <div className="   flex flex-col gap-1  col-span-8 ">
          <h1 className="text-sm  font-semibold   rounded-lg">{item.name}</h1>
          <h1 className="text-xs font-semibold  text-gray-600 rounded-lg">
            {item.title}
          </h1>
          <span className="  rounded-lg flex">
            <Stars number={item.averageStars} />
          </span>
          <div className=" flex justify-end">
            <a
              className="text-sm text-sky-800 hover:underline cursor-pointer"
              onClick={() => dispatch(setCandidateLaunch(item.id))}
            >
              Details
            </a>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-2 gap-4 justify-end">
        <a
          href={`mailto:${item.email}`}
          className="rounded inline-flex items-center gap-2 text-sky-900 bg-white px-2 py-1 text-sm font-semibold justify-center shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-5"
          >
            <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
            <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
          </svg>
          <span>Email</span>
        </a>
        <a
          href={`tel:${item.phone}`}
          className="rounded inline-flex items-center gap-2 text-sky-900 bg-white px-2 py-1 text-sm font-semibold justify-center shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-5"
          >
            <path
              fillRule="evenodd"
              d="M15 3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V5.56l-4.72 4.72a.75.75 0 1 1-1.06-1.06l4.72-4.72h-2.69a.75.75 0 0 1-.75-.75Z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
              clipRule="evenodd"
            />
          </svg>

          <span>Call</span>
        </a>
      </div>
    </div>
  );
}

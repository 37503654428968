import React, { useEffect, useState } from "react";

import { CandidateItem } from "./candidate";
import { useSelector } from "react-redux";
import useShift from "../../../hook/shift";
import Chart from "./chart";
import ListBoxChart from "./listboxchart";
import UpcomingShifts from "./upcoming-shift";
import Map from "./Map";
import useFirestore from "../../../hook/firestore";
import { where } from "firebase/firestore";
import useProfileUpdate from "../../../hook/company";

const optionsViewType = [
  { id: 1, name: "Live jobs", type: "jobs" },
  { id: 2, name: "Applications", type: "applications" },
  { id: 3, name: "Shifts", type: "shifts" },
];
const optionsViewTime = [
  { id: 1, name: "Current year", year: new Date().getFullYear() },
  { id: 2, name: "Last Year", year: new Date().getFullYear() - 1 },
  { id: 3, name: "2 Years ago", year: new Date().getFullYear() - 2 },
];

function ChartItem() {
  const { profile } = useProfileUpdate();
  const [dataType, setDataType] = useState("jobs");
  const [year, setYear] = useState(new Date().getFullYear());
  const [count, setCount] = useState({
    jobs: 0,
    applications: 0,
    shifts: 0,
  });
  const [number, setNumber] = useState(0);
  const [upcoming, setUpcoming] = useState(0);

  const { calculateShiftEarnings, loading: loadNumber } =
    useFirestore("shifts");
  const fetchSumTotalSpent = async () => {
    const result = await calculateShiftEarnings(
      [
        where("job.company", "==", profile.id),
        where("invoice.success", "==", true),
        where("paid", "==", true),
      ],
      {
        earning: true,
        hour: false,
      }
    );

    setNumber(result);
  };
  const fetchSumTotalSpentUpcoming = async () => {
    const result = await calculateShiftEarnings(
      [
        where("job.company", "==", profile.id),
        where("invoice.success", "==", true),
        where("paid", "==", false),
      ],
      {
        earning: true,
        hour: false,
      }
    );

    setUpcoming(result);
  };

  const { countDocumentsWithQuery: jobsCount } = useFirestore("jobs");
  const { countDocumentsWithQuery: applicationCount } =
    useFirestore("applications");
  const { countDocumentsWithQuery: shiftsCount } = useFirestore("shifts");

  const fetchCounts = async () => {
    const query = [where("job.company", "==", profile.id)];

    const jc = await jobsCount([where("company.id", "==", profile.id)]);
    const apc = await applicationCount(query);
    const sc = await shiftsCount(query);

    const params = {
      jobs: jc,
      applications: apc,
      shifts: sc,
    };

    setCount(params);
  };

  console.log(count);

  useEffect(() => {
    if (profile) {
      fetchCounts();
      fetchSumTotalSpent();
      fetchSumTotalSpentUpcoming();
    }
  }, [profile]);

  return (
    <div className="  md:h-[35rem]   rounded-lg border p-2">
      <div className=" grid md:grid-cols-3 gap-2 *:rounded-lg ">
        <div
          onClick={() => setDataType("jobs")}
          className=" col-span-1 gap-2 flex items-center cursor-pointer  p-2 "
        >
          <div className=" bg-gray-100 p-2 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-violet-700 to-sky-700">
              {count.jobs}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Live jobs posted</h1>
          </div>
        </div>
        <div
          onClick={() => setDataType("applications")}
          className=" col-span-1 gap-2 flex items-center cursor-pointer  p-2 "
        >
          <div className=" bg-gray-100 p-2 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-violet-700 to-sky-700">
              {count.applications}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Applications</h1>
          </div>
        </div>
        <div
          onClick={() => setDataType("shifts")}
          className=" col-span-1 gap-2 flex items-center cursor-pointer  p-2 "
        >
          <div className=" bg-gray-100 p-2 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-violet-700 to-sky-700">
              {count.shifts}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Shifts</h1>
          </div>
        </div>
      </div>
      <hr className="my-2" />
      <div className=" grid sm:grid-cols-2 gap-2 *:rounded-lg ">
        <div className=" col-span-full gap-2 flex items-center  p-2 ">
          <div className=" bg-gray-100 p-2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-800"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
              />
            </svg>
          </div>
          <div className=" flex flex-col">
            <h1 className="text-3xl block font-bold bg-clip-text bg-gradient-to-tr text-transparent from-green-400 to-sky-800">
              {profile && [profile?.currency?.code, number].join(" ")}
            </h1>

            <div className="inline-flex text-xs  font-semibold text-green-600">
              <div className=" size-4  rounded-full bg-gray-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                  />
                </svg>
              </div>

              <span>
                {" "}
                {profile && [profile?.currency?.code, upcoming].join(" ")}
              </span>
            </div>
            <h1 className=" text-[0.65rem] font-normal text-gray-400">Spent</h1>
          </div>
        </div>
      </div>
      <div className="lg:h-[56%] sm:h-[50%] h-28 relative ">
        <Chart dataType={dataType} year={year} />
      </div>

      <hr />
      <div className=" grid grid-cols-2 w-full">
        <div className=" col-span-1 gap-2 flex items-center w-full  p-2 ">
          <ListBoxChart
            type="type"
            setDataType={setDataType}
            options={optionsViewType}
          />
        </div>
        <div className=" col-span-1 gap-2 flex items-center justify-end w-full  p-2 ">
          <ListBoxChart
            type="year"
            setYear={setYear}
            options={optionsViewTime}
          />
        </div>
      </div>
    </div>
  );
}

function Dashboard() {
  const { readAllDocuments } = useFirestore("candidates");
  const [candidates, setCandidates] = useState([]);

  const fetchAllCandidates = async () => {
    const result = await readAllDocuments();
    setCandidates(result);
  };

  useEffect(() => {
    fetchAllCandidates();
  }, []);

  return (
    <div className=" p-4 ">
      <div className="xl:grid-cols-12   flex flex-col gap-4 md:grid">
        <div className=" xl:col-span-6">
          <ChartItem />
        </div>
        <div className=" xl:col-span-6">
          <UpcomingShifts />
        </div>
      </div>
      <div className=" w-full flex gap-4 py-2  overflow-x-scroll">
        {candidates && candidates.map((item) => <CandidateItem item={item} />)}
      </div>

      <div className=" w-full py-2 h-[35rem]">
        <Map />
      </div>
    </div>
  );
}

export default Dashboard;

"use client";

import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import SelectList from "./listbox";
import useProfileUpdate from "../../hook/candidate";

const options = [
  { id: 1, name: "AM" },
  { id: 2, name: "PM" },
  { id: 3, name: "FULL" },
  { id: 4, name: "OFF" },
];

export default function ModalAv({ open, setOpen, checked, date }) {
  const [selected, setSelected] = useState(options[2]);
  const { updateAvailability } = useProfileUpdate();

  useEffect(() => {
    if (checked) {
      setSelected(options[checked - 1]);
      return;
    }
  }, [checked]);

  const handleSelected = (name) => {
    updateAvailability({
      [date]: { av: name, date },
    });

    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10 sm:hidden">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden sm:mb-0 mb-16 w-full rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Availability
                </DialogTitle>
              </div>
            </div>
            <div className="mt-5 py-8 sm:mt-6 ">
              <select
                id="location"
                name="location"
                onChange={(e) => handleSelected(e.target.value)}
                defaultValue="Canada"
                className="mt-2 block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600  text-sm sm:leading-6"
              >
                {options.map((option) => (
                  <option
                    selected={option.id === selected.id}
                    value={option.name}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

"use client";

import { useEffect, useState } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import useProfileUpdate from "../../hook/candidate";
import { classNames } from "../utils/className";

const options = [
  { id: 1, name: "AM" },
  { id: 2, name: "PM" },
  { id: 3, name: "FULL" },
  { id: 4, name: "OFF" },
];

export default function SelectList({ date, checked }) {
  const [selected, setSelected] = useState(options[2]);
  const { updateAvailability } = useProfileUpdate();

  useEffect(() => {
    if (checked) {
      setSelected(options[checked - 1]);
      return;
    }
  }, [checked]);

  const handleSelected = (e) => {
    updateAvailability({
      [date]: { av: e.name, date },
    });
  };

  return (
    <Listbox value={selected} onChange={handleSelected}>
      <div className=" mt-2 w-full">
        <ListboxButton
          className={classNames(
            "relative w-full cursor-default rounded-md  py-1.5 pl-3 pr-10 text-left   text-xs font-normal  sm:text-sm ",
            checked === 3 && "bg-green-50 text-green-600",
            checked === 1 && "bg-indigo-50 text-indigo-600",
            checked === 2 && "bg-orange-50 text-orange-600",
            checked === 4 && "bg-red-50 text-red-600"
          )}
        >
          <span className="block truncate">{selected.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-green-600"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-60 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {options.map((person) => (
            <ListboxOption
              key={person.id}
              value={person}
              className="group relative w-fit cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
            >
              <span className="block truncate font-normal group-data-[selected]:font-semibold">
                {person.name}
              </span>

              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDZP1x-QpcanuM6dRWvmIfuFmBiViRVd5g",
  authDomain: "tempme-web-app.firebaseapp.com",
  projectId: "tempme-web-app",
  storageBucket: "tempme-web-app.appspot.com",
  messagingSenderId: "731583034684",
  appId: "1:731583034684:web:f46f419035c9c596981a39",
  measurementId: "G-JM6TBJK5FP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app, "gs://tempme-web-app.appspot.com");
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { storage, db, auth, functions };

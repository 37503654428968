import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setShowWithTimeout = createAsyncThunk(
  "ToastSlice/setShowWithTimeout",
  async (_, { dispatch }) => {
    dispatch(setShow());
    setTimeout(() => {
      dispatch(hideShow());
    }, 5000);
  }
);

export const ToastSlice = createSlice({
  name: "ToastSlice",
  initialState: {
    show: false,
    requestStatus: {},
  },
  reducers: {
    setShow: (state) => {
      state.show = true;
    },
    handleRequestStatus: (state, action) => {
      state.requestStatus = {
        success: action.payload.success,
        error: action.payload.error,
        content: action.payload.content,
      };
    },
    hideShow: (state) => {
      state.show = false;
    },
  },
});

export const { setShow, hideShow, handleRequestStatus } = ToastSlice.actions;

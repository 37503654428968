import { ArrowRightLeft, Banknote, Hourglass, SquareX } from "lucide-react";
import React, { useEffect, useState } from "react";
import useFirestore from "../../../hook/firestore";
import { useDispatch } from "react-redux";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";
import { classNames } from "../../../components/utils/className";
import { Link } from "react-router-dom";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import moment from "moment";

function Item({ item }) {
  const { readDocument } = useFirestore("jobs");
  const [job, setJob] = useState({});

  useEffect(() => {
    (async () => {
      const result = await readDocument(item.job.id);

      setJob(result);
    })();
  }, [item]);

  return (
    <div className="border gap-4 rounded-lg p-4 relative  grid ">
      <div className=" absolute px-8 py-2 text-sm font-semibold text-sky-900 rounded-bl-lg rounded-tr-lg bg-gray-100 top-0 right-0">
        <span>
          {moment.unix(item.job.startAt.unix).local().format("YYYY/MM/DD")}
        </span>
      </div>
      <div>
        <h1 className=" font-medium text-sky-800">{item.candidate.name}</h1>
        <h1 className=" text-xs text-gray-600">
          {job.company?.name} - {item.job.name}
        </h1>
      </div>

      <div className="flex items-end flex-col">
        <h1 className="font-semibold text-sm text-sky-800">
          {[
            job.currency,
            (parseFloat(job.totalHour) * parseFloat(job.hourlyRate)).toFixed(2),
          ].join(" ")}
        </h1>
        <h1 className="font-medium text-gray-600 text-xs">
          {" "}
          {[
            job.currency,
            (
              parseFloat(job.totalHour) *
              parseFloat(job.hourlyRate) *
              0.05
            ).toFixed(2),
          ].join(" ")}
        </h1>
      </div>

      <div className="flex flex-wrap items-center gap-4 justify-end">
        {item.invoice.id && (
          <a>
            <Link
              to={`invoices?id=${item.invoice.id}`}
              className="  text-sm bg-green-50 px-4 py-2 rounded-lg text-green-800"
            >
              View transaction
            </Link>
          </a>
        )}

        <div>
          <h1
            className={classNames(
              "text-sm  p-2 rounded-lg ",
              !item.invoice.refund
                ? "text-sky-800 bg-sky-50"
                : item.invoice.refund
                ? "text-red-800 bg-red-50"
                : " text-sky-800 bg-sky-50 animate-pulse"
            )}
          >
            {item.paid ? (
              <Banknote className=" size-5" />
            ) : item.invoice.refund ? (
              <SquareX className=" size-5" />
            ) : (
              <Hourglass className=" size-5" />
            )}
          </h1>
        </div>

        <a href={item.invoice?.pdf_link}>
          <h1 className="  inline-flex items-center gap-2  text-sm bg-indigo-50 p-2 rounded-lg text-indigo-800">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>

            <span>Invoice</span>
          </h1>
        </a>
        <div className=" p-2 rounded-full bg-gray-100 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="currentColor"
            className="size-4 text-sky-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

function Transactions() {
  const dispatch = useDispatch();
  const { callableFunction, loading } = useFirestore("shifts");

  const [shifts, setShits] = useState([]);
  const [name, setName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [filterBy, setFilterBy] = useState("*");

  const fetchShifts = async () => {
    const result = await callableFunction("filterShifts", {
      name,
      status: filterBy,
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
    });
    const { data } = result;

    setShits(data);
  };

  useEffect(() => {
    fetchShifts();

    const unsubscribe = onSnapshot(query(collection(db, "shifts")), () => {
      fetchShifts();
    });

    return () => {
      unsubscribe();
    };
  }, [name, startDate, filterBy]);

  return (
    <div className=" my-4 bg-white shadow rounded-lg border p-4 border-dashed relative">
      {loading && <SpinnerLoadData />}
      <div className="flex  items-center gap-4 text-sky-800">
        <ArrowRightLeft className=" size-5" />
        <h1 className="text-sm font-semibold ">Transactions</h1>
      </div>
      <div className=" grid  md:grid-cols-2 gap-4 my-4 ">
        <input
          placeholder="search..."
          onChange={(e) => setName(e.target.value)}
          className={"border-none bg-gray-100 rounded-lg text-sm"}
          type="text"
        />

        <input
          onChange={(e) => setStartDate(e.target.value)}
          className={"border-none bg-gray-100 rounded-lg text-sm"}
          type="date"
        />
      </div>
      <hr className="my-3" />
      <div className="flex flex-col gap-4 max-h-[30rem] overflow-y-scroll">
        {!loading && shifts && shifts.map((item) => <Item item={item} />)}
      </div>
    </div>
  );
}

export default Transactions;

import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Dashboard from "./Dashboard/index";
import Users from "./Users";

import Finance from "./finance";
import Invoices from "./invoices";
import Profile from "./profile";
import Industries from "./utils/Industries";
import Messages from "../../components/Messages";
import Inquires from "./contact";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { SpinnerLoadData } from "../../components/utils/spinner-load";

function Router() {
  return <Outlet />;
}

function LogOutFunc() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const logout = async () => {
    setLoading(true);
    await signOut(auth)
      .then(() => {
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        throw new Error(error);
      });
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <div className=" h-[90vh]  w-full relative">
      {loading && <SpinnerLoadData />}
    </div>
  );
}

function AdminRoute() {
  return (
    <TransitionGroup>
      <CSSTransition timeout={500} classNames="page">
        <Routes>
          <Route exact static path="/" element={<Router />}>
            <Route index element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/inquires" element={<Inquires />} />
            <Route path="/account-settings" element={<Profile />} />
            <Route path="/log-out" element={<LogOutFunc />} />
          </Route>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default AdminRoute;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../services/user";
import jwt_decode from "jwt-decode";
import Roles from "../Enum/UserRoles";
import { fetchCandidate } from "./candidate";
import { fetchCompany } from "./company";
import { auth } from "../firebase";
import { AuthErrorCodes, signInWithEmailAndPassword } from "firebase/auth";

const user = new User();

export const updateUser = createAsyncThunk(
  "authenticationSlice/updateUser",
  async ({ data }, thunkAPI) => {
    return await user.updateUser(data);
  }
);

export const connect = createAsyncThunk(
  "authenticationSlice/connect",
  async ({ email, password }, thunkAPI) => {
    await signInWithEmailAndPassword(auth, email, password).catch((error) => {
      thunkAPI.dispatch(setError(true));
    });
  }
);
export const reset = createAsyncThunk(
  "authenticationSlice/reset",
  async ({ email }) => {
    return await user.resetPassword(email);
  }
);

export const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: {
    isConnected: false,
    role: null,
    OwnerUID: null,
    error: null,
  },
  reducers: {
    setLogout: (state, action) => {},
    setIsConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setOwnerUID: (state, action) => {
      state.OwnerUID = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connect.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(connect.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { setLogout, setIsConnected, setRole, setOwnerUID, setError } =
  authenticationSlice.actions;

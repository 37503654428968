export const Shift = {
  PENDING: "pending",
  CANCEL: "cancel",
  OK: "ok",
  CONFIRMED: "confirmed",
  NO_SHOW: "no show",
  PAID: "paid",
  RATED: "rated",
  RATED_COMPANY: "rated company",
  RATED_CANDIDATE: "rated candidate",
  PENDING_PAYMENT: "Waiting for payment",
  PENDING_RATING: "Pending stars rating",
  PENDING_RATING_BOTH: "Pending stars rating for both",
  PENDING_RATING_COMPANY: "Pending stars rating for company",
  PENDING_RATING_CANDIDATE: "Pending stars rating for candidate",
  IN_PROGRESS: "in progress",
};

import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useJob from "../../../hook/job";
import Stars from "../../../components/rating/Stars";
import Details from "./details";
import { setId, setOpen } from "../../../redux/application";
import useProfileUpdate from "../../../hook/candidate";
import useFirestore from "../../../hook/firestore";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UsersIcon } from "lucide-react";
import { haversineDistance } from "../../../utils";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db, functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { classNames } from "../../../components/utils/className";
import ItemEmpty from "../../../components/utils/empty";
import useCurrentBreakpoint from "../../../hook/tailwindBreakPoint";

function JobItemLoading({ item }) {
  return (
    <div className=" p-4 py-6 shrink-0  bg-white  shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-xs text-end text-transparent">text</h1>
      </div>

      <div className=" space-y-1">
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100 text-base font-semibold">
          text
        </h1>
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100 text-xs font-semibold">
          {" "}
          text
        </h1>
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100  text-end font-semibold">
          {" "}
          text
        </h1>
      </div>

      <div className=" flex justify-end items-center text-transparent text-green-600  ">
        <div className=" bg-green-50 flex rounded-lg px-4 gap-2 py-1">
          <UsersIcon className=" size-4" />
          <h1 className="text-end text-sm font-semibold   ">1/3</h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-2  *:rounded-lg ">
        <div className="  text-transparent bg-slate-100 animate-pulse p-2  col-span-full text-xs font-semibold text-center">
          text
        </div>
        <div className=" text-transparent bg-slate-100 animate-pulse  p-2 col-span-full  text-xs font-semibold text-center">
          text
        </div>

        <div className=" text-transparent bg-slate-100 animate-pulse   text-xs p-2 font-semibold text-center ">
          text
        </div>
        <div className=" text-transparent bg-slate-100 animate-pulse    text-xs p-2 font-semibold text-center">
          text
        </div>

        <div className="  text-transparent bg-gray-100 animate-pulse border text-sm p-2 col-span-full">
          text
        </div>
        <div className="  p-2 grid grid-cols-2 text-sm gap-4  col-span-full">
          <button className="  text-transparent  text-center animate-pulse bg-white p-2 font-semibold  rounded-lg">
            More details
          </button>
          <button className="  text-transparent text-center bg-sky-600 hover:bg-sky-800 animate-pulse p-2 font-semibold rounded-lg">
            Quick Apply
          </button>
        </div>
      </div>
    </div>
  );
}
function JobItem({ item }) {
  const { readDocument } = useFirestore("companies");
  const { profile } = useProfileUpdate();
  const { readDocumentsWithQuery: jobApplied } = useFirestore("applications");
  const [wasApplied, setWasApplied] = useState();
  const [status, setStatus] = useState();
  const [company, setCompany] = useState(false);
  const dispatch = useDispatch();
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const navigate = useNavigate();
  const [distance, setDistance] = useState(0);

  const checkIfApplied = async () => {
    const result = await jobApplied([
      where("job.id", "==", item.id),
      where("candidate.id", "==", profile.id),
    ]);

    console.log(result);

    if (result.length > 0) {
      setWasApplied(true);
      setStatus(result[0].status);
    }
  };

  const handleDetail = (id) => {
    navigate(`?id=${id}`);
  };

  const handleApply = (e) => {
    e.stopPropagation();

    dispatch(setId(item.id));
    dispatch(setOpen(true));
  };

  const fetchCompany = async () => {
    const result = await readDocument(item.company.id);
    setCompany(result);
  };

  const CalculDistance = async () => {
    const result = await haversineDistance(
      latitude,
      longitude,
      item.location?.latitude,
      item.location?.longitude
    );
    if (result) {
      setDistance(result);
    }
  };

  useEffect(() => {
    if (profile) {
      fetchCompany();
      CalculDistance();
      checkIfApplied();
    }
  }, [item, profile]);

  return (
    <div className=" p-4 py-6 shrink-0  bg-white border shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-xs text-end">
          {moment.unix(item.creatAt).local().fromNow()}
        </h1>
      </div>

      <div className=" space-y-1">
        <h1 className="text-base font-semibold">{item.jobName}</h1>
        <h1 className="text-xs font-semibold">{item.company.name}</h1>
        <h1 className=" text-end font-semibold">
          {company && <Stars number={company.averageStars || 0} />}
        </h1>
      </div>

      <div className=" flex justify-end items-center text-green-600  ">
        <div className=" bg-green-50 flex rounded-lg px-4 gap-2 py-1">
          <UsersIcon className=" size-4" />
          <h1 className="text-end text-sm font-semibold   ">
            {[item.applicationCount || 0, item.nbCandidate].join("/")}
          </h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-2  *:rounded-lg ">
        <div className=" p-2 border col-span-full text-xs font-semibold text-center">
          {[item.startAt.time, item.endAt.time].join("-")}
        </div>
        <div className=" p-2 col-span-full border text-xs font-semibold text-center">
          {item.startDate}
        </div>

        <div className=" border text-xs p-2 font-semibold text-center ">
          {distance.toFixed(2)} Km Away
        </div>
        <div className="  border text-xs p-2 font-semibold text-center">
          {[item.currency, item.hourlyRate].join(" ")}/hr
        </div>

        <div className="  border text-sm p-2 col-span-full">
          {item.description}
        </div>
        <div
          onClick={() => handleDetail(item.id)}
          className="  p-2 grid grid-cols-2 text-sm gap-4  col-span-full"
        >
          <button className=" border text-center bg-white p-2 font-semibold  rounded-lg">
            More details
          </button>

          <button
            onClick={handleApply}
            disabled={wasApplied}
            className=" disabled:bg-gray-100 disabled:text-sky-600 text-center bg-sky-600 hover:bg-sky-800 p-2 font-semibold text-white rounded-lg"
          >
            {wasApplied ? status : " Quick Apply"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default function Jobs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const brk = useCurrentBreakpoint();
  const scrollContainerRef = useRef();
  const scrollToRef = useRef();
  const { readDocumentsWithQuery, loading, callableFunction } =
    useFirestore("jobs");
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const navigate = useNavigate();
  const { profile, load } = useProfileUpdate();
  const [jobs, setJobs] = useState([]);
  const [hrValue, setHrValue] = useState(null);
  const [hrtValue, setHrtValue] = useState(null);
  const [rValue, setRValue] = useState(null);
  const [rtValue, setRtValue] = useState(null);
  const [selected, setSelected] = useState(null);
  const [filterBy, setFilterBy] = useState(null);

  useEffect(() => {
    if (scrollContainerRef.current && scrollToRef.current) {
      // Calculer la position de défilement verticale
      const scrollPosition =
        scrollToRef.current.offsetTop - // Position de l'élément cible
        scrollContainerRef.current.clientHeight / 2 + // La moitié de la hauteur du conteneur
        scrollToRef.current.clientHeight / 2; // La moitié de la hauteur de l'élément cible

      scrollContainerRef.current.scrollTop = scrollPosition; // Appliquez la position de défilement
    }
  }, [scrollContainerRef, scrollToRef, jobs]);

  // Debounce handlers for hrValue and rValue
  useEffect(() => {
    const hrDebounceTimer = setTimeout(() => setHrValue(hrtValue), 1000);
    return () => clearTimeout(hrDebounceTimer);
  }, [hrtValue]);

  useEffect(() => {
    const rDebounceTimer = setTimeout(() => setRValue(rtValue), 1000);
    return () => clearTimeout(rDebounceTimer);
  }, [rtValue]);

  const handleHrChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setHrtValue(newValue);
  };

  const handleRChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setRtValue(newValue);
  };

  const subCategories = [
    { name: "Best rated", href: "setStarsAsc", for: "best_rated" },
    { name: "Near me", href: "setDistAsc", for: "near me" },
    { name: "Newest", href: "setCreatAt", for: "newest" },
  ];

  // Fetch jobs with a query to get the jobs
  const fetchJobs = useCallback(async () => {
    if (profile) {
      try {
        const result = await readDocumentsWithQuery([
          orderBy("creatAt", "desc"),
        ]);
        if (result.length > 0) {
          const firstJobId = result[0].id;

          setSelected(firstJobId);
          setJobs(result);
        } else {
          setSelected(null);
          setJobs([]);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    }
  }, [profile]);

  /*   useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "jobs"), fetchJobs);
    fetchJobs(); // Initial fetch
    return () => unsubscribe();
  }, [fetchJobs, db, latitude, longitude]); */

  // UseCallback for filter function
  const handleFilter = useCallback(async () => {
    try {
      const now = moment.utc().unix();
      const response = await callableFunction("filterJobs", {
        radius: rValue || "*",
        hourlyRate: hrValue || "*",
        latitude: latitude || profile.location.latitude || 0,
        longitude: longitude || profile.location.longitude || 0,
        filterBy: filterBy || "near me",
        unix: now,
      });

      const { data } = response;

      if (data && data.length > 0) {
        const firstJobId = data[0].id;

        if (!["sm", "xs"].includes(brk)) {
          setSelected(firstJobId);
        }
      } else {
        setSelected(null);
      }

      setJobs(data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  }, [rValue, hrValue, filterBy, latitude, longitude, profile]);

  useEffect(() => {
    handleFilter();

    const unsubscribe = onSnapshot(query(collection(db, "jobs")), () => {
      handleFilter();
    });

    return () => {
      unsubscribe();
    };
  }, [handleFilter]);

  return (
    <>
      <div className=" ">
        {/* Filter here */}

        <div
          className="p-4 text-sm  "
          onClick={(e) => {
            if (
              e.currentTarget.nextElementSibling.classList.contains("hidden")
            ) {
              e.currentTarget.nextElementSibling.classList.remove("hidden");

              e.currentTarget.nextElementSibling.classList.add("grid");
            } else {
              e.currentTarget.nextElementSibling.classList.add("hidden");

              e.currentTarget.nextElementSibling.classList.remove("grid");
            }
          }}
        >
          <div className=" w-full flex xl:hidden justify-between items-center bg-gray-100 p-4 rounded-lg ">
            {
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchParams({});
                }}
                className={!searchParams.get("id") && " max-xl:hidden "}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                  />
                </svg>
              </span>
            }
            <span className=" font-semibold">Filter</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
        </div>

        <div className=" hidden  xl:grid xl:grid-cols-3 px-4 gap-4 pb-6 ">
          <div className=" p-4 grid gap-4 rounded-lg bg-gray-100">
            <ul
              role="list"
              className=" flex flex-wrap   justify-between gap-2  text-xs sm:text-sm items-center  font-medium text-gray-900"
            >
              {subCategories.map((category) => (
                <li key={category.name}>
                  <button
                    type="button"
                    onClick={() => setFilterBy(category.for)}
                    className={`rounded-md ${
                      category.for === filterBy
                        ? "bg-sky-900 text-white hover:bg-sky-700"
                        : "bg-white text-gray-900 hover:bg-gray-100/50"
                    }  px-3.5 py-2.5 text-sm font-semibold    `}
                  >
                    {category.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className=" p-4 grid md:grid-cols-12 gap-4 items-center  rounded-lg bg-gray-100">
            <div className=" md:col-span-8">
              <input
                id="default-range"
                type="range"
                value={hrtValue}
                onChange={handleHrChange}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none  cursor-pointer dark:bg-gray-700"
              />
            </div>
            <div className=" md:col-span-4">
              <span className="inline-flex w-full    whitespace-nowrap justify-center rounded-md  px-2 py-1 text-sm font-semibold text-sky-900">
                {[hrtValue || "ALL", profile?.currency?.code + "/HR"].join(" ")}
              </span>
            </div>
          </div>
          <div className=" p-4 grid md:grid-cols-12 gap-4 items-center  rounded-lg bg-gray-100">
            <div className=" md:col-span-8">
              <input
                id="default-range"
                type="range"
                max={10000}
                value={rtValue}
                onChange={handleRChange}
                className="w-full h-2 bg-gray-200 rounded-full appearance-none cursor-pointer dark:bg-gray-700"
              />
            </div>
            <div className=" md:col-span-4">
              <span className="inline-flex w-full    whitespace-nowrap justify-center rounded-md  px-2 py-1 text-sm font-semibold text-sky-900">
                {[rtValue || "ALL", "Km"].join(" ")}
              </span>
            </div>
          </div>
        </div>

        <div className=" grid xl:grid-cols-12 gap-2  overflow-hidden">
          <div
            ref={scrollContainerRef}
            className={classNames(
              " xl:col-span-4 p-4 scroll-smooth  overflow-y-scroll flex flex-col gap-4  h-[calc(100vh-14rem)] ",
              searchParams.get("id") && " max-xl:hidden"
            )}
          >
            {jobs && !loading && jobs.length === 0 && <ItemEmpty />}

            {jobs &&
              jobs.map((item) => (
                <div
                  ref={searchParams.get("id") === item.id ? scrollToRef : null}
                  className={classNames(
                    searchParams.get("id") === item.id &&
                      "border border-sky-900 rounded-lg shadow-sm-light"
                  )}
                >
                  <JobItem item={item} />
                </div>
              ))}

            {(loading || !load) && [
              <JobItemLoading />,
              <JobItemLoading />,
              <JobItemLoading />,
            ]}
          </div>

          <div
            className={classNames(
              " block xl:col-span-8 px-4 h-[calc(100vh-14rem)] overflow-y-scroll",
              !searchParams.get("id") && "max-xl:hidden"
            )}
          >
            <Details id={selected} />
          </div>
        </div>
      </div>
    </>
  );
}

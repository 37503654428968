import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openDeleteModal } from "../../../redux/users";
import { auth } from "../../../firebase";
import useProfileUpdate from "../../../hook/company";
import useCurrencies from "../../../hook/currency";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { classNames } from "../../../components/utils/className";
import SuccessAlert, { ErrorAlert } from "../../../components/alert/alerts";
import GenericImg from "../../../components/layout/generic-user-img";
import Roles from "../../../Enum/UserRoles";
import useFirestore from "../../../hook/firestore";
import ModalEmail from "../../../components/modal/modalEmail";

function AccountDetails() {
  const {
    profile,
    formData,
    requestStatus,
    handleChange,
    location,
    handleChangeLocation,
    handleSave,
    load,
    password,
    handleSavePassword,
    handleChangePassword,
  } = useProfileUpdate();
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;
  const dispatch = useDispatch();
  const currencies = useCurrencies();
  const [file, setFile] = useState(null);

  const [open, setOpen] = useState(false);
  const { callableFunction, loading } = useFirestore("mail");
  const handleCheckEmail = async () => {
    await callableFunction("sendEmailVerificationCode", {
      email: profile.email,
      name: profile.name,
    }).then(() => setOpen(true));
  };

  return (
    <div className=" p-4">
      <div
        onClick={() => window.history.back()}
        className="flex w-fit justify-start gap-4 text-sky-900  items-center  cursor-pointer "
      >
        <span>
          <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            className="size-5 "
            color="currentColor"
          >
            <g id="_4" data-name="4">
              <path d="m492 276h-472a20 20 0 0 1 0-40h472a20 20 0 0 1 0 40z" />
              <path d="m189 445a19.937 19.937 0 0 1 -14.142-5.858l-169-169a20 20 0 0 1 0-28.284l169-169a20 20 0 0 1 28.284 28.284l-154.858 154.858 154.858 154.858a20 20 0 0 1 -14.142 34.142z" />
            </g>
          </svg>
        </span>
        <span>Account Details</span>
      </div>
      <hr className="my-5" />
      <div className=" grid text-sky-900 sm:grid-cols-2 md:grid-cols-3 gap-4 ">
        <div className="relative p-4 border flex flex-col items-center gap-8 rounded-lg">
          {!load && <SpinnerLoadData />}

          <div className=" flex flex-col items-center justify-center">
            <h1 className=" text-sm font-semibold ">
              {currentUser.displayName}
            </h1>
            <p className="text-xs">{formData.sector}</p>
          </div>

          <div className=" size-20  overflow-hidden rounded-full bg-gray-100">
            {(file && URL.createObjectURL(file)) || formData.logoUrl ? (
              <img
                src={(file && URL.createObjectURL(file)) || formData.logoUrl}
                alt="avatar"
                className="size-full object-cover rounded-full"
              />
            ) : (
              <GenericImg />
            )}
          </div>

          <div className="flex items-center">
            <button
              type="button"
              disabled={role === Roles.member}
              onClick={(e) => e.currentTarget.nextElementSibling.click()}
              className="rounded-md disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed flex items-center gap-3 bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
              Change logo
            </button>

            <input
              className="hidden"
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              accept="image/*"
            />
          </div>

          <div className=" grid w-full sm:grid-cols-2  gap-4 *:rounded-lg">
            <input
              placeholder="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className=" p-2 text-sm border-none  col-span-full  bg-gray-100"
            />
            <input
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className=" p-2 text-sm border-none col-span-full   bg-gray-100"
            />

            {!auth.currentUser.emailVerified && (
              <button
                onClick={handleCheckEmail}
                className=" bg-red-50 animate-pulse text-red-800 text-sm p-2  col-span-full"
              >
                Verify email
              </button>
            )}
          </div>

          <div className="w-full flex justify-end mt-auto">
            <button
              type="button"
              disabled={role === Roles.member}
              onClick={() => handleSave(file)}
              className="rounded-md disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
            >
              Save
            </button>
          </div>
        </div>
        <div className=" relative p-4 border flex flex-col items-center gap-8 rounded-lg">
          {!load && <SpinnerLoadData />}
          <h1 className="text-sm  font-semibold">Company Details</h1>

          <div className=" grid w-full sm:grid-cols-2  gap-4 *:rounded-lg">
            <input
              type="text"
              placeholder="Company Owner"
              name="owner"
              value={formData.owner}
              onChange={handleChange}
              className=" p-2 col-span-full text-sm border-none   bg-gray-100"
            />

            <input
              type="text"
              name="sector"
              value={formData.sector}
              onChange={handleChange}
              placeholder="Company Sector"
              className=" p-2 col-span-full text-sm border-none   bg-gray-100"
            />

            <select
              id="currency"
              onChange={handleChange}
              name="currency"
              className=" p-2 col-span-full text-sm border-none text-sky-900   bg-gray-100"
            >
              <option disabled selected>
                Company Currency
              </option>
              {currencies &&
                currencies.map((val) => (
                  <option
                    selected={profile?.currency?.code === val.code}
                    value={val.code}
                  >
                    {val.code}
                  </option>
                ))}
            </select>

            <input
              type="text"
              onChange={handleChange}
              name="crn"
              value={formData.crn}
              placeholder="Company registration number (C.R.N)"
              className=" p-2 col-span-full text-sm border-none   bg-gray-100"
            />

            <input
              placeholder="Company Vat"
              onChange={handleChange}
              name="vat"
              value={formData.vat}
              className=" p-2 text-sm border-none  col-span-full  bg-gray-100"
            />
          </div>

          <div className="w-full flex mt-auto justify-end">
            <button
              type="button"
              disabled={role === Roles.member}
              onClick={() => handleSave(file)}
              className="rounded-md disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
            >
              Save
            </button>
          </div>
        </div>

        <div className="  relative p-4 border flex flex-col items-center gap-8 rounded-lg">
          {!load && <SpinnerLoadData />}

          <h1 className="text-sm  font-semibold">Company Location</h1>

          <div className=" grid w-full   gap-4 *:rounded-lg">
            <select
              id="country"
              name="country"
              onChange={handleChange}
              required
              className=" p-2 text-sm border-none   bg-gray-100"
            >
              <option
                selected={!["UK", "KSA", "UAR"].includes(formData.country)}
                disabled
              >
                Country
              </option>
              <option selected={formData.country === "UK"} value={"UK"}>
                {" "}
                United Kingdom{" "}
              </option>
              <option selected={formData.country === "KSA"} value={"KSA"}>
                Kingdom of Saudi Arabia{" "}
              </option>
              <option selected={formData.country === "UAE"} value={"UAE"}>
                United Arab Emirates{" "}
              </option>
            </select>

            <input
              placeholder="Street"
              onChange={handleChangeLocation}
              name="street"
              value={location.street}
              className=" p-2 text-sm border-none   bg-gray-100"
            />
            <input
              placeholder="City"
              onChange={handleChangeLocation}
              name="city"
              value={location.city}
              className=" p-2 text-sm border-none   bg-gray-100"
            />
            <input
              placeholder="State"
              onChange={handleChangeLocation}
              name="state"
              value={location.state}
              className=" p-2 text-sm border-none   bg-gray-100"
            />
            <input
              placeholder="Zip"
              onChange={handleChangeLocation}
              name="zip"
              value={location.zip}
              className=" p-2 text-sm border-none   bg-gray-100"
            />
          </div>

          <div className="w-full mt-auto flex justify-end">
            <button
              type="button"
              disabled={role === Roles.member}
              onClick={() => handleSave(file)}
              className="rounded-md disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
            >
              Save
            </button>
          </div>
        </div>

        {![Roles.member, Roles.manager].includes(role) && (
          <form
            onSubmit={handleSavePassword}
            className="  relative p-4 border flex flex-col items-center gap-8 rounded-lg"
          >
            {!load && <SpinnerLoadData />}
            <h1 className="text-sm  font-semibold">Change password</h1>
            <SuccessAlert show={requestStatus.success} />
            <ErrorAlert
              show={requestStatus.error}
              content={requestStatus.content}
            />
            <div className=" grid w-full   gap-4 *:rounded-lg">
              <input
                type="password"
                placeholder="Current password"
                name="oldPassword"
                value={password.oldPassword}
                onChange={handleChangePassword}
                className=" p-2 text-sm border-none   bg-gray-100"
              />

              <input
                placeholder="New password"
                type="password"
                onChange={handleChangePassword}
                value={password.newPassword}
                name="newPassword"
                className={classNames(
                  " p-2 col-span-full text-sm    bg-gray-100",
                  requestStatus.error ? "border border-red-600" : "border-none"
                )}
              />
              <input
                type="password"
                placeholder="Confirm password"
                onChange={handleChangePassword}
                value={password.confirmPassword}
                name="confirmPassword"
                className={classNames(
                  " p-2 col-span-full text-sm    bg-gray-100",

                  requestStatus.error ? "border border-red-600" : "border-none"
                )}
              />
            </div>

            <div className="w-full mt-auto flex justify-end">
              <button
                type="submit"
                disabled={role === Roles.member}
                className="rounded-md  bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Save
              </button>
            </div>
          </form>
        )}

        {![Roles.member, Roles.manager].includes(role) && (
          <div className="  p-4 border flex flex-col items-center gap-8 rounded-lg">
            <h1 className="text-sm  font-semibold text-red-600">
              Delete account{" "}
            </h1>

            <p className="mt-1 text-sm text-center leading-6 text-gray-600">
              No longer want to use our service? You can delete your account
              here. This action is not reversible. All information related to
              this account will be deleted permanently.
            </p>

            <div className="w-full mt-auto  flex justify-center">
              <button
                type="button"
                onClick={() => dispatch(openDeleteModal())}
                className="rounded-md w-full bg-red-50 px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
              >
                Delete my account
              </button>
            </div>
          </div>
        )}
      </div>
      {profile && (
        <ModalEmail email={profile.email} open={open} setOpen={setOpen} />
      )}
    </div>
  );
}

export default AccountDetails;

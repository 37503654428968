import React from "react";
import useProfileUpdate from "../../hook/candidate";
import { BellIcon } from "lucide-react";
import { FireIcon } from "@heroicons/react/20/solid";

export function NotificationBellCandidate({ countNotification }) {
  const { profile, load } = useProfileUpdate();

  return (
    <div className="indicator">
      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
        {countNotification}
      </span>

      {profile && profile.hot_job && (
        <FireIcon
          className="h-6 w-6 animate-pulse text-orange-600"
          aria-hidden="true"
        />
      )}

      {profile && !profile.hot_job && (
        <BellIcon className="h-6 w-6 " aria-hidden="true" />
      )}
    </div>
  );
}

export function NotificationBellCompany({ countNotification }) {
  return (
    <div className="indicator">
      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
        {countNotification}
      </span>

      <BellIcon className="h-6 w-6" aria-hidden="true" />
    </div>
  );
}

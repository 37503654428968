import React from "react";
import SimpleCalendar from "../../../components/simple-calendar/calendar";

function Availability({ company = false, availabilities }) {

  return (
    <div className="w-full overflow-hidden">
      <SimpleCalendar availabilities={availabilities} company={company} />
    </div>
  );
}

export default Availability;

import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";

import Fuse from "fuse.js";
import { db } from "../../../firebase";
import { classNames } from "../../../components/utils/className";
import useFirestore from "../../../hook/firestore";

const AutoSuggest = ({ selected, setSelected, setForm, form }) => {
  const { callableFunction } = useFirestore("jobs");
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (input.length > 2) {
        try {
          const result = await callableFunction("getFilteredSubsectors", {
            input,
          });

          console.log(result);
          setSuggestions(result.suggestions);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [input]);

  const handleClickSuggestion = (e) => {
    setInput(e.name);
    setForm((prev) => ({
      ...prev,
      jobName: e.name,
    }));
    setSelected(e);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput(value);
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
    setSelected("");
  };

  return (
    <div className="relative">
      <input
        type="text"
        value={input}
        name="jobName"
        required
        className={classNames(
          " p-4 w-full text-sm border-none   rounded-lg  bg-gray-100"
        )}
        onChange={(e) => handleChange(e)}
        placeholder="Warehouser"
      />

      {suggestions.length > 0 && !selected && (
        <div className=" absolute  md:w-1/3 w-full mt-2 z-50 p-4 bg-gray-100 rounded-lg border  max-h-52 overflow-y-scroll ">
          <ul className="flex flex-col gap-3   ">
            {suggestions.map((suggestion, index) => (
              <li
                onClick={() => handleClickSuggestion(suggestion)}
                className="hover:bg-white p-3 truncate hover:text-sky-900 rounded-lg cursor-pointer text-sm font-semibold "
                key={index}
              >
                {suggestion.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AutoSuggest;

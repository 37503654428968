import { useState } from "react";
import { Jobs } from "../../../services/job";
import useFirestore from "../../../hook/firestore";
import { useNavigate } from "react-router-dom";

export default function useJobs(initialValues) {
  const [form, setForm] = useState(initialValues);
  const navigate = useNavigate();
  const { readDocumentsWithQuery } = useFirestore("jobs");
  const [jobs, setJobs] = useState([]);

  function handleInputChange(event) {
    const { name, value } = event.target;

    if (name.includes(".")) {
      const [outerKey, innerKey] = name.split(".");
      setForm({
        ...form,
        [outerKey]: { ...form[outerKey], [innerKey]: value },
      });
    } else {
      if (name === "hourlyRate") {
        const isValid = new RegExp("^[0-9/\\.]{0,5}$").test(value);
        if (isValid) {
          setForm({ ...form, [name]: value });
        } else {
          window.document.getElementsByName("name")[0].value = "";
          setForm({ ...form, [name]: "" });
        }
      } else {
        setForm({ ...form, [name]: value });
      }
    }
  }

  function handleCheckboxChange(event) {
    const { name, checked, value } = event.target;
    // Assurez-vous que la clé existe et est un tableau
    if (!Array.isArray(form[name])) {
      console.error(
        `La clé ${name} doit être un tableau dans l'objet de formulaire.`
      );
      return;
    }

    // Si la case est cochée, ajoutez la valeur au tableau, sinon supprimez-la
    if (checked) {
      setForm({ ...form, [name]: [...form[name], value] });
    } else {
      setForm({
        ...form,
        [name]: form[name].filter((item) => item !== value),
      });
    }
  }

  function reset() {
    setTimeout(() => {
      navigate("/jobs");
    },10000);
  }

  return {
    form,
    handleInputChange,
    handleCheckboxChange,
    reset,
    setForm,
  };
}

import React, { useEffect, useRef, useState } from "react";
import DropdownSkills from "../../../components/dropdown/dropdownSkills";
import JobType from "../../../Enum/JobType";
import moment from "moment";
import AutoSuggest from "./auto-suggest";
import useProfileUpdate from "../../../hook/company";
import useFirestore from "../../../hook/firestore";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { SuccessAlertPublished } from "../../../components/alert/alerts";
import useJobs from "./hook";

const JobForm = () => {
  const { profile } = useProfileUpdate();
  const [selected, setSelected] = useState("");
  const { createDocument, loading } = useFirestore("jobs");
  const [show, setShow] = useState(null);

  const [location, setLocation] = useState({
    street: null,
    city: null,
    zip: null,
    state: null,
  });

  function handleChangeLocation(event) {
    const { name, value } = event.target;

    setLocation({ ...location, [name]: value });
  }

  const street = useRef(null);
  const city = useRef(null);
  const zip = useRef(null);
  const state = useRef(null);
  const street1 = useRef(null);

  const { handleInputChange, setForm, form, handleCheckboxChange, reset } =
    useJobs({
      jobName: null,
      hourlyRate: 10,

      startDate: moment().format("YYYY-MM-DD"),
      startHour: null,
      endHour: null,
      description: null,
      location: {
        street: null,
        city: null,
        zip: null,
        state: null,
      },
      availability: true,
      totalHour: null,
      type: "DEFAULT",
      creatAt: moment.utc().unix(),
      sector: null,
      skill: [],
      nbCandidate: 1,
      company: {},
    });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!form.jobName || !form.description) {
      return null;
    }

    const { sector } = selected;
    const { code: currency } = profile.currency;
    const { name, logoUrl, id } = profile;
    const startAt = moment(
      `${form.startDate} ${form.startHour}`,
      "YYYY-MM-DD HH:mm"
    );
    const endAt = moment(
      `${form.startDate} ${form.endHour}`,
      "YYYY-MM-DD HH:mm"
    );
    const totalHour = endAt.diff(startAt, "hours", true);
    const temp = form;
    const params = {
      ...temp,
      location,
      sector,
      hourlyRate: parseFloat(form.hourlyRate),
      nbCandidate: parseFloat(form.nbCandidate),
      currency,
      startAt: {
        time: startAt.format("hh:mm A"),
        unix: startAt.unix(),
      },
      endAt: {
        time: endAt.format("hh:mm A"),
        unix: endAt.unix(),
      },
      creatAt: moment.utc().unix(),
      company: {
        name,
        logoUrl,
        id,
      },
      totalHour,
    };

    await createDocument(params).then((data) => {
      setShow(data);
      reset();
    });
  };

  useEffect(() => {
    // Function to check if the Google Maps script is already loaded
    const isScriptLoaded = (src) => {
      return Array.from(document.scripts).some((script) => script.src === src);
    };

    // Script URL
    const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAAZzq54uXg1aSR-8NSY41uz5-4P5JCWQM&libraries=places&callback=initAutocomplete`;

    // Only append the script if it's not already loaded
    if (!isScriptLoaded(scriptSrc)) {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.async = true;
      document.body.appendChild(script);

      // Initialize the Autocomplete when the script is loaded
      window.initAutocomplete = initAutocomplete;

      return () => {
        // Clean up the script and Autocomplete on component unmount
        document.body.removeChild(script);
        delete window.initAutocomplete;
      };
    }
  }, []);

  function initAutocomplete() {
    const address1Field = street.current;
    const postalField = zip.current;

    // Create the autocomplete object, restricting the search predictions to addresses in the US and Canada.
    const autocomplete = new window.google.maps.places.Autocomplete(
      address1Field,
      {
        componentRestrictions: { country: ["gb", "sa", "ae", "fr"] },
        fields: ["address_components", "geometry"],
        types: ["address"],
      }
    );

    address1Field.focus();

    // When the user selects an address from the drop-down, populate the address fields in the form.
    autocomplete.addListener("place_changed", fillInAddress);

    function fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = autocomplete.getPlace();
      let address1 = "";
      let postcode = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      let updatedAddress = {};

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number":
            address1 = `${component.long_name} ${address1}`;
            break;
          case "route":
            address1 += component.short_name;
            break;
          case "postal_code":
            postcode = `${component.long_name}${postcode}`;
            break;
          case "postal_code_suffix":
            postcode = `${postcode}-${component.long_name}`;
            break;
          case "locality":
            updatedAddress[`location.city`] = component.long_name;
            city.current.value = component.long_name;
            break;
          case "administrative_area_level_1":
            updatedAddress[`location.state`] = component.short_name;
            state.current.value = component.short_name;
            break;
        }
      }

      updatedAddress[`location.street`] = address1;
      street1.current.value = address1;
      updatedAddress[`location.zip`] = postcode;
      zip.current.value = postcode;

      const obj = {
        street: updatedAddress[`location.street`],
        state: updatedAddress[`location.state`],
        zip: updatedAddress[`location.zip`],
        city: updatedAddress[`location.city`],
      };

      setLocation(obj);
    }
  }



  return (
    <form onSubmit={handleSubmit} className=" md:p-4">
      <div className="flex justify-between flex-wrap p-2 md:p-0">
        <h1 className=" text-lg font-semibold text-sky-900">New Position</h1>

        <button
          type="submit"
          className="rounded-md bg-sky-900  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-800"
        >
          Post new position
        </button>
      </div>
      <hr className="my-4" />
      <SuccessAlertPublished id={show} />
      <div className="p-4 ">
        <div className="rounded-lg  ">
          <AutoSuggest
            selected={selected}
            setForm={setForm}
            form={form}
            setSelected={setSelected}
          />
        </div>
      </div>

      <div className=" p-2 grid  md:grid-cols-2 lg:grid-cols-3 sm:p-4 w-full   gap-3 relative   ">
        {loading && <SpinnerLoadData />}
        <div className=" flex flex-col border p-4 gap-8 rounded-lg">
          <div>
            <select
              id="type"
              name="type"
              required
              onChange={handleInputChange}
              className={` block  transition-all bg-gray-100 text-sky-900  duration-500 ${
                form.type === JobType.URGENT
                  ? "bg-orange-500 text-white font-semibold focus:ring-orange-800"
                  : form.type === JobType.TODAY
                  ? "bg-red-700 text-white font-semibold focus:ring-red-800"
                  : ""
              }  w-full font-semibold rounded-md border-0 py-4 pl-3 pr-10     text-sm`}
            >
              <option disabled selected>
                Urgency
              </option>
              <option value={JobType.DEFAULT}>{JobType.DEFAULT}</option>
              <option value={JobType.URGENT}>{JobType.URGENT}</option>
              <option value={JobType.TODAY}>{JobType.TODAY}</option>
            </select>
          </div>
          <div className=" relative">
            <span className=" pl-3 text-sm font-semibold absolute top-1/2 text-sky-900  -translate-y-1/2">
              Date
            </span>
            <input
              type="date"
              required
              min={moment().format("YYYY-MM-DD")}
              onChange={handleInputChange}
              value={form.startDate}
              name="startDate"
              id="date"
              className=" block w-full pl-20 font-semibold rounded-md border-0 py-4   bg-gray-100 text-sky-900    text-sm "
            />
          </div>
          <div className=" relative">
            <span className=" pl-3 text-sm font-semibold absolute top-1/2 text-sky-900  -translate-y-1/2">
              From
            </span>
            <input
              datepicker
              type="time"
              value={form.startHour}
              required
              max={form.endHour && form.endHour}
              onChange={handleInputChange}
              name="startHour"
              className=" block w-full pl-20 font-semibold rounded-md border-0 py-4   bg-gray-100 text-sky-900    text-sm "
              placeholder="Select date"
            />
          </div>
          <div className=" relative">
            <span className=" pl-3 text-sm font-semibold absolute top-1/2 text-sky-900  -translate-y-1/2">
              To
            </span>
            <input
              datepicker
              type="time"
              required
              value={form.endHour}
              min={form.startHour && form.startHour}
              onChange={handleInputChange}
              name="endHour"
              className=" block w-full pl-20 font-semibold rounded-md border-0 py-4   bg-gray-100 text-sky-900    text-sm "
              placeholder="Select date"
            />
          </div>
        </div>
        <div className=" flex flex-col border p-4   gap-8 rounded-lg">
          <div className="relative bg-gray-100 text-sky-900 p-4 rounded-lg  ">
            <div className="flex flex-col gap-4  ">
              <span className="text-sm font-semibold"> Hourly rate</span>
              <input
                id="default-range"
                type="range"
                step={0.05}
                name="hourlyRate"
                onChange={handleInputChange}
                value={form.hourlyRate}
                class="w-full h-2 bg-gray-200  rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              />
              <span className="p-2 w-full font-semibold border rounded-lg  bg-sky-800 text-white  text-center text-sm">
                {[profile?.currency?.code, form.hourlyRate].join(" ")}
              </span>
            </div>
          </div>
          <div className="relative bg-gray-100 text-sky-900 p-4 rounded-lg  ">
            <div className="flex flex-col gap-4 h-fit ">
              <span className="text-sm font-semibold"> Headcount</span>
              <input
                id="default-range"
                type="range"
                step={1}
                name="nbCandidate"
                onChange={handleInputChange}
                value={form.nbCandidate}
                class="w-full h-2 bg-gray-200  rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              />
              <span className="p-2 w-full font-semibold border rounded-lg  bg-sky-800 text-white  text-center text-sm">
                {form.nbCandidate}
              </span>
            </div>
          </div>
        </div>

        <div className=" grid border   col-span-full lg:col-span-1 p-4 gap-8 rounded-lg">
          <div className="flex flex-col gap-8">
            <div className="relative mt-2 col-span-full flex items-center">
              <input
                type="text"
                name="Location"
                id="Location"
                ref={street}
                placeholder="Where it will take place"
                className=" block w-full font-semibold rounded-md border-0 py-4 pl-3 pr-10 bg-gray-100 text-sky-900 placeholder:text-sky-900/70   text-sm "
              />
              <div className="absolute inset-y-0 right-0 flex py-4 pr-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 animate-bounce"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </div>
            </div>

            <div className="col-span-1">
              <label htmlFor="location.street" className="sr-only">
                street
              </label>
              <input
                type="text"
                required
                ref={street1}
                name="street"
                value={location.street}
                onChange={handleChangeLocation}
                id="street"
                className=" block w-full font-semibold rounded-md border-0 py-4 pl-3 pr-10 bg-gray-100 text-sky-900 placeholder:text-sky-900/70   text-sm "
                placeholder="street"
              />
            </div>
            <div className="col-span-1">
              <label htmlFor="location.city" className="sr-only">
                city
              </label>
              <input
                type="text"
                name="city"
                required
                ref={city}
                value={location.city}
                onChange={handleChangeLocation}
                id="city"
                className=" block w-full font-semibold rounded-md border-0 py-4 pl-3 pr-10 bg-gray-100 text-sky-900 placeholder:text-sky-900/70   text-sm "
                placeholder="city"
              />
            </div>
            <div className="col-span-1">
              <label htmlFor="location.zip" className="sr-only">
                zip
              </label>
              <input
                type="text"
                ref={zip}
                name="zip"
                value={location.zip}
                onChange={handleChangeLocation}
                id="location.zip"
                className=" block w-full font-semibold rounded-md border-0 py-4 pl-3 pr-10 bg-gray-100 text-sky-900 placeholder:text-sky-900/70   text-sm "
                placeholder="zip"
              />
            </div>
            <div className="col-span-1">
              <label htmlFor="state" className="sr-only">
                state
              </label>
              <input
                type="text"
                ref={state}
                onChange={handleChangeLocation}
                value={location.state}
                name="state"
                required
                id="state"
                className=" block w-full font-semibold rounded-md border-0 py-4 pl-3 pr-10 bg-gray-100 text-sky-900 placeholder:text-sky-900/70   text-sm "
                placeholder="state"
              />
            </div>
          </div>
        </div>
        <div className=" flex flex-col col-span-full overflow-hidden  border relative  p-4 gap-8 rounded-lg">
          <div className="w-full">
            <DropdownSkills
              handleCheckboxChange={handleCheckboxChange}
              selected={selected}
            />

            <div className="w-full overflow-hidden">
              <div className=" flex md:overflow-hidden overflow-x-auto md:hover:overflow-scroll shrink-0  whitespace-nowrap  gap-3  mt-2">
                {form.skill &&
                  form.skill.map((sk) => (
                    <span className="px-4 py-2 text-xs font-semibold bg-gray-100 text-sky-900 rounded-lg">
                      {sk}
                    </span>
                  ))}
              </div>
            </div>
          </div>
          <div className="">
            <textarea
              rows={4}
              onChange={handleInputChange}
              name="description"
              value={form.description}
              placeholder="Job description..."
              id="description"
              required
              className="block w-full rounded-md border-0 py-4 text-sky-900  bg-gray-100 text-sm font-semibold
              placeholder:text-sky-900/60 "
              defaultValue={""}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default JobForm;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFirestore from "../../../hook/firestore";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import moment from "moment";

function Details() {
  const { callableFunction, loading } = useFirestore("shifts");
  const [invoice, setInvoice] = useState({});

  const fetchInvoice = async () => {
    const result = await callableFunction("getInvoices", {
      invoiceId: searchParams.get("id"),
      customerId: null,
      customerName: null,
      customerEmail: null,
    });
    const data = result.invoices;
    setInvoice(data[0]);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchInvoice();
  }, [searchParams.get("id")]);

  return (
    <div className=" border rounded-lg p-4 size-full relative">
      {loading && <SpinnerLoadData />}

      {!loading && (
        <div className=" grid grid-cols-2 gap-4">
          <div className=" col-span-full  bg-gray-100 rounded-lg p-4">
            <span className="font-bold text-sky-800">
              {[
                invoice.object,
                moment.unix(invoice.period_end).local().format("MM-dd-yyyy"),
              ].join("-")}
            </span>
          </div>
          <div className="flex flex-col bg-gray-100 p-6 rounded-lg">
            <h1 className="font-semibold text-sky-800 text-lg mb-2">From</h1>
            <h2 className="text-base font-semibold mb-1">
              {invoice.account_name}
            </h2>
            <p className="text-sm text-gray-600 font-semibold">
              {invoice.account_country}
            </p>
          </div>

          {/* Section To */}
          <div className="flex flex-col items-end bg-gray-100 p-6 rounded-lg">
            <h1 className="font-semibold text-sky-800 text-lg mb-2">To</h1>
            <h2 className="text-base mb-1">{invoice.customer_name}</h2>
            <p className="text-sm text-gray-600 mb-1">
              {invoice.customer_email}
            </p>
            <p className="text-sm text-gray-600 mb-1">
              {invoice.customer_phone}
            </p>
            <p className="text-sm text-gray-600 mb-1">
              {invoice.customer_address?.line1}
            </p>
            {invoice.customer_address?.line2 && (
              <p className="text-sm text-gray-600 mb-1">
                {invoice.customer_address?.line2}
              </p>
            )}
            <p className="text-sm text-gray-600 mb-1">
              {invoice.customer_address?.postal_code}
            </p>
            <p className="text-sm text-gray-600 mb-1">
              {invoice.customer_address?.city}
            </p>
            <p className="text-sm text-gray-600 mb-1">
              {invoice.customer_address?.state}
            </p>
            <p className="text-sm text-gray-600 mb-1">
              {invoice.customer_address?.country}
            </p>
          </div>

          {invoice.lines?.data.map((item) => (
            <div className=" col-span-full flex flex-col gap-2 bg-gray-100 rounded-lg p-4">
              <h1 className="text-sm font-semibold text-sky-800">
                {item.description}
              </h1>
              <div className="grid grid-cols-2 ">
                <div>
                  <span className="font-bold text-gray-800">
                    {" "}
                    {[item.currency, (item.amount / 100).toFixed(2)]
                      .join(" ")
                      .toUpperCase()}{" "}
                  </span>
                </div>
              </div>
            </div>
          ))}

          <div className=" col-span-full flex flex-col gap-2 bg-gray-100 rounded-lg p-4">
            <h1 className="text-sm font-semibold text-sky-800">Total Due</h1>
            <div className="grid grid-cols-2 ">
              <div>
                <span className="font-bold text-gray-800">
                  {" "}
                  {[invoice.currency, (invoice.total / 100).toFixed(2)]
                    .join(" ")
                    .toUpperCase()}
                </span>
              </div>
            </div>
          </div>

          <div className=" p-10 border border-dashed rounded-lg flex justify-center items-center bg-gray-100 col-span-full">
            <h1 className="text-2xl font-bold text-sky-800">{invoice.status}</h1>
          </div>
        </div>
      )}
    </div>
  );
}

export default Details;

import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { baseUrl } from "../../utils";

export function FilePreview({ filePath, fileType, ...props }) {
  return (
    <a
      {...props}
      target="_blank"
      className="flex flex-col items-center justify-center"
    >
      {fileType === "application/pdf" ? (
        <div className="flex justify-center items-center">
          <iframe
            src={filePath}
            title="PDF Viewer"
            className="rounded-lg shadow-lg md:w-[600px] h-96"
          ></iframe>
        </div>
      ) : (
        <img
          src={filePath}
          className="rounded-lg shadow-lg shadow-sky-300"
          alt="file preview"
        />
      )}
    </a>
  );
}

import React from "react";
import Stats from "./stats";
import TableFinanceHistory from "../../../components/finance/table";
import { useBalance } from "../../../hook/stripe";
import Transactions from "./transactions";

function Finance() {
  useBalance();
  return (
    <div>
      <div className="px-4 text-sky-800 text-2xl">Finance</div>
      <hr className="my-3" />
      <Stats />

      <Transactions />
    </div>
  );
}

export default Finance;

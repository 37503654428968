import React, { useEffect, useState } from "react";
import { styleMap } from "../../../utils";
import {
  Circle,
  CircleF,
  GoogleMap,
  Marker,
  MarkerF,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import useJob from "../../../hook/job";
import useGeolocation from "../../../hook/geolocation";
import { useNavigate } from "react-router-dom";
import useProfileUpdate from "../../../hook/candidate";
import useFirestore from "../../../hook/firestore";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";

function Map() {
  const { readAllDocuments } = useFirestore("jobs");
  const [zoom, setZoom] = useState(10);
  const { profile } = useProfileUpdate();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);

  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );

  const fetchJobs = async () => {
    const result = await readAllDocuments();
    setJobs(result);
  };

  useEffect(() => {
    fetchJobs();
  }, [profile]);

  const { data, hasMore, fetchData } = useJob({ all: true, getAll: true });

  const { isLoaded } = useLoadScript({
    id: "google-map-script-2",
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE,
  });

  return (
    <div className="h-full  w-full">
      {
       !isLoaded && <div className="h-full max-lg:rounded-lg w-full  flex justify-center items-center animate-pulse bg-gray-100">
<SpinnerLoadData />
       </div>
      }
      {isLoaded && profile && (
        <GoogleMap
          mapContainerClassName="h-full max-lg:rounded-lg w-full border"
          center={{
            lat: latitude || profile.location.latitude || 0,
            lng: longitude || profile.location.longitude || 0,
          }}
          zoom={zoom}
          options={{
            styles: styleMap,
            mapTypeControl: false,
            fullscreenControl: false,
            panControl: false,
          }}
        >
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
            {(clusterer) =>
              jobs &&
              jobs.map((job, i) => (
                <Marker
                  key={i}
                  onClick={() => navigate(`/jobs?id=${job.id}`)}
                  position={{
                    lat: job.location.latitude,
                    lng: job.location.longitude,
                  }}
                  label={{
                    text: job.jobName,

                    className:
                      "absolute  bottom-[0rem] text-sky-800 righ-1/2 -translate-x-1/2 px-4 py-1   rounded-md md:text-sm text-xs font-semibold bg-gray-100  ",
                  }}
                  icon={{
                    url: require("../../../assets/img/company.png"),
                    scaledSize: {
                      width: 50,
                      height: 50,
                    },
                  }}
                  clusterer={clusterer}
                />
              ))
            }
          </MarkerClusterer>
          <Marker
            position={{
              lat: latitude || profile.location.latitude || 0,
              lng: longitude || profile.location.longitude || 0,
            }}
            icon={{
              url: require("../../../assets/img/worker.png"),

              scaledSize: {
                width: 50,
                height: 50,
              },
            }}
          />
          (
          <Circle
            center={{
              lat: latitude || profile.location.latitude || 0,
              lng: longitude || profile.location.longitude || 0,
            }}
            radius={profile.radius * 1000}
          />
          )
        </GoogleMap>
      )}
    </div>
  );
}

export default Map;

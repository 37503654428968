import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { classNames } from "../utils/className";
import { useEffect, useRef, useState } from "react";

export default function SuccessAlert({ show }) {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    setHide(false);
  }, [show]);

  return (
    !hide && (
      <div
        className={classNames(
          "rounded-lg  bg-green-50 p-4 w-full",
          show ? "block" : "hidden"
        )}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-green-400"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">
              Successfully updated
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={() => setHide(true)}
                className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
export function ErrorAlert({ show = false, content }) {
  const [hide, setHide] = useState(false);

  useEffect(() => {
    setHide(false);
  }, [content, show]);

  return (
    !hide && (
      <div
        className={classNames(
          "rounded-lg  bg-red-50 p-4 w-full",
          show ? "block" : "hidden"
        )}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-red-400"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-red-800">{content}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={() => setHide(true)}
                type="button"
                className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export function SuccessAlertPublished({ id }) {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (id) {
      setHide(false);

      setTimeout(() => {
        setHide(true);
      }, 5000);
    }
  }, [id]);

  return (
    !hide && (
      <div className={classNames("rounded-md bg-green-50 p-4")}>
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-green-400"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">
              Published with success !
            </p>
          </div>
        </div>
      </div>
    )
  );
}
export function ErrorAlertPublished({ id }) {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (id) {
      setHide(false);

      setTimeout(() => {
        setHide(true);
      }, 30000);
    }
  }, [id]);

  return (
    !hide && (
      <div className={classNames("rounded-md bg-red-50 p-4")}>
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-red-400"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-red-800">
              Missing some essential information before the job.
            </p>
          </div>
        </div>
      </div>
    )
  );
}

import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useCurrentBreakpoint from "../../../hook/tailwindBreakPoint";
import useFirestore from "../../../hook/firestore";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { classNames } from "../../../components/utils/className";
import Application from "../../../Enum/Applicatition";
import useProfileUpdate from "../../../hook/candidate";

const CustomTooltip = ({
  active,
  payload,
  label,
  type = "Live jobs posted",
  name,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white rounded-lg   ">
        <div className="bg-sky-900 text-sky-50 p-2 text-xs sm:text-sm rounded-t-lg  ">
          {name}
        </div>
        {payload.map((data, index) => (
          <>
            <p className=" text-xs sm:text-sm p-4 text-sky-900 font-semibold">
              Date : {payload[0].payload?.name}
            </p>
            <p className=" text-xs sm:text-sm p-4 text-sky-900 font-semibold">{`${data.name} : ${data.value}`}</p>
          </>
        ))}
      </div>
    );
  }

  return null;
};

function CustomizedAxisTick(props) {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
}

function Chart({ dataType }) {
  const brk = useCurrentBreakpoint();
  const { profile } = useProfileUpdate();
  const { callableFunction, loading } = useFirestore("applications");
  const [data, setData] = useState();
  const fectData = async () => {
    if (!["shifts", "stars"].includes(dataType)) {
      const result = await callableFunction("countApplicationsByMonth", {
        status: dataType,
        year: new Date().getFullYear(),
        candidateId: profile.id,
      });
      setData(result.applicationsByMonth);
    }
    if (["shifts", "stars"].includes(dataType)) {
      const result = await callableFunction("countShiftsByMonth", {
        status: "",
        year: new Date().getFullYear(),
        filterStars: dataType === "stars" ? true : false,
        candidateId: profile.id,
      });
      setData(result.shiftsByMonth);
    }
  };

  useEffect(() => {
    if (profile) {
      fectData();
    }
  }, [dataType, profile]);

  return (
    <ResponsiveContainer className={"relative"} width="100%" height="100%">
      {loading && <SpinnerLoadData />}
      <BarChart width={150} height={40} data={data}>
        <XAxis
          tickSize={4}
          axisLine={false}
          tickMargin={10}
          padding={"gap"}
          tickCount={9}
          height={
            ["md"].includes(brk) ? 30 : ["xs", "sm"].includes(brk) ? 20 : 20
          }
          dataKey="name"
          type="category"
          className="text-[0.65rem] text-sky-900"
          tick={CustomizedAxisTick}
        />
        <YAxis hide />
        <Bar
          dataKey={dataType !== "stars" ? "number" : "stars"}
          radius={[20, 20, 0, 0]}
          barSize={30}
          className={classNames(
            " border-gray-800 text-sm  rounded-t-lg ",
            dataType === "shifts" && "fill-indigo-600 ",
            dataType === Application.ACCEPTED && "fill-green-600 ",
            dataType === "" && "fill-sky-600 ",
            dataType === "stars" && "fill-orange-600 "
          )}
        />

        <Tooltip
          content={
            <CustomTooltip
              name={
                dataType === Application.ACCEPTED
                  ? "Accepted Apllications "
                  : dataType === "shifts"
                  ? "Shifts"
                  : dataType === "stars"
                  ? "Stars"
                  : "All Applications"
              }
            />
          }
          cursor={{
            stroke: "#362F78",
            strokeWidth: 0.1,
            fill: "rgb(7 89 133 / 0.25)",
            radius: [20, 20, 0, 0],
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Chart;

import { useCallback, useEffect, useState } from "react";
import Stars from "../../../components/rating/Stars";
import useProfileUpdate from "../../../hook/candidate";
import useFirestore from "../../../hook/firestore";
import { orderBy, where } from "firebase/firestore";
import { classNames } from "../../../components/utils/className";
import Application from "../../../Enum/Applicatition";
import moment from "moment";
import DaysFilter from "../../../components/simple-calendar/days-upcoming";
import { Shift as s } from "../../../Enum/shift";
import ItemEmpty from "../../../components/utils/empty";

function HistoricsItemLoading({ item }) {
  return (
    <div className=" relative">
      <h1
        className={classNames(
          " px-2 py-2 rounded-bl-lg absolute right-0  text-xs font-semibold bg-gray-100 animate-pulse text-transparent"
        )}
      >
        text
      </h1>
      <div className=" border  rounded-lg grid gap-2 text-sky-800 *:rounded-lg md:grid-cols-2 p-4 ">
        <div className="  p-2 col-span-full   font-semibold">
          <h1 className="text-base bg-gray-100 animate-pulse text-transparent">
            {" "}
            text
          </h1>
          <h1 className="text-xs bg-gray-100 animate-pulse text-transparent ">
            {" "}
            text
          </h1>
        </div>

        <span className="text-[0.65rem] bg-gray-100 animate-pulse text-transparent font-semibold flex justify-end w-full col-span-full">
          text
        </span>

        <div className=" grid p-2  gap-4 col-span-full font-semibold text-sm grid-cols-2">
          <div className="  rounded-lg flex items-center justify-center whitespace-nowrap px-4 py-2 bg-gray-100 animate-pulse text-transparent">
            <h1>shift</h1>
          </div>
          <div
            className={classNames(
              "   flex items-center justify-center  whitespace-nowrap px-4 py-2 rounded-lg bg-gray-100 animate-pulse text-transparent"
            )}
          >
            <h1 className="text-xs">text</h1>
          </div>
        </div>

        <h1 className="  p-2 text-sm col-span-full flex justify-end bg-gray-100 animate-pulse text-transparent">
          text
        </h1>
      </div>
    </div>
  );
}

function HistoricsItem({ item }) {
  const { readDocument } = useFirestore("companies");
  const { readDocumentsWithQuery: readShift } = useFirestore("shifts");
  const [data, setData] = useState(null);

  const fetchData = async () => {
    const resultCompany = await readDocument(item.company?.id);
    const resultShift = await readShift([
      where("applicationId", "==", item.id),
    ]);

    const params = {
      company: resultCompany,
      shift: resultShift && resultShift[0],
    };

    setData(params);
  };

  useEffect(() => {
    fetchData();
  }, [item]);

  return (
    <div className=" relative">
      <h1
        className={classNames(
          " px-2 py-2 rounded-bl-lg absolute right-0  text-xs font-semibold",
          item.status == Application.PENDING && "bg-gray-100  text-gray-600",
          item.status == Application.ACCEPTED && "bg-green-100  text-green-600",
          item.status == Application.REJECTED && "bg-red-100  text-red-600"
        )}
      >
        {item.status}
      </h1>
      <div className=" border  rounded-lg grid gap-2 text-sky-800 *:rounded-lg md:grid-cols-2 p-4 ">
        <div className="  p-2 col-span-full   font-semibold">
          <h1 className="text-base"> {item.job.name}</h1>
          <h1 className="text-xs "> {data && data.company?.name}</h1>
        </div>

        <span className="text-[0.65rem] font-semibold flex justify-end w-full col-span-full">
          {moment.unix(item.creatAt).local().fromNow()}
        </span>
        {data && data.shift?.status && (
          <div className=" grid p-2  gap-4 col-span-full font-semibold text-sm grid-cols-2">
            <div className="  rounded-lg flex items-center justify-center whitespace-nowrap px-4 py-2 bg-sky-50 text-sky-900">
              <h1>shift</h1>
            </div>
            <div
              className={classNames(
                "   flex items-center justify-center  whitespace-nowrap px-4 py-2 rounded-lg",
                data &&
                  data.shift?.status === s.CONFIRMED &&
                  "bg-green-50 text-green-800 ",
                data &&
                  data.shift?.status === s.PAID &&
                  " bg-green-800 text-green-50",
                data &&
                  data.shift?.status === s.CANCEL &&
                  " bg-red-800 text-red-50",
                data &&
                  data.shift?.status === s.NO_SHOW &&
                  " bg-orange-800 text-orange-50",
                data &&
                  data.shift?.status === s.PENDING_PAYMENT &&
                  " bg-indigo-800 text-indigo-50",
                item.status === s.PENDING_RATING &&
                  " bg-yellow-800 text-yellow-50"
              )}
            >
              <h1 className="text-xs">{data && data.shift?.status}</h1>
            </div>
          </div>
        )}

        <h1 className="  p-2 text-sm col-span-full flex justify-end">
          <Stars number={(data && data.shift.stars.candidate) || 0} />
        </h1>
      </div>
    </div>
  );
}

export default function Historics() {
  const { profile } = useProfileUpdate();
  const [filterBy, setFilterBy] = useState("*");
  const { readDocumentsWithQuery, callableFunction, loading } =
    useFirestore("applications");
  const [historics, setHistorics] = useState([]);
  const [date, setDate] = useState(null);

  const fetchHistorics = async () => {
    const result = await callableFunction("filterShiftsByApplications", {
      status: filterBy,
      candidate: profile.id,
      startDate: date || "",
    });
    const { data } = result;

    setHistorics(data);
  };

  useEffect(() => {
    if (profile) {
      fetchHistorics();
    }
  }, [profile, date]);

  return (
    <>
      <div className=" p-4">
        <h1 className=" text-lg font-semibold text-sky-900">Historics</h1>
        <hr className="my-4" />
        <DaysFilter setDate={setDate} date={date} />
        <div className=" grid xl:grid-cols-12 mt-6">
          <div className=" xl:col-span-4 gap-4 flex flex-col  max-h-[calc(100vh-18rem)] overflow-y-scroll px-2 pb-8">
            {historics &&
              historics.map((item) => <HistoricsItem item={item} />)}

            {historics && historics.length === 0 && !loading && <ItemEmpty />}

            {loading && [
              <HistoricsItemLoading />,
              <HistoricsItemLoading />,
              <HistoricsItemLoading />,
            ]}
          </div>

          <div className=" xl:col-span-8"></div>
        </div>
      </div>
    </>
  );
}

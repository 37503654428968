import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useJobCompany } from "../../../hook/job";
import { setJobPage, setOpenRepost } from "../../../redux/company";
import Stars from "../../../components/rating/Stars";
import Details from "./details";
import useFirestore from "../../../hook/firestore";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import useProfileUpdate from "../../../hook/company";
import moment from "moment";
import { UsersIcon } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { db } from "../../../firebase";
import { classNames } from "../../../components/utils/className";
import useCurrentBreakpoint from "../../../hook/tailwindBreakPoint";

function JobItemLoading({ item }) {
  return (
    <div className=" p-4 py-6 shrink-0  bg-white border shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-xs text-end text-transparent">text</h1>
      </div>

      <div className=" space-y-1">
        <h1 className="text-transparent  animate-pulse bg-slate-100 text-base font-semibold">
          text
        </h1>
        <h1 className="text-transparent w-1/3 animate-pulse bg-slate-100 text-xs font-semibold">
          {" "}
          text
        </h1>
        <h1 className="text-transparent w-2/3 animate-pulse bg-slate-100  text-end font-semibold">
          {" "}
          text
        </h1>
      </div>

      <div className=" flex justify-end items-center text-transparent text-green-600  ">
        <div className=" bg-green-50 flex rounded-lg px-4 gap-2 py-1">
          <UsersIcon className=" size-4" />
          <h1 className="text-end text-sm font-semibold   ">1/3</h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-2  *:rounded-lg ">
        <div className="  text-transparent bg-slate-100 animate-pulse p-2 border col-span-full text-xs font-semibold text-center">
          text
        </div>
        <div className=" text-transparent bg-slate-100 animate-pulse  p-2 col-span-full border text-xs font-semibold text-center">
          text
        </div>

        <div className=" text-transparent bg-slate-100 animate-pulse  border text-xs p-2 font-semibold text-center ">
          text
        </div>
        <div className=" text-transparent bg-slate-100 animate-pulse   border text-xs p-2 font-semibold text-center">
          text
        </div>

        <div className="  text-transparent bg-gray-100 animate-pulse border text-sm p-2 col-span-full">
          text
        </div>
        <div className="  p-2 grid grid-cols-2 text-sm gap-4  col-span-full">
          <button className="  text-transparent border text-center bg-white p-2 font-semibold  rounded-lg">
            More details
          </button>
          <button className="  text-transparent text-center bg-sky-600 hover:bg-sky-800 p-2 font-semibold text-white rounded-lg">
            Repost
          </button>
        </div>
      </div>
    </div>
  );
}

function JobItem({ item, setSelected }) {
  const { profile } = useProfileUpdate();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setOpenRepost(true));
    setSearchParams({
      id: item.id,
    });
  };

  const handleDetail = (id) => {
    navigate(`?id=${id}`);
  };

  return (
    <div className=" p-4 py-6 shrink-0  bg-white border shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-xs text-end">
          {moment.unix(item.creatAt).local().fromNow()}
        </h1>
      </div>

      <div className=" space-y-1">
        <h1 className="text-base font-semibold">{item.jobName}</h1>
        <h1 className="text-xs font-semibold">{item.company.name}</h1>
        <h1 className=" text-end font-semibold">
          <Stars number={(profile && profile.averageStars) || 0} />
        </h1>
      </div>

      <div className=" flex justify-end items-center text-green-600  ">
        <div className=" bg-green-50 flex rounded-lg px-4 gap-2 py-1">
          <UsersIcon className=" size-4" />
          <h1 className="text-end text-sm font-semibold   ">
            {[item.applicationCount || 0, item.nbCandidate].join("/")}
          </h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-2  *:rounded-lg ">
        <div className=" p-2 border col-span-full text-xs font-semibold text-center">
          {[item.startAt.time, item.endAt.time].join("-")}
        </div>
        <div className=" p-2 col-span-full border text-xs font-semibold text-center">
          {item.startDate}
        </div>

        <div className=" border text-xs p-2 font-semibold text-center ">
          ---
        </div>
        <div className="  border text-xs p-2 font-semibold text-center">
          {[item.currency, item.hourlyRate].join(" ")}/hr
        </div>

        <div className="  border text-sm p-2 col-span-full">
          {item.description}
        </div>
        <div className="  p-2 grid grid-cols-2 text-sm gap-4  col-span-full">
          <button
            onClick={() => handleDetail(item.id)}
            className=" border text-center bg-white p-2 font-semibold  rounded-lg"
          >
            More details
          </button>
          <button
            onClick={handleClick}
            className=" text-center bg-sky-600 p-2 font-semibold text-white rounded-lg"
          >
            {" "}
            Repost
          </button>
        </div>
      </div>
    </div>
  );
}

function Jobs() {
  const scrollContainerRef = useRef();
  const scrollToRef = useRef();
  const brk = useCurrentBreakpoint();
  const [jobs, setJobs] = useState(null);
  const { profile, load } = useProfileUpdate();
  const [selected, setSelected] = useState(null);
  const [whereDate, setWhereDate] = useState(null);
  const [whereName, setWhereName] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log(scrollToRef.current);

    if (scrollContainerRef.current && scrollToRef.current) {
      // Calculer la position de défilement verticale
      const scrollPosition =
        scrollToRef.current.offsetTop - // Position de l'élément cible
        scrollContainerRef.current.clientHeight / 2 + // La moitié de la hauteur du conteneur
        scrollToRef.current.clientHeight / 2; // La moitié de la hauteur de l'élément cible

      scrollContainerRef.current.scrollTop = scrollPosition; // Appliquez la position de défilement
    }
  }, [scrollContainerRef, scrollToRef, jobs]);

  const { readDocumentsWithQuery, loading } = useFirestore("jobs");
  const fetchJobs = async () => {
    if (profile) {
      const query = [where("company.id", "==", profile.id)];
      if (whereDate) {
        query.push(
          where("startDate", "==", moment(whereDate).format("YYYY-MM-DD"))
        );
      }

      if (whereName) {
        query.push(where("jobName", "==", whereName));
      }

      query.push(orderBy("creatAt", "desc"));

      const result = await readDocumentsWithQuery(query);
      if (result && result.length > 0) {
        const firstJobId = result[0].id;
        if (!["sm", "xs"].includes(brk)) {
          setSelected(firstJobId);
        }
      } else {
        setSelected(null);
      }
      setJobs(result);
    }
  };
  useEffect(() => {
    fetchJobs();

    if (profile) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, "jobs"),
          where("company.id", "==", profile?.id) // Condition: where name is "test"
        ),
        (querySnapshot) => {
          fetchJobs();
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile, whereDate, whereName]);

  /*   const start = (job_page - 1) * job_limit + 1;
  const end = Math.min(job_page * job_limit, jobs?.total); */

  return (
    <div className="sm:p-4">
      {(searchParams.get("id")) && (
        <div className="p-2">
          <button
            onClick={() => setSearchParams({})}
            className="  font-semibold text-sm text-sky-900 hidden w-full bg-gray-100 rounded-lg  max-xl:inline-flex items-center justify-between p-2"
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
              />
            </svg>
            <span>Previous</span>
          </button>
        </div>
      )}

      <div className="  grid sm:grid-cols-2 md:grid-cols-12 gap-2 *:rounded-lg text-sm p-2">
        <div className=" md:col-span-6 bg-gray-100 pe-16 relative text-sky-900">
          <input
            type="text"
            onChange={(e) => setWhereName(e.currentTarget.value)}
            className=" bg-transparent border-none rounded-lg w-full "
            placeholder="warehouser"
          />
          <button className=" absolute text-sm font-semibold top-1/2 -translate-y-1/2">
            Search
          </button>
        </div>
        <div className=" md:col-span-6 bg-gray-100 pl-16 text-sm relative">
          <span className=" absolute left-3 top-1/2 text-sm font-semibold text-sky-900 -translate-y-1/2 ">
            Start Date
          </span>

          <input
            type="date"
            name=""
            onChange={(e) => setWhereDate(e.currentTarget.value)}
            className=" w-full bg-transparent flex items-center justify-center text-sm  rounded-lg border-none"
            id=""
          />
        </div>
      </div>
      <div className=" grid xl:grid-cols-12 gap-2 py-2 overflow-hidden">
        <div
          ref={scrollContainerRef}
          className={classNames(
            " xl:col-span-4 p-4  overflow-y-scroll flex flex-col gap-4  h-[calc(100vh-14rem)] ",
            searchParams.get("id") && " max-xl:hidden"
          )}
        >
          {jobs &&
            jobs.map((item) => (
              <div
                ref={searchParams.get("id") === item.id ? scrollToRef : null}
                className={classNames(
                  searchParams.get("id") === item.id &&
                    "border border-sky-900 rounded-lg shadow-sm-light"
                )}
              >
                <JobItem setSelected={setSelected} item={item} />
              </div>
            ))}

          {jobs && jobs.length === 0 && (
            <div className="p-4 border rounded-lg text-sky-900 text-sm font-semibold">
              <h1>No more jobs to load.</h1>
            </div>
          )}

          {(loading || !load) && [
            <JobItemLoading />,
            <JobItemLoading />,
            <JobItemLoading />,
          ]}
        </div>
        <div
          className={classNames(
            "block xl:col-span-8 px-4 h-[calc(100vh-14rem)] overflow-y-scroll",
            !searchParams.get("id") && "max-xl:hidden"
          )}
        >
          <Details id={selected} />
        </div>
      </div>
    </div>
  );
}

export default Jobs;

import { useEffect, useState } from "react";
import useFirestore from "../../../hook/firestore";
import ItemEmpty from "../../../components/utils/empty";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import Details from "./Details";
import { db } from "../../../firebase";
import { collection, onSnapshot, query } from "firebase/firestore";
import { classNames } from "../../../components/utils/className";

function ItemInquire({ item }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { deleteDocument } = useFirestore("inquires");

  const handleDelete = async (id) => {
    const conf = window.confirm("Do you want remove the inquire ?");
    if (conf) {
      await deleteDocument(id);
    }
  };

  return (
    <div className=" rounded-lg p-4 py-6 border relative">
      <span className=" absolute text-xs px-2 py-1 border-b top-0 right-0">
        {moment.unix(item.creatAt).local().fromNow()}
      </span>
      <h1 className=" font-semibold text-sky-800">
        {[item.lastName, item.firstName].join(" ")}
      </h1>
      <p className=" text-xs break-words ">
        {[item.email, item.phone].join(" | ")}
      </p>
      <hr className="my-2" />
      <p className="  text-sm  rounded-lg p-3 bg-gray-100">
        {item.description}
      </p>

      <div className=" grid grid-cols-2 my-4 gap-4 ">
        <button
          onClick={() => handleDelete(item.id)}
          className=" bg-red-50 hover:bg-red-100 text-red-800  text-sm p-3 rounded-lg"
        >
          Remove
        </button>
        <button
          onClick={() =>
            setSearchParams({
              id: item.id,
            })
          }
          className=" bg-green-50 hover:bg-green-100 text-green-800  text-sm p-3 rounded-lg"
        >
          Reply
        </button>
      </div>
    </div>
  );
}

export default function Inquires() {
  const { readAllDocuments } = useFirestore("inquires");
  const [inquires, setInquires] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchInquires = async () => {
    const result = await readAllDocuments();

    setInquires(result);
  };

  useEffect(() => {
    fetchInquires();

    const unsubscribe = onSnapshot(query(collection(db, "inquires")), () => {
      fetchInquires();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      <div className="px-4 text-sky-800 text-2xl">Inquires</div>
      <hr className="my-3" />
      <div className=" grid grid-cols-12">
        <div className=" col-span-4 h-[90vh] px-2 overflow-y-scroll flex flex-col gap-3">
          {inquires.map((item) => (
            <ItemInquire item={item} />
          ))}
          {inquires.length === 0 && <ItemEmpty />}
        </div>
        <div className=" col-span-8 p-2 h-[90vh]">
          <div
            className={classNames(
              "size-full flex-col rounded-lg border p-8 flex  items-center",
              searchParams.get("id") ? "justify-start" : "justify-center"
            )}
          >
            {searchParams.get("id") && <Details />}
            {!searchParams.get("id") && (
              <div>
                <span className=" font-semibold text-sky-800 border rounded-lg p-4 border-dashed">
                  Select inquire
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FireIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import Roles from "../../Enum/UserRoles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../utils/className";
import useCandidate from "../../hook/candidate";
import useCompany from "../../hook/company";
import { setLogout } from "../../redux/authentication";
import { baseUrl } from "../../utils";
import { auth, db } from "../../firebase";
import { signOut } from "firebase/auth";
import GenericImg from "./generic-user-img";
import useFirestore from "../../hook/firestore";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import {
  NotificationBellCandidate,
  NotificationBellCompany,
} from "./notificationBell";
import { setNotifOpen } from "../../redux/conversation";

function Header({ setSidebarOpen }) {
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;
  const [countNotification, setCountNotification] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { q } = useSelector((state) => state.FilterSlice);
  const { countDocumentsWithQuery, loading } = useFirestore("notifications");
  const { readDocument } = useFirestore("notifs_log");
  const fetchCountNotifications = async () => {
    const log = await readDocument(currentUser.uid);

    const result = await countDocumentsWithQuery([
      where("to", "==", currentUser.uid),
      where("creatAt", ">=", log?.date),
      where("read", "==", false),
    ]);

    setCountNotification(result);
  };

  function handleFocus() {
    if (role === Roles.candidate) {
      if (location.pathname !== "/jobs") {
        navigate("/jobs");
      }
    } else {
      if (location.pathname !== "/candidate") {
        navigate("/candidate");
      }
    }
  }

  const logout = async () => {
    await signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  console.log(currentUser);

  useEffect(() => {
    fetchCountNotifications();
    const docRef = doc(db, "notifs_log", currentUser.uid);

    const unsubscribe = onSnapshot(docRef, () => {
      fetchCountNotifications();
    });

    const unsubscribe2 = onSnapshot(
      query(
        collection(db, "notifications"),
        where("to", "==", currentUser.uid) // Condition: where name is "test"
      ),
      () => {
        fetchCountNotifications();
      }
    );

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  return (
    <div className="sticky top-0 z-[1000000000000000000000000000000000] flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form className="relative flex flex-1" action="#" method="GET">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
            onFocus={handleFocus}
            value={q}
            onChange={(e) =>
              dispatch({
                type: "FilterSlice/setQuery",
                payload: e.target.value,
              })
            }
          />
        </form>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <button
            type="button"
            onClick={() => dispatch(setNotifOpen())}
            className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500  cursor-pointer"
          >
            <span className="sr-only">View notifications</span>
            {role === Roles.candidate && (
              <NotificationBellCandidate
                countNotification={countNotification || 0}
              />
            )}

            {[Roles.company, Roles.manager, Roles.member].includes(role) && (
              <NotificationBellCompany
                countNotification={countNotification || 0}
              />
            )}
          </button>

          {/* Separator */}
          <div
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
            aria-hidden="true"
          />

          {/* Profile dropdown */}

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>

                <div className="size-8 rounded-full">
                  {currentUser.photoURL ? (
                    <img
                      className="size-full rounded-full object-cover  bg-gray-50"
                      src={currentUser.photoURL}
                      alt="avatar"
                    />
                  ) : (
                    <GenericImg />
                  )}
                </div>

                <span className="hidden lg:flex  lg:items-center">
                  <div className="flex flex-col">
                    <span
                      className="ml-4 text-sm mb-0 flex flex-col space-y-0 font-semibold  text-gray-900"
                      aria-hidden="true"
                    >
                      {currentUser.displayName}
                    </span>
                    {currentUser.name && (
                      <span className="text-[0.65rem] text-end text-sky-900 font-semibold">
                        {[currentUser.name, currentUser.role].join(" | ")}
                      </span>
                    )}
                  </div>

                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-4 py-3">
                  <p className="text-sm">Signed in as </p>

                  <p className="truncate text-sm font-medium text-gray-900">
                    {currentUser.email}
                  </p>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/profile"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        Account settings
                      </Link>
                    )}
                  </Menu.Item>
                </div>

                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={logout}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          {[Roles.company, Roles.member, Roles.manager].includes(role) && (
            <>
              <button
                disabled={role === Roles.member}
                onClick={() => navigate("/job/create")}
                className="relative hidden disabled:bg-gray-400 md:inline-flex items-center rounded-md shadow-sky-200 bg-sky-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                Create new job
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;

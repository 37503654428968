import { CSSTransition, TransitionGroup } from "react-transition-group";
import LayoutLanding from "./layout";
import { Route, Routes } from "react-router-dom";
import About from "./about";
import NotFound404 from "../components/not-found-page/404-not-found";
import GimmeAGig from "./gimme-a-gig";
import GimmeTalent from "./gimme-talent";
import Login from "./login";

import ResetPassword from "./reset-password";
import JobConversion from "./job-conversion";
import Details from "./details-job";
import Registration from "./registration-ease";
import AboutTempME from "./about_tempme";
import LayoutAdmin from "../apps/admin/layout";
import FAQ from "./faq";
import Careers from "./careers";
import HiringTips from "./hiring-tips";
import TermsAndConditions from "./term-and-condition";
import PrivacyPolicy from "./privacy-policy";
import Contact from "./Contact-b";
import StarsRatingWorks from "./starsRatingWorks";

export function LandingRoute() {
  return (
    <TransitionGroup>
      <CSSTransition timeout={500} classNames="page">
        <Routes>
          {!window.ReactNativeWebView && (
            <Route exact path="/" element={<LayoutLanding />}>
              <Route index element={<About />} />
              <Route path="/gimme-a-gig" element={<GimmeAGig />} />
              <Route path="/gimme-talent" element={<GimmeTalent />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/jobs" element={<JobConversion />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/jobs/:id" element={<Details />} />
              <Route path="/about-tempme/story" element={<AboutTempME />} />
              <Route path="/about-tempme/careers" element={<Careers />} />
              <Route path="/for-temper/:name" element={<Details />} />
              <Route path="/for-temper/FAQ" element={<FAQ />} />
              <Route
                path="/for-companies/hiring-tips"
                element={<HiringTips />}
              />
              <Route path="/support/terms" element={<TermsAndConditions />} />
              <Route
                path="/support/privacy-policy"
                element={<PrivacyPolicy />}
              />
              <Route path="/how-ratings-work" element={<StarsRatingWorks />} />
              <Route path="*" element={<NotFound404 />} />
            </Route>
          )}
          {window.ReactNativeWebView && (
            <Route exact path="/" element={<LayoutLanding />}>
              <Route index element={<Login />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="*" element={<Login />} />
            </Route>
          )}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

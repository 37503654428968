import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { updateStarRatingCandidate } from "../../redux/shift";
import GenericImg from "../layout/generic-user-img";
import useFirestore from "../../hook/firestore";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import useProfileUpdate from "../../hook/candidate";
import { db } from "../../firebase";
import Application from "../../Enum/Applicatition";
import ReactStars from "react-rating-stars-component";
import { SpinnerLoadData } from "../utils/spinner-load";
import Notification from "../../Enum/Notification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Item({ item }) {
  const [stars, setStars] = useState(1);
  const { updateDocument } = useFirestore("applications");
  const {
    updateDocument: updateShift,
    readDocumentsWithQuery,
    loading,
  } = useFirestore("shifts");
  const { deleteDocument, updateDocument: updateRead } =
    useFirestore("notifications");

  const handleUpdateApplication = async (status) => {
    await updateDocument(item.applicationId, {
      reconfirmPosition: status,
    }).then(async () => {
      await deleteDocument(item.id);
    });
  };

  const handleUpdateRead = async () => {
    await updateRead(item.id, {
      read: true,
    });
  };

  const handleUpdateShift = async () => {
    const docShift = await readDocumentsWithQuery([
      where("applicationId", "==", item.applicationId),
    ]);

    if (docShift.length > 0) {
      const temp = docShift[0].stars;
      temp.company = stars;

      await updateShift(docShift[0].id, {
        stars: temp,
      });

      await deleteDocument(item.id);
    }
  };

  return (
    <li
      onClick={handleUpdateRead}
      className={classNames(
        "w-full max-h-[20rem] p-4 transition-all ease-in-out relative cursor-pointer ",
        !item.read && "bg-gray-100"
      )}
    >
      {loading && <SpinnerLoadData />}

      <span className="absolute right-0 top-0 text-xs px-3 font-semibold text-gray-600 py-2">
        {moment.unix(item.creatAt).local().fromNow()}
      </span>
      <h1 className="text-sm font-semibold text-sky-900 py-2">{item.title}</h1>
      <div className="grid grid-cols-12 gap-8">
        <div className=" col-span-1">
          <div className=" size-8 border rounded-full">
            {item.company?.logoUrl && (
              <img
                src={item.company?.logoUrl}
                className={"rounded-full object-cover size-full"}
                alt="candidate"
              />
            )}

            {!item.company?.logoUrl && <GenericImg />}
          </div>
        </div>
        <div className=" col-span-11 flex items-center">
          <p className="text-sm font-semibold">{item.candidate?.name}</p>
        </div>
      </div>
      <a className=" text-xs hover:underline  cursor-pointer font-semibold text-sky-900">
        {item.job.name}
      </a>

      {item.shift && (
        <div className=" grid grid-cols-2 gap-2 mt-4 text-xs font-semibold">
          <div className=" bg-sky-200  text-sky-800 p-2 text-center rounded-lg">
            {[item.job.startAt.time, item.job.EndAt.time].join("-")}
          </div>
          <div className=" bg-green-200 text-green-800 p-2 text-center rounded-lg">
            {moment.unix(item.job.startAt.unix).local().fromNow()}
          </div>
          <div className=" bg-orange-200 text-orange-800 p-2 col-span-full text-center rounded-lg">
            {item.job.totalHour}
          </div>
        </div>
      )}

      {item.reconfirmationApplication && (
        <div className=" grid grid-cols-2 gap-4 mt-2">
          <button
            onClick={() => handleUpdateApplication(Application.NOT_RECONFIRMED)}
            className=" bg-red-100 text-red-800 justify-center flex items-center p-2 rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.498 15.25H4.372c-1.026 0-1.945-.694-2.054-1.715a12.137 12.137 0 0 1-.068-1.285c0-2.848.992-5.464 2.649-7.521C5.287 4.247 5.886 4 6.504 4h4.016a4.5 4.5 0 0 1 1.423.23l3.114 1.04a4.5 4.5 0 0 0 1.423.23h1.294M7.498 15.25c.618 0 .991.724.725 1.282A7.471 7.471 0 0 0 7.5 19.75 2.25 2.25 0 0 0 9.75 22a.75.75 0 0 0 .75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 0 0 2.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384m-10.253 1.5H9.7m8.075-9.75c.01.05.027.1.05.148.593 1.2.925 2.55.925 3.977 0 1.487-.36 2.89-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398-.306.774-1.086 1.227-1.918 1.227h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 0 0 .303-.54"
              />
            </svg>
          </button>
          <button
            onClick={() => handleUpdateApplication(Application.RECONFIRMED)}
            className=" bg-sky-100 text-sky-800 justify-center flex items-center p-2 rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
              />
            </svg>
          </button>
        </div>
      )}
      {item.rate && (
        <div className=" flex justify-center flex-col items-center  gap-4 mt-2">
          <div className=" py-4 px-8 rounded-lg flex justify-center items-center bg-yellow-50">
            <ReactStars
              count={5}
              size={35}
              value={stars}
              onChange={setStars}
              activeColor="#f59e0b"
            />
          </div>

          <button
            onClick={() => handleUpdateShift()}
            className="bg-sky-900 text-sm text-sky-50 w-2/3 p-2 rounded-lg"
          >
            Rate my employer
          </button>
        </div>
      )}
    </li>
  );
}

export default function NotificationsCandidate() {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const { notifOpen } = useSelector((state) => state.conversationSlice);
  const { readDocumentsWithQuery } = useFirestore("notifications");
  const { profile } = useProfileUpdate();
  const [tab, setTab] = useState(0);
  const [stars, setStars] = useState(1);

  const tabs = [
    { name: "All", href: 0, current: tab === 0 },
    { name: "Accepted", href: 1, current: tab === 1 },
    { name: "Rejected", href: 2, current: tab === 2 },
    { name: "Invitations", href: 3, current: tab === 3 },
  ];

  const confirmRating = (id) => {
    dispatch(
      updateStarRatingCandidate({
        id: id,
        params: {
          company: stars,
        },
      })
    );
  };

  const fetchNotification = async () => {
    const query = [
      where("to", "==", profile.id),
      where("job.startAt.unix", "<=", moment.utc().unix()),
      orderBy("creatAt", "desc"),
    ];

    if (tab !== 0) {
      switch (tab) {
        case 1:
          query.push(
            where("title", "==", Notification.TYPE(Notification.NEED_RECONF))
          );
          break;
        case 2:
          query.push(
            where("title", "==", Notification.TYPE(Notification.REJECTED))
          );
          break;
        case 3:
          query.push(
            where("title", "==", Notification.TYPE(Notification.INVITE))
          );
          break;

        default:
          break;
      }
    }

    const result = await readDocumentsWithQuery(query);
    setNotifications(result);
  };

  useEffect(() => {
    if (profile) {
      fetchNotification();

      const unsubscribe = onSnapshot(
        query(
          collection(db, "notifications"),
          where("to", "==", profile.id) // Condition: where name is "test"
        ),
        () => {
          fetchNotification();
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile, notifOpen, tab]);

  return (
    <Transition.Root show={notifOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() =>
          dispatch({
            type: "conversation/setNotifOpen",
          })
        }
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed top-12 bottom-0 right-0 flex max-w-full pl-0 md:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Notifications
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-sky-500"
                            onClick={() =>
                              dispatch({
                                type: "conversation/setNotifOpen",
                              })
                            }
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav className="-mb-px flex space-x-6">
                          {tabs.map((tab) => (
                            <a
                              type="button"
                              key={tab.name}
                              onClick={() => setTab(tab.href)}
                              className={classNames(
                                tab.current
                                  ? "border-sky-400 text-sky-400"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer"
                              )}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <ul
                      role="list"
                      className="flex-1 divide-y divide-gray-200 overflow-y-auto pb-16"
                    >
                      {notifications && notifications.length === 0 && (
                        <p className="px-2 mt-4 text-center animate-pulse font-semibold text-sky-800 text-sm">
                          In this section, you'll find all pending notifications
                          that require a response. Please note, notifications
                          related to jobs that are no longer available will be
                          automatically removed
                        </p>
                      )}

                      {notifications &&
                        notifications.map((item) => <Item item={item} />)}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

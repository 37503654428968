import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import JobCard from "../Job/Job-card";
import useJob from "../../hook/job";
import { useDispatch, useSelector } from "react-redux";
import { DetailSclice } from "../../redux/details";
import NotFound404 from "../not-found-page/404-not-found";
import { baseUrl, haversineDistance } from "../../utils";
import InstantJob, { InstantJobLoading } from "../Job/instant-job";
import GoBack from "../utils/goBack";
import { Border } from "../utils/Border";
import Stars from "../rating/Stars";
import useFirestore from "../../hook/firestore";
import useProfileUpdate from "../../hook/candidate";
import { where } from "firebase/firestore";
import { setId, setOpen } from "../../redux/application";
import moment from "moment";
import Application from "../../Enum/Applicatition";
import { SpinnerLoadData } from "../utils/spinner-load";

function JobItem({ item }) {
  const { readDocument } = useFirestore("companies");
  const { profile } = useProfileUpdate();
  const { readDocumentsWithQuery: jobApplied } = useFirestore("applications");
  const [wasApplied, setWasApplied] = useState();
  const [status, setStatus] = useState();
  const [company, setCompany] = useState();
  const dispatch = useDispatch();
  const [distance, setDistance] = useState(0);
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );

  const checkIfApplied = async () => {
    const result = await jobApplied([
      where("job.id", "==", item.id),
      where("candidate.id", "==", profile.id),
    ]);

    console.log(result);

    if (result.length > 0) {
      setWasApplied(true);
      setStatus(result[0].status);
    }
  };
  const handleClick = () => {
    dispatch(setId(item.id));
    dispatch(setOpen(true));
  };

  const fetchCompany = async () => {
    const result = await readDocument(item.company.id);
    setCompany(result);
  };

  const CalculDistance = async () => {
    const result = await haversineDistance(
      latitude,
      longitude,
      item.location?.latitude,
      item.location?.longitude
    );
    if (result) {
      setDistance(result);
    }
  };

  useEffect(() => {
    if (profile) {
      fetchCompany();
      CalculDistance();
      checkIfApplied();
    }
  }, [item, profile]);

  return (
    <div className=" p-4 py-6 shrink-0  border bg-white shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-[.65rem] text-end">
          {moment.unix(item.creatAt).local().fromNow()}
        </h1>
      </div>

      <div className=" grid md:grid-cols-12 gap-4 py-2 ">
        <div className="  md:col-span-4 flex flex-col gap-2">
          <h1 className="text-sm font-semibold">{item.jobName}</h1>
          <h1 className="text-xs font-semibold">{item.company.name}</h1>
          <h1 className=" text-end font-semibold">
            {company && <Stars number={company.averageStars || 0} />}
          </h1>
        </div>
        <div className=" grid grid-cols-2 gap-2 md:col-span-8  *:rounded-lg ">
          <div className=" bg-gray-100 p-2  text-sky-900 text-xs font-semibold text-center">
            {item.startDate}
          </div>
          <div className=" bg-gray-100 p-2  text-sky-900 text-xs font-semibold text-center">
            {[item.startAt.time, item.endAt.time].join("-")}
          </div>
          <div className=" bg-gray-100  text-sky-900 text-xs p-2 font-semibold text-center ">
            {distance.toFixed(2)} Km Away
          </div>
          <div className=" bg-gray-100   text-sky-900 text-xs p-2 font-semibold text-center">
            {[item.currency, item.hourlyRate].join(" ")}/h
          </div>

          <div className="  grid grid-cols-2 gap-2   col-span-full">
            <Link
              to={`/jobs?id=${item.id}`}
              className=" border text-center text-xs col-span-full bg-white p-2 font-semibold  rounded-lg"
            >
              More details
            </Link>
            <button
              onClick={handleClick}
              disabled={wasApplied}
              className=" disabled:bg-gray-100 disabled:text-sky-600 text-center bg-sky-600 text-xs p-2 col-span-full font-semibold text-white rounded-lg"
            >
              {wasApplied ? status : " Quick Apply"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function JobItemLoading() {
  return (
    <div className=" p-4 py-6 shrink-0  border bg-white shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-[.65rem] text-end rounded-lg px-3 pt-1 bg-gray-100 h-4 w-8 animate-pulse "></h1>
      </div>

      <div className=" grid md:grid-cols-12 gap-4 py-2 ">
        <div className="  md:col-span-4 flex flex-col gap-2">
          <h1 className="text-base font-semibold bg-gray-100 animate-pulse rounded-lg w-full h-4"></h1>
          <h1 className="text-base font-semibold bg-gray-100 animate-pulse rounded-lg w-full h-4"></h1>
          <h1 className="text-base font-semibold bg-gray-100 animate-pulse rounded-lg w-full h-4"></h1>
        </div>
        <div className=" grid grid-cols-2 gap-2 md:col-span-8  *:rounded-lg ">
          <div className=" bg-gray-100 p-2  animate-pulse text-sky-50 text-xs font-semibold text-center">
            2024-01-01
          </div>
          <div className=" bg-gray-100 p-2  animate-pulse text-sky-50 text-xs font-semibold text-center">
            07:15 PM - 11:15 PM
          </div>
          <div className=" bg-gray-100 animate-pulse  text-sky-50 text-xs p-2 font-semibold text-center ">
            12km Away
          </div>
          <div className=" bg-gray-100 animate-pulse   text-sky-50 text-xs p-2 font-semibold text-center">
            21.00 GBP/H
          </div>

          <div className="  grid grid-cols-2 gap-2   col-span-full">
            <button className="  text-sky-50 text-center text-xs col-span-full bg-gray-100 animate-pulse p-2 font-semibold  rounded-lg">
              More details
            </button>
            <button className=" text-center bg-sky-600 animate-pulse text-xs p-2 col-span-full font-semibold text-sky-600 rounded-lg">
              {" "}
              Quick Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export const Company = ({ admin = false }) => {
  const {
    readDocumentsWithQuery,
    countDocumentsWithQuery: countJobs,
    loading,
  } = useFirestore("jobs");
  const {
    countDocumentsWithQuery: countApplicants,
    loading: loadingApplicants,
  } = useFirestore("applications");
  const { readDocument, loading: loadingCompany } = useFirestore("companies");
  const [company, setCompany] = useState({});

  const [jobs, setJobs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = useState({
    jp: 0,
    appl: 0,
    acpt: 0,
    stars: 0,
  });

  useEffect(() => {
    (async () => {
      const query = [where("company.id", "==", searchParams.get("id"))];

      const result = await readDocumentsWithQuery(query);
      const companyDetails = await readDocument(searchParams.get("id"));
      setCompany(companyDetails);
      const jp = await countJobs(query);
      const appl = await countApplicants([
        where("job.company", "==", searchParams.get("id")),
      ]);
      const acpt = await countApplicants([
        where("job.company", "==", searchParams.get("id")),
        where("status", "==", Application.ACCEPTED),
      ]);

      setCount({
        jp,
        appl,
        acpt,
        stars: companyDetails.averageStars || 0,
      });
      setJobs(result);
    })();
  }, [searchParams.get("id")]);

  return (
    <div>
      <GoBack />
      <div className="lg:grid max-lg:flex flex-col  lg:grid-cols-12 gap-4 sm:p-3 p-2  ">
        <div className="md:col-span-4  grid  ">
          <div className="  border-1 rounded-lg border-gray-300 p-2 sm:p-4 shadow-sm h-fit ring-1 ring-offset-slate-400 ring-gray-200 relative">
            {loadingCompany && <SpinnerLoadData />}
            <div className="h-32  relative bg-gradient-to-t rounded-md  to-sky-800 from-sky-300">
              {!company.logoUrl && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="inline-block h-20 w-20 rounded-md absolute bottom-[-3rem] ms-6 text-white bg-gradient-to-t from-sky-700 to-slate-200"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                  />
                </svg>
              )}

              {company.logoUrl && (
                <img
                  src={company.logoUrl}
                  alt="logo"
                  className="object-cover h-20 w-20 rounded-lg absolute bottom-[-3rem] ms-6"
                  srcset=""
                />
              )}
            </div>
            <div className=" px-4 ms-[calc(6rem+2px)] mt-1">
              <h1 className="font-extrabold text-sm ">
                {company && company.name}
              </h1>
              <span className="inline-flex space-x-3 text-xs text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
                {company && company.location?.city}
              </span>
            </div>
            <div className="grid mt-5 grid-cols-1 gap-y-3 font-medium">
              <dl>
                <dt className="text-sm text-slate-500 not-italic">
                  Email address
                </dt>
                <dd className="text-sm">
                  {" "}
                  <address>
                    <a> {company && company.email}</a>
                  </address>
                </dd>
              </dl>
              <dl>
                <dt className="text-sm text-slate-500">Phone</dt>
                <dd className="text-sm">
                  <address>
                    <a>{company && company.phone}</a>
                  </address>
                </dd>
              </dl>
              <dl>
                <dt className="text-sm text-slate-500">Company address</dt>
                <dd className="text-sm">
                  {company &&
                    [
                      company.location?.street,
                      company.location?.city,
                      company.location?.zip,
                      company.location?.state,
                    ].join(" ")}
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className=" grid col-span-8 gap-4 grid-cols-2 ">
          <div className="   bg-slate-100  rounded-lg p-4     flex flex-col gap-2 justify-center items-center relative">
            {loading && <SpinnerLoadData />}
            <span className="font-semibold text-xs  text-center">
              Jobs posted
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-16  text-sky-600/40"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
              />
            </svg>

            <span className="text-center text-2xl text-sky-800 font-extrabold">
              {count.jp}
            </span>
          </div>
          <div className="   bg-slate-100  rounded-lg p-4   flex flex-col gap-2 justify-center items-center relative">
            {loadingApplicants && <SpinnerLoadData />}
            <span className="font-semibold text-xs  text-center">
              Applicants
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-16 opacity-40 text-blue-600/40"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
              />
            </svg>

            <span className="text-center text-2xl text-sky-800 font-extrabold">
              {count.appl}
            </span>
          </div>
          <div className="   bg-slate-100  rounded-lg p-4   flex flex-col gap-2 justify-center items-center relative">
            {loadingApplicants && <SpinnerLoadData />}
            <span className="font-semibold text-xs  text-center">Accepted</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-16 opacity-40 text-green-600/40"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>

            <span className="text-center text-2xl text-sky-800 font-extrabold">
              {count.acpt}
            </span>
          </div>
          <div className="   bg-slate-100  rounded-lg p-4   flex flex-col gap-2  justify-center items-center relative">
            {loadingCompany && <SpinnerLoadData />}
            <span className="font-semibold text-xs  text-center">
              Stars rating
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-16 opacity-40 text-orange-600/40"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
              />
            </svg>

            <span className="text-center text-2xl text-sky-800 font-extrabold">
              {[count.stars, "5"].join("/")}
            </span>
          </div>
        </div>
      </div>

      <div className="static p-3 ">
        <h1 className="text-sm font-semibold mb-2 text-sky-800 ">
          Live positions
        </h1>

        {!loading && (
          <div className="    gap-4  w-full overflow-x-hidden hover:overflow-x-scroll flex rounded-lg">
            {jobs.map((item) => (
              <JobItem item={item} />
            ))}
          </div>
        )}

        {loading && (
          <div className="    gap-4  w-full overflow-x-hidden hover:overflow-x-scroll flex rounded-lg">
            <JobItemLoading />
            <JobItemLoading />
            <JobItemLoading />
            <JobItemLoading />
          </div>
        )}
      </div>
    </div>
  );
};

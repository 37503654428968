import {
  Banknote,
  CalendarArrowDown,
  Eye,
  FileSymlink,
  Paperclip,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import useFirestore from "../../../hook/firestore";
import { useNavigate, useSearchParams } from "react-router-dom";
import Details from "./Details";
import moment from "moment";
import { classNames } from "../../../components/utils/className";
import ItemEmpty from "../../../components/utils/empty";

function Item({ item }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      className={classNames(
        " relative w-full bg-white p-4 py-8 border rounded-lg",
        searchParams.get("id") === item.id && "border-violet-900"
      )}
    >
      <span className="absolute top-0 right-0  rounded-bl-lg p-2 bg-green-50 text-green-800 font-semibold  text-sm">
        {item.status}
      </span>
      <div>
        <h1 className="text-base font-bold text-sky-800">
          {item.customer_name || item.customer_email}
        </h1>
        <p className="text-xs text-slate-800 font-medium">{item.customer}</p>
      </div>
      <hr className="my-2" />
      <div className=" flex flex-col gap-2 mt-3 items-end text-sm ">
        <div className="text-sm  text-s items-center gap-2 font-semibold flex ">
          <Banknote className=" size-4" />{" "}
          <span>
            {[item.currency || "--", (item.amount_due / 100).toFixed(2)]
              .join(" ")
              .toUpperCase()}
          </span>
        </div>
        <div className="text-sm flex gap-2 items-center font-semibold">
          <CalendarArrowDown className=" size-4 " />{" "}
          <span>
            {moment.unix(item.period_end).local().format("MM/DD/YYYY")}
          </span>
        </div>
      </div>
      <hr className="my-2" />
      <div className="w-full flex  gap-3 justify-end items-center">
        {item.hosted_invoice_url && (
          <a
            href={item.hosted_invoice_url}
            className="bg-sky-800 hover:bg-sky-600 text-center text-sky-100 rounded-lg  p-2"
          >
            <Paperclip className="size-5" />
          </a>
        )}
        <button
          onClick={() => navigate(`.?id=${item.id}`)}
          className="bg-sky-800 hover:bg-sky-600 text-center text-sky-100 rounded-lg  p-2"
        >
          <Eye className="size-5" />
        </button>
      </div>
    </div>
  );
}
function ItemLoad() {
  return (
    <div className=" relative bg-white p-4 border rounded-lg">
      <span className="absolute top-0 right-0 p-2 rounded-bl-lg bg-gray-100 animate-pulse text-transparent font-semibold  text-sm">
        status
      </span>
      <div className="space-y-1 mt-8 flex flex-col">
        <h1 className="text-base font-bold bg-gray-100 animate-pulse text-transparent">
          Company
        </h1>
        <p className="text-xs bg-gray-100 animate-pulse text-transparent font-medium">
          CustomerID
        </p>
      </div>
      <hr className="my-2" />
      <div className=" flex flex-col gap-2 mt-3 items-end text-sm ">
        <div className="text-sm bg-gray-100 animate-pulse text-transparent  text-s items-center gap-2 font-semibold flex ">
          <Banknote className=" size-4" /> <span>USD 24,00</span>
        </div>
        <div className="text-sm flex gap-2 bg-gray-100 animate-pulse text-transparent items-center font-semibold">
          <CalendarArrowDown className=" size-4 " /> <span>10/10/2024</span>
        </div>
      </div>
      <hr className="my-2" />
      <div className="w-full flex justify-center items-center">
        <button className="bg-gray-100 animate-pulse text-transparent rounded-lg w-full">
          More details
        </button>
      </div>
    </div>
  );
}

export default function Invoices() {
  const { callableFunction, loading } = useFirestore("shifts");
  const [invoices, setInvoices] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [invoiceId, setInvoiceId] = useState();
  const [customer, setCustomer] = useState();

  const fetchInvoices = async () => {
    try {
      const params = {};
      if (invoiceId) params.invoiceId = invoiceId;

      if (customer) {
        if (customer.includes("@")) {
          // Si 'customer' contient un '@', c'est un email
          params.customerEmail = customer;
        } else if (customer.startsWith("cus_")) {
          // Si 'customer' commence par 'cus_', c'est un ID client
          params.customerId = customer;
        } else {
          // Sinon, c'est un nom de client
          params.customerName = customer;
        }
      }

      const { success, invoices } = await callableFunction(
        "getInvoices",
        params
      );

      if (success) {
        const data = invoiceId ? invoices : invoices.data;
        setInvoices(data);
      } else {
        setInvoices([]);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des factures :", error);
      setInvoices([]);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <div className="">
      <div className="px-4 text-sky-800 text-2xl">Invoices</div>
      <hr className="my-3" />
      <div className=" grid md:grid-cols-2 gap-4">
        <div className=" bg-gray-100 p-4 flex flex-col gap-2 rounded-lg relative">
          <button
            onClick={fetchInvoices}
            className=" absolute top-[50%] text-sm font-semibold py-1 right-6 hover:bg-indigo-200 bg-indigo-100 text-indigo-800 rounded-lg px-6 "
          >
            Go
          </button>
          <span className="text-sm text-sky-700">Invoice</span>
          <input
            type="text"
            className=" w-full rounded-lg border-gray-200 text-sm  "
            onChange={(e) => setInvoiceId(e.target.value)}
            placeholder="in_1Oi9hFI7eOuKUJKP1EWic4YP"
          />{" "}
        </div>
        <div className=" bg-gray-100 p-4 flex flex-col gap-2 relative rounded-lg">
          <button
            onClick={fetchInvoices}
            className=" absolute top-[50%] text-sm font-semibold py-1 right-6 hover:bg-indigo-200 bg-indigo-100 text-indigo-800 rounded-lg px-6 "
          >
            Go
          </button>
          <span className="text-sm text-sky-700">Customer</span>
          <input
            type="text"
            onChange={(e) => setCustomer(e.target.value)}
            className=" w-full rounded-lg border-gray-200 text-sm  "
            placeholder="cus_O4aQMvycCB0h9I / name / email"
          />{" "}
        </div>
      </div>

      <hr className=" my-4" />
      <div className=" grid md:grid-cols-12 mt-6 ">
        <div className=" md:col-span-4 h-[80vh] overflow-y-auto  space-y-3  ">
          {!loading && invoices.map((item) => <Item item={item} />)}

          {loading && [
            <ItemLoad />,
            <ItemLoad />,
            <ItemLoad />,
            <ItemLoad />,
            <ItemLoad />,
          ]}

          {invoices.length === 0 && <ItemEmpty />}
        </div>
        <div className=" md:col-span-8 p-4 flex justify-center items-center h-[80vh]">
          {searchParams.get("id") ? (
            <Details />
          ) : (
            <div>
              <span className="  border border-dashed rounded-lg p-4 animate-pulse text-sky-800 font-semibold">
                Select invoice
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import Roles from "../Enum/UserRoles";

export async function getUserFromCollection(uid) {
  const collections = [
    { name: "candidates", role: Roles.candidate },
    { name: "companies", role: Roles.company },
  ];

  // Vérifier d'abord si l'utilisateur est un candidat ou un propriétaire d'entreprise
  for (const { name, role } of collections) {
    const docRef = doc(db, name, uid);
    const userSnapshot = await getDoc(docRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return { ...userData, role }; // Utilise le rôle défini dans l'objet
    }
  }

  // Si l'utilisateur n'est pas trouvé dans les collections candidates ou companies,
  // vérifier s'il est un sous-utilisateur dans les entreprises
  const companiesSnapshot = await getDocs(collection(db, "companies"));

  for (const companyDoc of companiesSnapshot.docs) {
    const companyData = companyDoc.data();

    if (companyData.subUsers && Array.isArray(companyData.subUsers)) {
      const subUser = companyData.subUsers.find((sub) => sub.uid === uid);

      if (subUser) {
        // Si l'utilisateur est trouvé comme sous-utilisateur, retourner ses données avec le rôle
        return {
          ...subUser,
          companyId: companyDoc.id,
          displayName: companyData.name,
          role: subUser.role,
          photoURL: companyData.logoUrl,
        };
      }
    }
  }

  // Vérifier si l'utilisateur est un admin
  const adminRef = doc(db, "admins", uid);
  const adminSnapshot = await getDoc(adminRef);

  if (adminSnapshot.exists()) {
    const adminData = adminSnapshot.data();
    return { ...adminData, role: Roles.admin }; // Utilise le rôle 'admin' pour cet utilisateur
  }

  // Si l'utilisateur n'est trouvé dans aucune collection
  throw new Error("User not found in any collection");
}

export async function addUserToCollection(collectionName, userData, uid) {
  try {
    await setDoc(doc(db, collectionName, uid), userData);
  } catch (error) {
    console.error("Error adding user to collection:", error);
    throw new Error("Failed to add user to the database");
  }
}

// Fonction pour valider les données d'inscription
function validateRegistrationData(data) {
  // Ajouter ici des validations spécifiques selon vos besoins
  if (!data.email || !data.password) {
    throw new Error("Missing required fields");
  }
  // Plus de validations peuvent être ajoutées ici
}

// Fonction pour enregistrer un utilisateur
export async function registerUser(params) {
  validateRegistrationData(params);

  // Transaction pour s'assurer que l'opération est atomique
  try {
    await runTransaction(db, async (transaction) => {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        params.email,
        params.password
      );
      const user = userCredential.user;

      // Déterminer la collection en fonction du rôle de l'utilisateur
      const collectionName =
        params.type.id === Roles.company ? "companies" : "candidates";

      // Ajouter l'utilisateur à la collection appropriée
      const userData = params;
      delete userData.password;
      delete userData.type;

      await updateProfile(auth.currentUser, {
        displayName: params.name,
      });

      await addUserToCollection(collectionName, userData, user.uid);
    });
  } catch (error) {
    console.error("Error during registration:", error);
    throw new Error("Registration failed");
  }
}

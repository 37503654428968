import { useEffect, useState } from "react";

import useFirestore from "./firestore";

export default function useCurrencies() {
  const { readAllDocuments } = useFirestore("currencies");
  const [currencies, setCurrencies] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const docs = await readAllDocuments();
      setCurrencies(docs);
    };

    fetchData();
  }, []);

  return currencies;
}

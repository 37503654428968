import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useCurrentBreakpoint from "../../hook/tailwindBreakPoint";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setFirstName,
  setLastName,
  setLocation,
  setPhone,
  setPrefix,
  setRole,
} from "../../redux/registration";
import { setClose } from "../../redux/teams";
import RadioRole from "./radio-group";
import { createSubUser } from "../../redux/users";
import useFirestore from "../../hook/firestore";
import { useLocation, useSearchParams } from "react-router-dom";
import { SpinnerLoadData } from "../utils/spinner-load";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CommandTeams() {
  const { callableFunction, getOneSubUser, updateSubUser, loading } =
    useFirestore("companies");
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const brk = useCurrentBreakpoint();
  const { open, role } = useSelector((state) => state.TeamSlice);
  const locate = useLocation();

  const [formData, setFormData] = useState({});
  const [location, setLocation] = useState({});

  const handleChangeLocation = (e) => {
    const { name, value } = e.target;

    setLocation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = formData;
    params.role = role.id;
    params.location = location;

    if (!searchParams.get("id")) {
      params.password = "Majicavo976MJK@@";
      params.name = [params.firstName, params.lastName].join(" ");
      delete params.firstName;
      delete params.lastName;
      await callableFunction("addSubUserToCompany", params).then(() => {
        dispatch(setClose());
      });
    }
    if (searchParams.get("id")) {
      await updateSubUser(searchParams.get("id"), params).then(() => {
        dispatch(setClose());
      });
    }
  };

  const fetchOneSub = async () => {
    const result = await getOneSubUser(searchParams.get("id"));
    setFormData(result);
    setLocation(result.location);
  };

  useEffect(() => {
    if (
      searchParams.get("id") &&
      locate.pathname.startsWith("/profile/team-members")
    ) {
      fetchOneSub();
    }
  }, [searchParams.get("id")]);

  console.log(formData);

  return (
    <Transition.Root show={open} as={Fragment} appear>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => dispatch(setClose())}
      >
        <Transition.Child
          as={Fragment}
          enter={(brk !== "sm" || brk !== "xs") && "ease-out duration-300"}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={(brk !== "sm" || brk !== "xs") && "ease-in duration-200"}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed flex justify-center inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto py-20 px-4  md:p-20">
          <Transition.Child
            as={Fragment}
            enter={(brk !== "sm" || brk !== "xs") && "ease-out duration-300"}
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave={(brk !== "sm" || brk !== "xs") && "ease-in duration-200"}
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl  transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <form
                onSubmit={(e) => handleSubmit(e)}
                className="h-auto p-3 relative "
              >
                {loading && searchParams.get("id") && <SpinnerLoadData />}

                <h1 className="text-sm text-sky-800 font-semibold p-4">
                  New team member
                </h1>
                <hr className="my-3" />

                <div class="mt-5">
                  <div
                    onSubmit={handleSubmit}
                    className="gap-y-4 gap-x-3 grid grid-cols-6 "
                  >
                    <div
                      className={classNames(
                        "col-span-full",
                        !searchParams.get("id") && "hidden"
                      )}
                    >
                      <input
                        id="name"
                        name="name"
                        value={formData.name}
                        disabled
                        type="text"
                        autoComplete="given-name"
                        placeholder="First name"
                        required
                        className=" rounded-lg w-full cursor-not-allowed bg-gray-100 border-none text-sm "
                      />
                    </div>
                    {!searchParams.get("id") && (
                      <div className={classNames("col-span-3")}>
                        <input
                          id="firstName"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          type="text"
                          autoComplete="given-name"
                          placeholder="First name"
                          required
                          className=" rounded-lg w-full bg-gray-100 border-none text-sm "
                        />
                      </div>
                    )}

                    {!searchParams.get("id") && (
                      <div className={classNames("col-span-3")}>
                        <input
                          id="lastName"
                          name="lastName"
                          value={formData.lastName}
                          placeholder="Last name"
                          type="text"
                          onChange={handleChange}
                          autoComplete="family-name"
                          required
                          className=" rounded-lg w-full bg-gray-100 border-none text-sm "
                        />
                      </div>
                    )}

                    <div className="col-span-3">
                      <input
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        type="tel"
                        onChange={handleChange}
                        autoComplete="tel"
                        placeholder="phone"
                        required
                        className=" rounded-lg w-full bg-gray-100 border-none text-sm "
                      />
                    </div>
                    <div className="col-span-3">
                      <input
                        id="email"
                        name="email"
                        value={formData.email}
                        type="email"
                        onChange={handleChange}
                        autoComplete="email"
                        required
                        placeholder="email"
                        className=" rounded-lg w-full bg-gray-100 border-none text-sm "
                      />
                    </div>

                    <div className="col-span-full">
                      <RadioRole />
                    </div>

                    <div className="col-span-full my-5">
                      <div className="relative">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                          <span className="bg-white px-2 text-sm text-gray-500">
                            Extra data
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-full grid gap-4 grid-cols-2">
                      <input
                        id="address"
                        name="street"
                        value={location.street}
                        type="text"
                        onChange={handleChangeLocation}
                        autoComplete="street-address"
                        required
                        placeholder="street"
                        className=" rounded-lg w-full bg-gray-100 border-none text-sm "
                      />
                      <input
                        id="address"
                        name="city"
                        value={location.city}
                        type="text"
                        onChange={handleChangeLocation}
                        autoComplete="address-level2"
                        required
                        placeholder="city"
                        className=" rounded-lg w-full bg-gray-100 border-none text-sm "
                      />
                      <input
                        id="address"
                        name="zip"
                        value={location.zip}
                        type="text"
                        onChange={handleChangeLocation}
                        autoComplete="postal-code"
                        required
                        placeholder="zip"
                        className=" rounded-lg w-full bg-gray-100 border-none text-sm "
                      />
                      <input
                        id="address"
                        name="state"
                        value={location.state}
                        type="text"
                        onChange={handleChangeLocation}
                        autoComplete="address-level1"
                        required
                        placeholder="state"
                        className=" rounded-lg w-full bg-gray-100 border-none text-sm "
                      />
                    </div>
                  </div>
                </div>
                <div className="my-6 flex justify-center space-x-4 items-center">
                  <button
                    type="button"
                    onClick={() => dispatch(setClose())}
                    className="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-800 shadow-sm hover:bg-red-100"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="rounded-md disabled:bg-gray-400 disabled:text-white bg-sky-50 px-3.5 py-2.5 text-sm font-semibold text-sky-800 shadow-sm hover:bg-sky-100"
                  >
                    {searchParams.get("id") && <span> Update</span>}
                    {!searchParams.get("id") && <span> Add new member</span>}
                  </button>
                </div>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Shift } from "../services/shift";

const shift = new Shift();
export const shiftOne = createAsyncThunk(
  "shift/getOneShift",
  async ({ id }, thunkAPI) => {
    const response = await shift.getOneShift(id);

    return response;
  }
);
export const fetchShifts = createAsyncThunk(
  "shift/fetchData",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { date_f, page, limit, search, filter } = state.ShiftSlice;

    const response = await shift.getShift(page, 10, search, filter);

    return response;
  }
);
export const fetchTableFinanceShifts = createAsyncThunk(
  "shift/fetchTableFinanceData",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { date_f, page, limit } = state.ShiftSlice;

    console.log(date_f);

    const response = await shift.getTableShift(date_f, page, limit);

    return response;
  }
);
export const fetchTableFinanceShiftsCandidate = createAsyncThunk(
  "shift/fetchTableFinanceDataCandidate",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { date_f, page } = state.ShiftSlice;

    console.log(date_f);

    const response = await shift.getTableShiftCandidate(date_f, page, 10);

    return response;
  }
);
export const fetchStats = createAsyncThunk(
  "shift/fetchStats",
  async (arg, thunkAPI) => {
    const response = await shift.financeStats();

    return response;
  }
);

export const updateShift = createAsyncThunk(
  "shift/updateShift",
  async ({ id, params = {} }, thunkAPI) => {
    await shift.updateShift(id, params);

    const state = thunkAPI.getState();
    const { date_f, page, limit, search, filter } = state.ShiftSlice;
    const response = await shift.getShift(page, 10, search, filter);
    const response1 = await shift.getOneShift(id);

    return { response, response1 };
  }
);
export const updateStarRating = createAsyncThunk(
  "shift/updateStarRating",
  async ({ id, params = {} }, thunkAPI) => {
    await shift.updateStarRating(id, params);

    const state = thunkAPI.getState();
    const { date_f, page, limit, search, filter } = state.ShiftSlice;
    const response = await shift.getShift(page, 10, search, filter);
    const response1 = await shift.getOneShift(id);

    return { response, response1 };
  }
);
export const updateStarRatingCandidate = createAsyncThunk(
  "shift/updateStarRatingCandidate",
  async ({ id, params = {} }, thunkAPI) => {
    await shift.updateStarRating(id, params);
  }
);

export const ShiftSlice = createSlice({
  name: "ShiftSlice",
  initialState: {
    shifPunch: false,
    loading: false,
    oneShift: null,
    idShift: null,
    shifts: null,
    tableFinance: [],
    date_f: undefined,
    limit: 5,
    page: 1,
    search: "",
    filter: "",
    stats: {},
  },
  reducers: {
    setShiftPunch: (state, action) => {
      state.shifPunch = !state.shifPunch;
    },
    setIdShift: (state, action) => {
      state.idShift = action.payload;
    },
    setClose: (state) => {
      state.shifPunch = false;
    },
    setDateF: (state, action) => {
      state.date_f = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(shiftOne.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTableFinanceShifts.fulfilled, (state, action) => {
      state.tableFinance = action.payload;
    });
    builder.addCase(
      fetchTableFinanceShiftsCandidate.fulfilled,
      (state, action) => {
        state.tableFinance = action.payload;
      }
    );
    builder.addCase(shiftOne.fulfilled, (state, action) => {
      state.loading = false;
      state.oneShift = action.payload;
    });
    builder.addCase(updateShift.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateShift.fulfilled, (state, action) => {
      state.loading = false;
      state.shifts = action.payload.response;
      state.oneShift = action.payload.response1;
    });
    builder.addCase(updateStarRating.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStarRating.fulfilled, (state, action) => {
      state.loading = false;
      state.shifts = action.payload.response;
      state.oneShift = action.payload.response1;
    });
    builder.addCase(fetchShifts.fulfilled, (state, action) => {
      state.shifts = action.payload;
    });
    builder.addCase(fetchStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });
  },
});

export const {
  setDateF,
  setPage,
  setSearch,
  setFilter,
  setShiftPunch,
  setIdShift,
} = ShiftSlice.actions;

import { PlusIcon, XCircleIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListOfAdmin from "./list-of-user";
import { useGetAdmins } from "../../../hook/admin";
import { Button } from "../../../components/utils/Button";
import { openSideUser, setPage } from "../../../redux/admin";
import {
  checkPasswordAnChange,
  setConfirmPassword,
  setNewPassword,
  setPasswordToCheck,
} from "../../../redux/users";
import { auth, db } from "../../../firebase";
import GenericImg from "../../../components/layout/generic-user-img";
import { UserX } from "lucide-react";
import useFirestore from "../../../hook/firestore";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { collection, onSnapshot, query } from "firebase/firestore";
import { updateProfile } from "firebase/auth";

function ItemNew() {
  const handleInvite = (e) => {
    e.preventDefault();
  };

  return (
    <form
      onSubmit={handleInvite}
      className=" p-4  col-span-full flex justify-center items-center flex-col gap-4 rounded-lg border border-dashed"
    >
      <span className="text-sm font-semibold text-sky-800">
        Invite someone join the team
      </span>

      <input
        type="email"
        placeholder="email@tempme.co"
        className="w-full rounded-lg text-sm bg-gray-100 border-none"
      />
      <div className="flex justify-end items-end w-full">
        <button
          type="submit"
          className=" bg-sky-800 text-slate-50  border px-6 p-2 rounded-lg"
        >
          Send
        </button>
      </div>
    </form>
  );
}

function Item({ item }) {
  const { deleteDocument } = useFirestore("admins");

  const handleDelete = async (id) => {
    const dlt = await deleteDocument(id);
  };

  return (
    <div className=" rounded-lg border p-4">
      <h1 className="text-sm font-semibold text-sky-800">{item.name}</h1>
      <hr className="my-3" />
      <div className=" space-y-1">
        <p className="text-xs">{item.email}</p>
        <p className="text-xs">{item.phone}</p>
      </div>

      <div className=" grid grid-cols-2 gap-3 mt-3">
        <button
          onClick={() => handleDelete(item.id)}
          className="  col-span-full flex justify-center items-center bg-red-50 text-red-800 hover:bg-red-100 rounded-lg p-2"
        >
          <UserX />
        </button>
      </div>
    </div>
  );
}

function Profile() {
  const {
    readAllDocuments,
    readDocument,
    updateDocument,
    UploadDocument,
    loading,
  } = useFirestore("admins");
  const [admins, setAdmins] = useState([]);
  const [file, setFile] = useState();
  const [currentUser, setCurrentUser] = useState({
    name: "",
    phone: "",
  });

  const fetchAdmins = async () => {
    const result = await readAllDocuments();

    setAdmins(result);
  };

  const currentAdmin = async () => {
    const result = await readDocument(auth.currentUser.uid);
    setCurrentUser(result);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;

    setCurrentUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const params = currentUser;

    if (file) {
      const url = await UploadDocument(file);
      params.imgUrl = url;
      await updateProfile(auth.currentUser, {
        photoURL: url,
      });
    }
    params.email = auth.currentUser.email;
    await updateDocument(currentUser.id, params);
  };

  useEffect(() => {
    if (auth.currentUser.uid) {
      currentAdmin();
    }
  }, [auth.currentUser.uid]);

  useEffect(() => {
    fetchAdmins();

    const unsubscribe = onSnapshot(query(collection(db, "admins")), () => {
      fetchAdmins();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="">
      <div className=" grid grid-cols-12 gap-4">
        <div className=" col-span-4 ">
          <form
            onSubmit={handleUpdate}
            className=" border flex flex-col items-center gap-6 p-4 rounded-lg relative"
          >
            {loading && <SpinnerLoadData />}

            <div>
              {" "}
              <h1 className=" text-center font-semibold text-sky-900 ">
                Administrator
              </h1>
              <p className="text-xs ">{auth.currentUser.email}</p>
            </div>

            <div className=" rounded-full bg-gray-100 size-20">
              {!file ? (
                auth.currentUser.photoURL ? (
                  <img
                    className="size-full rounded-full object-cover"
                    src={auth.currentUser.photoURL}
                    alt=""
                  />
                ) : (
                  <GenericImg />
                )
              ) : (
                <img
                  className="size-full rounded-full object-cover"
                  src={URL.createObjectURL(file)}
                />
              )}
            </div>

            <button
              type="button"
              onClick={(e) => e.currentTarget.nextElementSibling.click()}
              className="text-sm font-semibold bg-sky-100 text-sky-800 rounded-lg px-6 py-2"
            >
              Upload Image
            </button>

            <input
              type="file"
              name="file"
              accept="image/*"
              className="hidden"
              id=""
              onChange={(e) => setFile(e.currentTarget.files[0])}
            />

            <div className=" grid  w-full gap-4">
              <input
                type="text"
                value={currentUser.name}
                name="name"
                onChange={handleChange}
                className=" border-none rounded-lg text-sm bg-gray-100"
                placeholder={
                  auth.currentUser.displayName || "Adminstrator name"
                }
              />
              <input
                type="text"
                name="phone"
                onChange={handleChange}
                value={currentUser.phone}
                className=" border-none rounded-lg text-sm bg-gray-100"
                placeholder={
                  auth.currentUser.phoneNumber || "Adminstrator phone number"
                }
              />
            </div>

            <div className=" flex justify-end items-end w-full ">
              <button
                type="submit"
                className=" bg-sky-800  py-2 text-sky-50 px-4 rounded-lg"
              >
                Validate
              </button>
            </div>
          </form>
        </div>

        <div className=" col-span-8 ">
          <div className=" p-4 rounded-lg size-full border h-[90vh]">
            <h1 className=" font-semibold ">List of admin</h1>
            <hr className=" my-4" />

            <div className=" w-full grid gap-3 grid-cols-3">
              {admins.map((item) => (
                <Item item={item} />
              ))}
              <ItemNew />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

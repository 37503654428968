import { configureStore } from "@reduxjs/toolkit";
import { CalendarSlice } from "./Calendar";
import { ApplicationSlice } from "./application";
import { FilterSlice } from "./Filter";
import { GeolocationSlice } from "./geolocation";
import { DetailSlice } from "./details";
import { conversationSlice } from "./conversation";
import { candidateSlice } from "./candidate";
import { companySlice } from "./company";
import { authenticationSlice } from "./authentication";
import { ShiftSlice } from "./shift";
import { RegistrationSlice } from "./registration";
import socketReducer, { setupSocket, socketSlice } from "./socketSlice";
import { NavigationSlice } from "./navigation";
import { TeamSlice } from "./teams";
import { UserSlice } from "./users";
import { adminSlice } from "./admin";
import { stripeSlice } from "./stripe";
import { ToastSlice } from "./toast";

export const mainStore = configureStore({
  reducer: {
    CalendarSlice: CalendarSlice.reducer,
    ApplicationSlice: ApplicationSlice.reducer,
    FilterSlice: FilterSlice.reducer,
    GeolocationSlice: GeolocationSlice.reducer,
    DetailSlice: DetailSlice.reducer,
    conversationSlice: conversationSlice.reducer,
    candidateSlice: candidateSlice.reducer,
    companySlice: companySlice.reducer,
    authenticationSlice: authenticationSlice.reducer,
    ShiftSlice: ShiftSlice.reducer,
    RegistrationSlice: RegistrationSlice.reducer,
    NavigationSlice: NavigationSlice.reducer,
    socket: socketReducer,
    TeamSlice: TeamSlice.reducer,
    UserSlice: UserSlice.reducer,
    adminSlice: adminSlice.reducer,
    stripeSlice: stripeSlice.reducer,
    ToastSlice: ToastSlice.reducer,
    socketSlice: socketSlice.reducer,
  },
});

import React from "react";

function ItemEmpty() {
  return (
    <div className=" bg-gray-100 rounded-lg p-4">
      <h1 className="text-sm text-sky-800">No more data to load</h1>
    </div>
  );
}

export default ItemEmpty;

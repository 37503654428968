import React, { useEffect, useState } from "react";

import useFirestore from "../../../hook/firestore";
import UpcomingShifts from "./upcoming-shift";
import Chart from "./chart";
import ListBoxChart from "./listboxchart";
import { FaUserTie } from "react-icons/fa6";
import { Link } from "react-router-dom";

import { BiSolidMessageRounded } from "react-icons/bi";
import Transactions from "./transactions";
import Users from "./users";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";
import MessagesIcon from "../../../components/layout/messagesIcon";
import { BuildingOfficeIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

const optionsViewType = [
  { id: 1, name: "Users", type: "users" },
  { id: 2, name: "Candidates", type: "candidates" },
  { id: 3, name: "Companies", type: "companies" },
  { id: 4, name: "Jobs", type: "jobs" },
];
const optionsViewTime = [
  { id: 1, name: "Current year", year: new Date().getFullYear() },
  { id: 2, name: "Last Year", year: new Date().getFullYear() - 1 },
  { id: 3, name: "2 Years ago", year: new Date().getFullYear() - 2 },
];

function ChartItem() {
  const [dataType, setDataType] = useState("users");
  const [year, setYear] = useState(new Date().getFullYear());
  const [count, setCount] = useState({
    users: 0,
    candidates: 0,
    companies: 0,
    jobs: 0,
  });

  const { countAllDocuments: jobsCount } = useFirestore("jobs");
  const { countAllDocuments: companiesCount } = useFirestore("companies");
  const { countAllDocuments: candidateCount } = useFirestore("candidates");

  const fetchCounts = async () => {
    const jc = await jobsCount();
    const cp = await companiesCount();
    const cd = await candidateCount();

    const params = {
      jobs: jc,
      candidates: cd,
      companies: cp,
      users: cd + cp,
    };

    setCount(params);
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  return (
    <div className="  md:h-[35rem] bg-white shadow   rounded-lg border p-2">
      <div className=" flex  overflow-hidden hover:overflow-x-scroll *:shrink-0 gap-4 *:rounded-lg ">
        <div
          onClick={() => setDataType("users")}
          className=" col-span-1 gap-2 flex border px-4 items-center cursor-pointer  p-2 "
        >
          <div className=" bg-gray-100 p-6 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-4xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-violet-700 to-sky-700">
              {count.users}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Total users</h1>
          </div>
        </div>
        <div
          onClick={() => setDataType("candidates")}
          className=" col-span-1 gap-2 border px-4 flex items-center cursor-pointer  p-2 "
        >
          <div className=" bg-gray-100 p-6 rounded-lg">
            <FaUserTie className="size-6 text-sky-800" />
          </div>

          <div className=" flex flex-col">
            <h1 className="text-4xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-violet-700 to-sky-700">
              {count.candidates}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Candidates</h1>
          </div>
        </div>
        <div
          onClick={() => setDataType("companies")}
          className=" col-span-1 gap-2 border px-4 flex items-center cursor-pointer  p-2 "
        >
          <div className=" bg-gray-100 p-6 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-4xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-violet-700 to-sky-700">
              {count.companies}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Companies</h1>
          </div>
        </div>
        <div
          onClick={() => setDataType("jobs")}
          className=" col-span-1 border px-4 gap-2 flex items-center cursor-pointer  p-2 "
        >
          <div className=" bg-gray-100 p-6 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-4xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-violet-700 to-sky-700">
              {count.jobs}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Jobs</h1>
          </div>
        </div>
      </div>
      <hr className="my-2" />
      <div className=" grid sm:grid-cols-2 gap-2 *:rounded-lg ">
        <div className=" col-span-full gap-2 flex items-center  p-2 ">
          <div className=" bg-gray-100 p-4 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-8 text-sky-800"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
              />
            </svg>
          </div>
          <div className=" flex flex-col">
            <h1 className="text-3xl block font-bold bg-clip-text bg-gradient-to-tr text-transparent from-green-400 to-sky-800">
              {/*    {profile && [profile.currency.code, number].join(" ")} */}{" "}
              USD 0
            </h1>

            <div className="inline-flex text-xs  font-semibold text-green-600">
              <div className=" size-4  rounded-full bg-gray-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                  />
                </svg>
              </div>

              <span>
                {" "}
                0 USD{" "}
                {/*     {profile && [profile.currency.code, upcoming].join(" ")} */}
              </span>
            </div>
            <h1 className=" text-[0.65rem] font-normal text-gray-400">
              Balance
            </h1>
          </div>
        </div>
      </div>
      <div className="lg:h-[50%] sm:h-[50%] h-28 relative ">
        <Chart dataType={dataType} year={year} />
      </div>

      <hr />
      <div className=" grid grid-cols-2 w-full">
        <div className=" col-span-1 gap-2 flex items-center w-full  p-2 ">
          <ListBoxChart
            type="type"
            setDataType={setDataType}
            options={optionsViewType}
          />
        </div>
        <div className=" col-span-1 gap-2 flex items-center justify-end w-full  p-2 ">
          <ListBoxChart
            type="year"
            setYear={setYear}
            options={optionsViewTime}
          />
        </div>
      </div>
    </div>
  );
}

function ToolsBar() {
  const { countAllDocuments: countI } = useFirestore("inquires");
  const [countInquires, setCountInquires] = useState(0);

  const fetchCount = async () => {
    const result = await countI();
    setCountInquires(result);
  };

  useEffect(() => {
    fetchCount();

    const unsubscribe = onSnapshot(query(collection(db, "inquires")), () => {
      fetchCount();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className=" w-full my-4 font-semibold  gap-3 grid   md:grid-cols-3">
      <Link
        to="account"
        className=" p-8 bg-white shadow  flex gap-4 border-dashed  justify-center items-center text-sky-800 hover:underline  underline-offset-2 rounded-lg border"
      >
        <BuildingOfficeIcon className="size-6 text-gray-400" />
        <span>Account settings</span>
      </Link>
      <Link
        to={"inquires"}
        className=" p-8 bg-white shadow  flex gap-4 border-dashed  justify-center items-center text-sky-800 hover:underline  underline-offset-2 rounded-lg border"
      >
        <EnvelopeIcon className=" text-gray-400 size-6" />
        <span>Inquires</span>
        <span className="bg-sky-50 text-sky-800 px-6 rounded-lg text-sm">
          {countInquires}
        </span>
      </Link>
      <Link
        to={"messages"}
        className=" p-8 bg-white shadow  flex gap-4 border-dashed  justify-center items-center text-sky-800 hover:underline  underline-offset-2 rounded-lg border"
      >
        <MessagesIcon />

        <span>Messages</span>
      </Link>
    </div>
  );
}

function Dashboard() {
  const { readAllDocuments, callableFunction } = useFirestore("candidates");
  const [candidates, setCandidates] = useState([]);

  const fetchAllCandidates = async () => {
    const result = await readAllDocuments();
    setCandidates(result);
  };

  useEffect(() => {
    fetchAllCandidates();
  }, []);

  return (
    <div className="  ">
      <div className="xl:grid-cols-12   flex flex-col gap-4 md:grid">
        <div className=" xl:col-span-6">
          <ChartItem />
        </div>
        <div className=" xl:col-span-6">
          <UpcomingShifts />
        </div>
      </div>
      <ToolsBar />
      <Transactions />

      <Users />
    </div>
  );
}

export default Dashboard;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Jobs } from "../services/job";
import { Application } from "../services/application";
import { Candidate } from "../services/candidate";
import socket from "./socket";
import { fetchCandidate } from "./candidate";

const job = new Jobs();
const application = new Application();
const candidate = new Candidate();

export const applyJob = createAsyncThunk(
  "application/applyJob",
  async ({ jobId, note }, { dispatch }) => {
    await job.postApplication(jobId, note);
    dispatch(fetchCandidate());
  }
);

export const fetchOneCandidate = createAsyncThunk(
  "candidateSlice/fetchOneCandidate",
  async ({ id }) => {
    return await candidate.getOneCandidate(id);
  }
);

export const getApplicationCompany = createAsyncThunk(
  "application/getApplication",
  async () => {
    return await application.getApplications();
  }
);

export const getAllApplicationCandidate = createAsyncThunk(
  "application/getAllApplicationCandidate",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { page, limit, filter } = state.ApplicationSlice;
    return await application.getApplicationsCandidateHistorics(
      page,
      limit,
      filter
    );
  }
);

export const updateApplicationCompany = createAsyncThunk(
  "application/updateApplicationComp",
  async ({ id, params = {} }) => {
    await job.updateApplication(id, params);
    const applications = await application.getApplications();
    return applications;
  }
);

export const ApplicationSlice = createSlice({
  name: "ApplicationSlice",
  initialState: {
    hr: {
      hourlyRate: 0,
      currency: null,
      symbol: null,
      name: null,
      jobId: null,
      date: null,
      time: null,
      logo: null,
      userId: null,
    },
    note: null,
    open: false,
    loading: false,
    applications: [],
    candidateLaunch: false,
    candidate: null,
    idCandidate: null,
    applicationLaunch: false,
    id: null,
    userToSend: null,
    page: 1,
    limit: 10,
    filter: "",
    shiftDate: new Date(),
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setHr: (state, action) => {
      state.hr = action.payload;
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setCandidateLaunch: (state, action) => {
      state.candidateLaunch = !state.candidateLaunch;
      state.idCandidate = action.payload;
    },
    setClose: (state) => {
      state.candidateLaunch = false;
    },
    setApplicationLaunch: (state) => {
      state.applicationLaunch = !state.applicationLaunch;
    },
    setUserToSend: (state, action) => {
      state.userToSend = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setShiftDate: (state, action) => {
      state.shiftDate = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(applyJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(applyJob.fulfilled, (state) => {
      state.open = false;
      state.loading = false;
      socket.emit("message", {
        targetUserID: state.hr.userId,
        type: "new-application",
        message: {
          content: "You've received a new application !",
        },
      });
    });
    builder.addCase(getApplicationCompany.fulfilled, (state, action) => {
      state.applications = action.payload;
    });
    builder.addCase(getAllApplicationCandidate.fulfilled, (state, action) => {
      state.applications = action.payload;
    });
    builder.addCase(updateApplicationCompany.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateApplicationCompany.fulfilled, (state, action) => {
      state.applications = action.payload;
      state.loading = false;

      socket.emit("message", {
        targetUserID: state.userToSend,
        type: "new-notification",
        message: {
          data: state.convId,
          content: "You've received a new notification !",
        },
      });
    });
    builder.addCase(fetchOneCandidate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOneCandidate.fulfilled, (state, action) => {
      state.candidate = action.payload;
      state.loading = false;
    });
  },
});

export const {
  setCandidateLaunch,
  setPage,
  setFilter,
  setShiftDate,
  setOpen,
  setId,
} = ApplicationSlice.actions;

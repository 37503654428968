import React, { useEffect, useState } from "react";

import useFirestore from "../../../hook/firestore";
import { auth, db } from "../../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import useProfileUpdate from "../../../hook/company";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { useSelector } from "react-redux";
import Roles from "../../../Enum/UserRoles";

function NotificationsSetting() {
  const { updateNotificationSettings, load, setLoad } = useProfileUpdate();
  const { readDocument } = useFirestore("notification_settings");
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const uid = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID.uid
    : auth.currentUser.uid;

  const [settings, setSettings] = useState({
    jobs: true,
    invitations: true,
    offers: true,
    push: true,
  });

  useEffect(() => {
    if (!settings) {
      updateNotificationSettings({
        jobs: true,
        invitations: true,
        offers: true,
        push: true,
      });
    }
  }, [settings]);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoad(false);
      await readDocument(uid).then((data) => {
        setSettings(data);

        setLoad(true);
      });
    };

    const docRef = doc(db, "notification_settings", uid);

    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        console.log("Current data: ", docSnapshot.data());

        fetchSettings();
      } else {
        console.log("No such document!");
      }
    });

    if (uid) {
      fetchSettings();
    }
  }, [uid]);

  const handleChange = (e) => {
    const { name, checked, value, type } = e.target;

    setSettings((prev) => ({
      ...prev,
      [name]: type === "radio" ? (value === "true" ? true : false) : checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    updateNotificationSettings(settings);
  };

  return (
    <div className=" p-4 flex justify-center">
      <div className=" border rounded-lg">
        <form onSubmit={handleSubmit}>
          <div className="sm:overflow-hidden sm:rounded-md relative">
            {!load && <SpinnerLoadData />}
            <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
              <div
                onClick={() => window.history.back()}
                className=" cursor-pointer w-fit flex gap-4 items-center text-lg font-semibold text-sky-900"
              >
                <span>
                  <svg
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    className="size-5 "
                    color="currentColor"
                  >
                    <g id="_4" data-name="4">
                      <path d="m492 276h-472a20 20 0 0 1 0-40h472a20 20 0 0 1 0 40z" />
                      <path d="m189 445a19.937 19.937 0 0 1 -14.142-5.858l-169-169a20 20 0 0 1 0-28.284l169-169a20 20 0 0 1 28.284 28.284l-154.858 154.858 154.858 154.858a20 20 0 0 1 -14.142 34.142z" />
                    </g>
                  </svg>
                </span>{" "}
                <span> Notification Settings</span>
              </div>

              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  Notify me when :
                </legend>
                <div className="mt-4 space-y-4">
                  <div className="flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="company.user.notification.notifyWhen.jobs"
                        name="jobs"
                        checked={settings?.jobs}
                        onChange={handleChange}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-sky-900 focus:ring-sky-500"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-900"
                      >
                        Jobs
                      </label>
                      <p className="text-gray-500">
                        Get notified when companies post jobs.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="company.user.notification.notifyWhen.invitations"
                          name="invitations"
                          checked={settings?.invitations}
                          onChange={handleChange}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-sky-900 focus:ring-sky-500"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="company.user.notification.notifyWhen.invitations"
                          className="font-medium text-gray-900"
                        >
                          Invitations
                        </label>
                        <p className="text-gray-500">
                          Get notified when a company invite you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="company.user.notification.notifyWhen.offers"
                          name="offers"
                          checked={settings?.offers}
                          onChange={handleChange}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-sky-900 focus:ring-sky-500"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="offers"
                          className="font-medium text-gray-900"
                        >
                          Offers
                        </label>
                        <p className="text-gray-500">
                          Get notified when a company accepts or rejects an
                          application.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>

              <hr />
              <fieldset className="mt-6">
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  Push Notifications
                </legend>
                <p className="text-sm text-gray-500">
                  These are delivered via phone.
                </p>
                <div className="mt-4 space-y-4">
                  <div className="flex items-center">
                    <input
                      id="push-everything"
                      name="push"
                      type="radio"
                      onChange={handleChange}
                      value={true}
                      checked={settings?.push}
                      className="h-4 w-4 border-gray-300 text-sky-900 focus:ring-sky-500"
                    />
                    <label htmlFor="push-everything" className="ml-3">
                      <span className="block text-sm font-medium leading-6 text-gray-900">
                        Everything enabled
                      </span>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="push-nothing"
                      name="push"
                      type="radio"
                      checked={!settings?.push}
                      onChange={handleChange}
                      value={false}
                      className="h-4 w-4 border-gray-300 text-sky-900 focus:ring-sky-500"
                    />
                    <label htmlFor="push-nothing" className="ml-3">
                      <span className="block text-sm font-medium leading-6 text-gray-900">
                        No push notifications
                      </span>
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NotificationsSetting;

import React, { useState, useEffect } from "react";
import SelectList from "./listbox";
import ModalAv from "./modal";
import useProfileUpdate from "../../hook/candidate";
import { classNames } from "../utils/className";

function SimpleCalendar({ availabilities: availability, company = false }) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const { availabilities } = useProfileUpdate();

  const handleClick = (date, checked) => {
    setSelected({
      checked,
      date,
    });
    setOpen(true);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1
    );
    setCurrentDate(nextMonth);
  };

  const goToPreviousMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1
    );
    setCurrentDate(prevMonth);
  };

  const daysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const dayOfWeek = (date) => {
    let day = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    // Ajustement pour que la semaine commence le lundi
    return day === 0 ? 6 : day - 1;
  };

  const generateCalendar = () => {
    const totalDays = daysInMonth(currentDate);
    const startDay = dayOfWeek(currentDate); // Jour de la semaine du 1er du mois
    const days = [];
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    // Ajouter des cases vides pour les jours avant le 1er du mois
    for (let i = 0; i < startDay; i++) {
      days.push(<div key={`empty-${i}`}> </div>);
    }

    // Ajouter les jours du mois
    for (let day = 1; day <= totalDays; day++) {
      const index_date = [
        day,
        currentDate.getMonth(),
        currentDate.getFullYear(),
      ].join("-");

      const isToday =
        day === new Date().getDate() &&
        currentDate.getMonth() === new Date().getMonth() &&
        currentDate.getFullYear() === new Date().getFullYear();

      if (availabilities && availabilities.hasOwnProperty(index_date)) {
        // index_date;
        console.log(index_date === availabilities[index_date]?.date);
      }

      if (!company) {
        days.push(
          <div
            key={day}
            className={classNames(
              "flex-col items-center  p-2 py-4  rounded-lg  hover:bg-gray-100  justify-center cursor-pointer flex",

              isToday && "bg-sky-600 text-white",
              availabilities &&
                availabilities.hasOwnProperty(index_date) &&
                availabilities[index_date].date === index_date &&
                availabilities[index_date].av === "PM" &&
                !isToday &&
                "text-orange-600 bg-orange-100",
              availabilities &&
                availabilities.hasOwnProperty(index_date) &&
                availabilities[index_date].date === index_date &&
                availabilities[index_date].av === "AM" &&
                !isToday &&
                "text-indigo-600 bg-indigo-100",
              availabilities &&
                availabilities.hasOwnProperty(index_date) &&
                availabilities[index_date].date === index_date &&
                availabilities[index_date].av === "OFF" &&
                "text-red-600 bg-red-100"
            )}
            onClick={() =>
              handleClick(
                index_date,
                availabilities &&
                  availabilities.hasOwnProperty(index_date) &&
                  availabilities[index_date].date === index_date &&
                  availabilities[index_date].av === "AM"
                  ? 1
                  : availabilities &&
                    availabilities.hasOwnProperty(index_date) &&
                    availabilities[index_date].date === index_date &&
                    availabilities[index_date].av === "PM"
                  ? 2
                  : availabilities &&
                    availabilities.hasOwnProperty(index_date) &&
                    availabilities[index_date].date === index_date &&
                    availabilities[index_date].av === "FULL"
                  ? 3
                  : availabilities &&
                    availabilities.hasOwnProperty(index_date) &&
                    availabilities[index_date].date === index_date &&
                    availabilities[index_date].av === "OFF"
                  ? 4
                  : 3
              )
            }
          >
            {day}

            <div
              className={classNames(
                !company && " md:block hidden ",
                company && "hidden"
              )}
            >
              <SelectList
                checked={
                  availabilities &&
                  availabilities.hasOwnProperty(index_date) &&
                  availabilities[index_date].date === index_date &&
                  availabilities[index_date].av === "AM"
                    ? 1
                    : availabilities &&
                      availabilities.hasOwnProperty(index_date) &&
                      availabilities[index_date].date === index_date &&
                      availabilities[index_date].av === "PM"
                    ? 2
                    : availabilities &&
                      availabilities.hasOwnProperty(index_date) &&
                      availabilities[index_date].date === index_date &&
                      availabilities[index_date].av === "FULL"
                    ? 3
                    : availabilities &&
                      availabilities.hasOwnProperty(index_date) &&
                      availabilities[index_date].date === index_date &&
                      availabilities[index_date].av === "OFF"
                    ? 4
                    : 3
                }
                date={`${day}-${currentDate.getMonth()}-${currentDate.getFullYear()}`}
              />
            </div>
          </div>
        );
      }
      if (company) {
        days.push(
          <div
            key={day}
            className={classNames(
              "flex-col items-center  p-2 py-4  rounded-lg  hover:bg-gray-100  justify-center cursor-pointer flex",

              isToday && "bg-sky-600 text-white",
              availability &&
                availability.hasOwnProperty(index_date) &&
                availability[index_date].date === index_date &&
                availability[index_date].av === "PM" &&
                !isToday &&
                "text-orange-600 bg-orange-100",
              availability &&
                availability.hasOwnProperty(index_date) &&
                availability[index_date].date === index_date &&
                availability[index_date].av === "AM" &&
                !isToday &&
                "text-indigo-600 bg-indigo-100",
              availability &&
                availability.hasOwnProperty(index_date) &&
                availability[index_date].date === index_date &&
                availability[index_date].av === "OFF" &&
                "text-red-600 bg-red-100"
            )}
            onClick={() =>
              handleClick(
                index_date,
                availability &&
                  availability.hasOwnProperty(index_date) &&
                  availability[index_date].date === index_date &&
                  availability[index_date].av === "AM"
                  ? 1
                  : availability &&
                    availability.hasOwnProperty(index_date) &&
                    availability[index_date].date === index_date &&
                    availability[index_date].av === "PM"
                  ? 2
                  : availability &&
                    availability.hasOwnProperty(index_date) &&
                    availability[index_date].date === index_date &&
                    availability[index_date].av === "FULL"
                  ? 3
                  : availability &&
                    availability.hasOwnProperty(index_date) &&
                    availability[index_date].date === index_date &&
                    availability[index_date].av === "OFF"
                  ? 4
                  : 3
              )
            }
          >
            {day}

            <div
              className={classNames(
                !company && " md:block hidden ",
                company && "hidden"
              )}
            >
              <SelectList
                checked={
                  availabilities &&
                  availabilities.hasOwnProperty(index_date) &&
                  availabilities[index_date].date === index_date &&
                  availabilities[index_date].av === "AM"
                    ? 1
                    : availabilities &&
                      availabilities.hasOwnProperty(index_date) &&
                      availabilities[index_date].date === index_date &&
                      availabilities[index_date].av === "PM"
                    ? 2
                    : availabilities &&
                      availabilities.hasOwnProperty(index_date) &&
                      availabilities[index_date].date === index_date &&
                      availabilities[index_date].av === "FULL"
                    ? 3
                    : availabilities &&
                      availabilities.hasOwnProperty(index_date) &&
                      availabilities[index_date].date === index_date &&
                      availabilities[index_date].av === "OFF"
                    ? 4
                    : 3
                }
                date={`${day}-${currentDate.getMonth()}-${currentDate.getFullYear()}`}
              />
            </div>
          </div>
        );
      }
    }

    return (
      <div
        className={classNames(
          !company &&
            "my-8 grid grid-cols-7 xl:gap-4 gap-2 xl:divide-x divide-y",
          company && "my-8 grid grid-cols-7 xl:gap-4 gap-2 "
        )}
      >
        {daysOfWeek.map((d) => (
          <div
            className={classNames("xl:p-4 text-xs py-10 xl:text-sm")}
            key={d}
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            {d}
          </div>
        ))}

        {days}
      </div>
    );
  };

  return (
    <div className="  p-4  ">
      <div className="  grid h-full  grid-cols-12">
        <button className=" col-span-2" onClick={goToPreviousMonth}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
            />
          </svg>
        </button>
        <div className=" col-span-8 capitalize font-semibold flex justify-center ">
          <span>
            {" "}
            {currentDate.toLocaleDateString("fr-FR", {
              month: "long",
              year: "numeric",
            })}
          </span>
        </div>
        <button
          className=" col-span-2 flex justify-end"
          onClick={goToNextMonth}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        </button>
      </div>
      {generateCalendar()}
      <ModalAv
        open={open}
        date={selected?.date}
        checked={selected?.checked}
        setOpen={setOpen}
      />
    </div>
  );
}

export default SimpleCalendar;

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_URL_PROD
    : process.env.REACT_APP_URL_LOCAL1;

export async function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Rayon de la Terre en kilomètres
  const rad = Math.PI / 180; // Facteur de conversion pour les degrés en radians

  // Convertir les latitudes et longitudes de degrés en radians
  const φ1 = lat1 * rad;
  const φ2 = lat2 * rad;
  const Δφ = (lat2 - lat1) * rad;
  const Δλ = (lon2 - lon1) * rad;

  // Formule de haversine
  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance en kilomètres
  const distance = R * c;

  return distance;
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

export function formatName(name) {
  const parts = name.split(" ");

  if (parts.length === 1 && parts[0].length > 12) {
    return parts[0][0];
  }

  if (parts.length > 1) {
    let formattedName = "";
    for (const part of parts) {
      if (part.length > 8) {
        formattedName += part[0] + " ";
      } else {
        formattedName += part + " ";
      }
    }
    return formattedName.trim().replaceAll("-", " ");
  }

  return name;
}

export function isValidCardNumber(cardNumber) {
  // Retirer tous les espaces et tirets
  cardNumber = cardNumber.replace(/\s+/g, "").replace(/-/g, "");

  // Appliquer l'algorithme de Luhn
  let sum = 0;
  let shouldDouble = false;
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber[i]);
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return sum % 10 === 0;
}

export function getCardType(cardNumber) {
  // Retirer tous les espaces et tirets
  cardNumber = cardNumber.replace(/\s+/g, "").replace(/-/g, "");
  // Utiliser des expressions régulières pour déterminer le type de carte
  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)) {
    return "Visa";
  } else if (/^5[1-5][0-9]{14}$/.test(cardNumber)) {
    return "MasterCard";
  } else if (/^3[47][0-9]{13}$/.test(cardNumber)) {
    return "Amex";
  } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cardNumber)) {
    return "Discover";
  } else if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cardNumber)) {
    return "JCB";
  } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cardNumber)) {
    return "Diners Club";
  } else if (/^50[0-9]{14,19}$/.test(cardNumber)) {
    return "Aura";
  } else if (/^6011[0-9]{12}$/.test(cardNumber)) {
    return "Nexi";
  } else {
    return "Unknown";
  }
}

export function convertTo24HourFormat(timeStr) {
  const [time, period] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}
export const styleMap = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#b4d4e1",
      },
      {
        visibility: "on",
      },
    ],
  },
];

export const prefix = {
  UK: require("./assets/img/flag/united-kingdom_321269.png"),
  KSA: require("./assets/img/flag/saudi-arabia_321258.png"),
  UAE: require("./assets/img/flag/united-arab-emirates_321268.png"),
};

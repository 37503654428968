import { useEffect, useState } from "react";
import ModalUp from "./modal";
import Stars from "../../../components/rating/Stars";
import DaysFilter from "../../../components/simple-calendar/days-upcoming";
import useFirestore from "../../../hook/firestore";
import useProfileUpdate from "../../../hook/company";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import GenericImg from "../../../components/layout/generic-user-img";
import moment from "moment";
import { classNames } from "../../../components/utils/className";
import { Shift as s } from "../../../Enum/shift";

function ItemEmpty() {
  return (
    <div className=" bg-gray-100 rounded-lg p-4">
      <h1 className="text-sm text-sky-800">No more data to load</h1>
    </div>
  );
}

function ItemUpcomingLoading() {
  return (
    <div className=" w-full bg-white  border p-4   cursor-pointer  relative justify-between rounded-lg py-8">
      <div className=" absolute top-0 right-0">
        <h1 className="text-xs px-4 py-1 animate-pulse bg-slate-100 text-transparent">
          {" "}
          text
        </h1>
      </div>
      <div className=" grid grid-cols-2 gap-2">
        <div className=" grid col-span-full gap-2 grid-cols-12">
          <div className=" col-span-2">
            <div className="  size-10  border rounded-full"></div>
          </div>
          <h1 className=" col-span-10 rounded-lg  p-2 animate-pulse bg-slate-100 text-transparent">
            text
          </h1>
        </div>

        <div className=" font-semibold text-xs col-span-full grid grid-cols-2 gap-2">
          <h1 className=" text-center bg-slate-100 rounded-lg text-transparent p-2">
            text
          </h1>
          <h1 className=" animate-pulse bg-slate-100 text-center rounded-lg text-transparent p-2">
            {" "}
            text
          </h1>
          <h1 className=" animate-pulse bg-slate-100 text-center rounded-lg text-transparent p-2">
            text
          </h1>
          <p
            className={classNames(
              "  font-semibold text-center animate-pulse bg-slate-100 whitespace-nowrap px-4 py-2 rounded-lg text-transparent"
            )}
          >
            text
          </p>
          <div className=" flex justify-center col-span-full animate-pulse bg-slate-100 text-transparent  rounded-lg  p-2">
            text
          </div>
        </div>
      </div>
    </div>
  );
}

function ItemUpcoming({ setOpen, setSelected, item }) {
  const handleClick = () => {
    setOpen(true);
    setSelected({ startAt: item.createdAt, endAt: item.job.startAt.unix });
  };

  return (
    <div
      onClick={handleClick}
      className=" w-full bg-white  border p-4   cursor-pointer  relative justify-between rounded-lg py-8"
    >
      <div className=" absolute top-0 right-0">
        <h1 className="text-xs px-4 py-2">
          {" "}
          {moment.unix(item.createdAt).local().fromNow()}
        </h1>
      </div>
      <div className=" grid grid-cols-2 gap-2">
        <div className=" grid col-span-full gap-2 grid-cols-12">
          <div className=" col-span-2">
            <div className="  size-10  border rounded-full">
              {!item.candidate.avatarUrl && <GenericImg />}
              {item.candidate.avatarUrl && (
                <img
                  src={item.candidate.avatarUrl}
                  className="size-full rounded-full object-cover"
                  alt=""
                />
              )}
            </div>
          </div>
          <h1 className=" col-span-10 rounded-lg  p-2">
            {item.candidate.name}
          </h1>
        </div>

        <div className=" font-semibold text-xs col-span-full grid grid-cols-2 gap-2">
          <h1 className=" text-center bg-slate-100 rounded-lg  p-2">
            {item.job.name}
          </h1>
          <h1 className=" bg-slate-100 text-center rounded-lg  p-2">
            {" "}
            {moment.unix(item.job.startAt.unix).local().fromNow()}
          </h1>
          <h1
            className={classNames(
              " bg-slate-100 text-center rounded-lg  p-2",
              ![
                s.CONFIRMED,
                s.PAID,
                s.CANCEL,
                s.NO_SHOW,
                s.PENDING_PAYMENT,
                s.PENDING_RATING,
              ].includes(item.status) && " text-xs col-span-full"
            )}
          >
            {item.job.hourlyRate}
          </h1>
          <p
            className={classNames(
              "  font-semibold text-center  whitespace-nowrap px-4 py-2 rounded-lg",
              item.status === s.CONFIRMED && "bg-green-50 text-green-800 ",
              item.status === s.PAID && " bg-green-800 text-green-50",
              item.status === s.CANCEL && " bg-red-800 text-red-50",
              item.status === s.NO_SHOW && " bg-orange-800 text-orange-50",
              item.status === s.PENDING_PAYMENT &&
                " bg-indigo-800 text-indigo-50",
              item.status === s.PENDING_RATING &&
                " bg-yellow-800 text-yellow-50",
              ![
                s.CONFIRMED,
                s.PAID,
                s.CANCEL,
                s.NO_SHOW,
                s.PENDING_PAYMENT,
                s.PENDING_RATING,
              ].includes(item.status) && "bg-slate-100  col-span-full"
            )}
          >
            {item.status}
          </p>
          <div className=" flex justify-center col-span-full  rounded-lg  p-2">
            <Stars number={item.stars.company || 0} />
          </div>
        </div>
      </div>
    </div>
  );
}

const FillingGauge = ({ duration }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, duration * 10);

    return () => clearInterval(interval);
  }, [duration]);

  return (
    <div className="gauge-container">
      <div className="gauge">
        <div className="gauge-fill" style={{ width: `${percentage}%` }}></div>
      </div>
      <div className="percentage">{percentage}%</div>
    </div>
  );
};

export const CircularGauge = ({ startAt, endTime }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeRemaining]);

  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);
    if (now >= startAt && now <= endTime) {
      setTimeRemaining(endTime - now);
    } else if (now < startAt) {
      setTimeRemaining(null); // Ne pas commencer si la date actuelle est avant la date de début
    } else {
      setTimeRemaining(0); // Compte à rebours terminé
    }
  }, [startAt, endTime]);

  const radius = 50;
  const circumference = 2 * Math.PI * radius;

  const totalTime = endTime - startAt;
  const timeElapsed = totalTime - timeRemaining;
  const offset = circumference - (timeElapsed / totalTime) * circumference;

  const percentage = Math.min((timeElapsed / totalTime) * 100, 100);

  const formatTime = (time) => {
    if (time <= 0) return "00:00:00"; // Si le temps est écoulé
    const days = String(Math.floor(time / (24 * 60 * 60))).padStart(2, "0");
    const hours = String(
      Math.floor((time % (24 * 60 * 60)) / (60 * 60))
    ).padStart(2, "0");
    const minutes = String(Math.floor((time % (60 * 60)) / 60)).padStart(
      2,
      "0"
    );
    const seconds = String(time % 60).padStart(2, "0");

    return (
      <div className="  text-sm grid grid-cols-4 gap-4 font-semibold text-sky-800 ">
        <span className="p-2 bg-gray-100 rounded-lg"> {days} D</span>
        <span className="p-2 bg-gray-100 rounded-lg"> {hours} H</span>
        <span className="p-2 bg-gray-100 rounded-lg"> {minutes} M</span>
        <span className="p-2 bg-gray-100 rounded-lg"> {seconds} S</span>
      </div>
    );
  };

  return (
    <div className="flex justify-center flex-col items-center">
      <div className="relative flex justify-center flex-col items-center p-4 rounded-lg">
        <div className="my-5 flex flex-col justify-center items-center">
          <svg className="circular-gauge " width="120" height="120">
            <circle className="background-circle" cx="60" cy="60" r={radius} />
            <circle
              className="foreground-circle"
              cx="60"
              cy="60"
              r={radius}
              style={{ strokeDashoffset: offset }}
            />
          </svg>
          <div className="time-text">{formatTime(timeRemaining)}</div>
          <div className="text-center font-bold mt-2">
            {Math.round(percentage)}% completed
          </div>
          <div className="bg-orange-600 font-semibold px-4 py-2 rounded-lg text-white mt-4">
            <h1>Push remind 10 min before</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Shift() {
  const { readDocumentsWithQuery, callableFunction, loading } =
    useFirestore("shifts");
  const { profile, load } = useProfileUpdate();
  const [filterBy, setFilterBy] = useState("*");
  const [shifts, setShits] = useState([]);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(null);
  const [selected, setSelected] = useState(null);
  const [date, setDate] = useState(null);

  const fetchShifts = async () => {
    const result = await callableFunction("filterShifts", {
      name,
      status: filterBy,
      company: profile.id,
      startDate: date || "",
    });
    const { data } = result;

    setShits(data);
    if (data && data.length > 0) {
      setSelected({
        startAt: data[0].createdAt,
        endAt: data[0].job.startAt.unix,
      });
    }
  };

  useEffect(() => {
    if (profile) {
      fetchShifts();

      const unsubscribe = onSnapshot(
        query(
          collection(db, "shifts"),
          where("job.company", "==", profile.id) // Condition: where name is "test"
        ),
        () => {
          fetchShifts();
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile, name, filterBy, date]);

  return (
    <div className="sm:p-4 p-2">
      <ModalUp
        startAt={selected?.startAt}
        endTime={selected?.endAt}
        open={open}
        setOpen={setOpen}
      />
      <h1 className="font-semibold text-lg text-sky-900">Upcoming Shift</h1>
      <hr className="my-3" />
      <DaysFilter date={date} setDate={setDate} />
      <div className=" grid xl:grid-cols-12 mt-6 divide-x">
        <div className=" xl:col-span-4 flex flex-col gap-4  max-h-[calc(100vh-14rem)] overflow-y-scroll md:p-4">
          {shifts &&
            shifts.map((item) => (
              <ItemUpcoming
                item={item}
                setSelected={setSelected}
                setOpen={setOpen}
              />
            ))}

          {!loading && shifts && shifts.length === 0 && <ItemEmpty />}

          {(loading || !load) &&
            [
              <ItemUpcomingLoading />,
              <ItemUpcomingLoading />,
              <ItemUpcomingLoading />,
            ].map((el) => el)}
        </div>

        <div className="  xl:flex hidden xl:col-span-8 p-4 justify-center items-center">
          {selected && shifts.length > 0 && (
            <CircularGauge
              startAt={selected.startAt}
              endTime={selected.endAt}
            />
          )}
        </div>
      </div>
    </div>
  );
}

import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { updateStarRatingCandidate } from "../../redux/shift";
import useFirestore from "../../hook/firestore";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import useProfileUpdate from "../../hook/company";
import moment from "moment";
import { db } from "../../firebase";
import GenericImg from "../layout/generic-user-img";
import Notification from "../../Enum/Notification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Item({ item }) {
  const { readDocument } = useFirestore("jobs");
  const { deleteDocument, updateDocument: updateRead } =
    useFirestore("notifications");

  const [job, setJob] = useState(null);

  useEffect(() => {
    if (item.shift) {
      (async () => {
        await readDocument(item.job.id).then((data) => {
          setJob(data);
        });
      })();
    }
  }, [item]);

  const handleUpdateRead = async () => {
    await updateRead(item.id, {
      read: true,
    });
  };

  return (
    <li
      onClick={handleUpdateRead}
      className={classNames(
        "w-full max-h-[14rem] p-4 transition-all ease-in-out relative cursor-pointer",
        !item.read && "bg-gray-100"
      )}
    >
      <span className="absolute right-0 top-0 text-xs px-3 font-semibold text-gray-600 py-2">
        {moment.unix(item.creatAt).local().fromNow()}
      </span>
      <h1 className="text-sm font-semibold text-sky-900 py-2">{item.title}</h1>
      <div className="grid grid-cols-12 gap-8">
        <div className=" col-span-1">
          <div className=" size-8 border rounded-full">
            {item.candidate?.avatarUrl && (
              <img
                src={item.candidate?.avatarUrl}
                className={"rounded-full object-cover size-full"}
                alt="candidate"
              />
            )}

            {!item.candidate?.avatarUrl && <GenericImg />}
          </div>
        </div>
        <div className=" col-span-11 flex items-center">
          <p className="text-sm font-semibold">{item.candidate?.name}</p>
        </div>
      </div>
      <a className=" text-xs hover:underline  cursor-pointer font-semibold text-sky-900">
        {item.job.name}
      </a>

      {item.shift && job && (
        <div className=" grid grid-cols-2 gap-2 mt-4 text-xs font-semibold">
          <div className=" bg-sky-200  text-sky-800 p-2 text-center rounded-lg">
            {[job.startAt?.time, job.endAt?.time].join("-")}
          </div>
          <div className=" bg-green-200 text-green-800 p-2 text-center rounded-lg">
            {moment.unix(job.startAt?.unix).local().fromNow()}
          </div>
          <div className=" bg-orange-200 text-orange-800 p-2 col-span-full text-center rounded-lg">
            {job.totalHour.toFixed(2)} hrs
          </div>
        </div>
      )}
    </li>
  );
}

export default function NotificationsCompany() {
  const dispatch = useDispatch();
  const { readDocumentsWithQuery } = useFirestore("notifications");
  const [notifications, setNotifications] = useState([]);
  const { notifOpen } = useSelector((state) => state.conversationSlice);
  const { profile } = useProfileUpdate();

  const [tab, setTab] = useState(0);
  const [stars, setStars] = useState(1);

  const tabs = [
    { name: "All", href: 0, current: tab === 0 },
    { name: "Applications", href: 1, current: tab === 1 },
    { name: "Reconfirmed", href: 2, current: tab === 2 },
  ];

  const confirmRating = (id) => {
    dispatch(
      updateStarRatingCandidate({
        id: id,
        params: {
          company: stars,
        },
      })
    );
  };

  const fetchNotification = async () => {
    const query = [
      where("to", "==", profile.id),
      where("job.startAt.unix", "<=", moment.utc().unix()),
      orderBy("creatAt", "desc"),
    ];

    if (tab !== 0) {
      switch (tab) {
        case 1:
          query.push(
            where(
              "title",
              "==",
              Notification.TYPE(Notification.NEW_APPLICATION)
            )
          );
          break;
        case 2:
          query.push(
            where("title", "==", Notification.TYPE(Notification.RECONFIRMED))
          );
          break;

        default:
          break;
      }
    }

    const result = await readDocumentsWithQuery(query);
    setNotifications(result);
  };

  useEffect(() => {
    if (profile) {
      fetchNotification();

      const unsubscribe = onSnapshot(
        query(
          collection(db, "notifications"),
          where("to", "==", profile.id) // Condition: where name is "test"
        ),
        (docSnapshot) => {
          if (!docSnapshot.empty) {
            fetchNotification();
          } else {
            console.log("No such document!");
          }
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile, notifOpen, tab]);

  return (
    <Transition.Root show={notifOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() =>
          dispatch({
            type: "conversation/setNotifOpen",
          })
        }
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed top-12 bottom-0 right-0 flex max-w-full pl-0 md:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Notifications
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-sky-500"
                            onClick={() =>
                              dispatch({
                                type: "conversation/setNotifOpen",
                              })
                            }
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav className="-mb-px flex space-x-6">
                          {tabs.map((tab) => (
                            <a
                              type="button"
                              key={tab.name}
                              onClick={() => setTab(tab.href)}
                              className={classNames(
                                tab.current
                                  ? "border-sky-400 text-sky-400"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer"
                              )}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <ul
                      role="list"
                      className="flex-1 divide-y divide-gray-200 overflow-y-auto pb-16"
                    >
                      {notifications && notifications.length === 0 && (
                        <p className="px-2 mt-4 text-center animate-pulse font-semibold text-sky-800 text-sm">
                          In this section, you'll find all pending notifications
                          that require a response. Please note, notifications
                          related to jobs that are no longer available will be
                          automatically removed
                        </p>
                      )}

                      {notifications &&
                        notifications.map((item) => <Item item={item} />)}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React, { useEffect, useState } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectComponentsProvider,
  ConnectPayouts,
} from "@stripe/react-connect-js";
import { Container } from "../../../components/utils/Container";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import useFirestore from "../../../hook/firestore";
import useProfileUpdate from "../../../hook/company";

export function StripeDashboard() {
  const { profile } = useProfileUpdate();
  const { callableFunction, loading } = useFirestore("shifts");
  const [stripeConnectInstance, setStripeConnectInstance] = useState();

  useEffect(() => {
    if (profile) {
      setStripeConnectInstance(() => {
        const fetchClientSecret = async () => {
          // Fetch the AccountSession client secret

          const response = await callableFunction("openPayout", {
            stripeAccount: profile.stripeAccount,
          });
          const { client_secret: clientSecret } = response.account;

          return clientSecret;
        };
        return loadConnectAndInitialize({
          // This is a placeholder - it should be replaced with your publishable API key.
          // Sign in to see your own test API key embedded in code samples.
          // Don’t submit any personally identifiable information in requests made with this key.
          publishableKey:
            "pk_test_51Ls2bXI7eOuKUJKPnNzFkYGPgK0NpWPCJm9SyEzK6y50yy7V49cuNl7rrJd4e8vhV4pB4CWlhLs2EecBCYzwSotC00j7FXAV20",
          fetchClientSecret: fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#3AA9FD",
              colorBackground: "#FFFFFF",
              spacingUnit: "10px",
            },
          },
        });
      });
    }
  }, [profile]);

  return (
    <Container className="py-4 ">
      <div
        onClick={() => window.history.back()}
        className="flex w-fit justify-start gap-4 text-sky-900  items-center  cursor-pointer  "
      >
        <span>
          <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            className="size-5 "
            color="currentColor"
          >
            <g id="_4" data-name="4">
              <path d="m492 276h-472a20 20 0 0 1 0-40h472a20 20 0 0 1 0 40z" />
              <path d="m189 445a19.937 19.937 0 0 1 -14.142-5.858l-169-169a20 20 0 0 1 0-28.284l169-169a20 20 0 0 1 28.284 28.284l-154.858 154.858 154.858 154.858a20 20 0 0 1 -14.142 34.142z" />
            </g>
          </svg>
        </span>
        <span>Payouts</span>
      </div>
      <hr className="my-5" />

      {
        <div className=" h-[calc(100vh-18rem)] relative">
          {loading && <SpinnerLoadData />}

          {stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectPayouts />
            </ConnectComponentsProvider>
          )}
        </div>
      }
    </Container>
  );
}

import { CircleUserRound } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFirestore from "../../../hook/firestore";
import { DataF } from "@react-google-maps/api";
import Roles from "../../../Enum/UserRoles";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";

function Details() {
  const { readDocument: readCandidate, loading: loadCandidate } =
    useFirestore("candidates");
  const { readDocument: readCompanies, loading: loadCompany } =
    useFirestore("companies");
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState({});

  const fetchCandidate = async () => {
    const id = searchParams.get("id");
    const result = await readCandidate(id);
    setUser(result);
  };
  const fetchCompany = async () => {
    const id = searchParams.get("id");
    const result = await readCompanies(id);
    setUser(result);
  };

  useEffect(() => {
    if (searchParams.get("role") === Roles.candidate) {
      fetchCandidate();
    }

    if (searchParams.get("role") === Roles.company) {
      fetchCompany();
    }
  }, [searchParams.get("id"), searchParams.get("role")]);

  return (
    <div className=" w-full border rounded-lg relative bg-white p-4">
      <h1>Details</h1>

      <hr className=" my-3" />

      {(loadCandidate || loadCompany) && <SpinnerLoadData />}

      {user && (
        <div className="  grid gap-4">
          <div className=" grid grid-cols-12 gap-4">
            <div className=" col-span-4">
              <div className=" size-[12rem]  flex justify-center items-center s border rounded-lg">
                {[Roles.candidate].includes(searchParams.get("role")) && (
                  <img
                    src={user.avatarUrl}
                    className=" size-full object-cover"
                    alt="cd"
                  />
                )}
                {[Roles.company].includes(searchParams.get("role")) && (
                  <img
                    src={user.logoUrl}
                    className=" size-full object-cover"
                    alt="cp"
                  />
                )}

                {
                  ![Roles.company, Roles.candidate].includes(
                    searchParams.get("role") && (
                      <CircleUserRound className=" size-1/2  text-gray-400" />
                    )
                  )
                }
              </div>
            </div>

            <div className=" col-span-8  grid gap-4">
              <div className="w-full">
                <input
                  className="  w-full p-4 text-sm   border-none rounded-lg bg-gray-100 "
                  type="text"
                  value={user.name}
                  placeholder="Name"
                />
              </div>
              <div className="w-full">
                <input
                  className="  w-full p-4 text-sm  border-none rounded-lg bg-gray-100 "
                  type="text"
                  value={user.role}
                  placeholder="Role"
                />
              </div>
              <div className="w-full">
                <input
                  className="  w-full p-4 text-sm  border-none rounded-lg bg-gray-100 "
                  type="text"
                  value={user.title || "Not available title"}
                  placeholder="Title"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <input
              className="  w-full p-4 text-sm  border-none rounded-lg bg-gray-100 "
              type="text"
              value={user.currency?.code || user.currency}
              placeholder="currency"
            />
          </div>
          <div className="w-full">
            <input
              className="  w-full p-4 text-sm  border-none rounded-lg bg-gray-100 "
              type="text"
              value={user.hourlyRate || "Not available hourly rate"}
              placeholder="hourly rate"
            />
          </div>
          <div className="w-full">
            <input
              className="  w-full p-4 text-sm  border-none rounded-lg bg-gray-100 "
              type="text"
              value={user.radius || "Not available radius"}
              placeholder="radius"
            />
          </div>
          <div className="w-full relative ">
            <input
              className="  w-full p-4 text-sm  border-none rounded-lg bg-gray-100 "
              type="text"
              value={user.email}
              placeholder="email"
            />
          </div>
          <div className="w-full">
            <input
              className="  w-full p-4 text-sm  border-none rounded-lg bg-gray-100 "
              type="text"
              value={user.phone || "Not available phone"}
              placeholder="phone"
            />
          </div>
          <div className="w-full">
            <input
              className="  w-full p-4 text-sm border-none rounded-lg bg-gray-100 "
              type="text"
              value={user.country}
              placeholder="country"
            />
          </div>
          <div className="w-full">
            <input
              className="  w-full p-4 text-sm border-none rounded-lg bg-gray-100 "
              type="text"
              value={
                [
                  user.location?.street,
                  user.location?.zip,
                  user.location?.city,
                  user.location?.state,
                ].join(" , ") || "Not available"
              }
              placeholder="address"
            />
          </div>
          <div className="w-full">
            <input
              className="  w-full  p-4 text-sm border-none rounded-lg bg-gray-100 "
              type="text"
              value={user.stripeAccount || "Not available"}
              placeholder="stripe account"
            />
          </div>
          <div className="w-full">
            <input
              className="  w-full p-4 text-sm border-none rounded-lg bg-gray-100 "
              type="text"
              value={user.stripeCustomer || "Not available"}
              placeholder="stripe customer"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Details;

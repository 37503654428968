import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { data } from "autoprefixer";
import { classNames } from "../utils/className";

function DaysFilter({ setDate, date }) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const scrollContainerRef = useRef(null);
  const todayRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current && todayRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const todayElement = todayRef.current;

      // Calcul de la position de l'élément todayRef
      const todayOffsetLeft = todayElement.offsetLeft;
      const todayWidth = todayElement.clientWidth;
      const containerWidth = scrollContainer.clientWidth;

      // Calcul de la nouvelle position de défilement pour centrer todayRef
      const scrollPosition = todayOffsetLeft - containerWidth + todayWidth;

      // Défilement jusqu'à la position calculée
      scrollContainer.scrollTo({
        left: scrollPosition,
        behavior: "smooth", // Vous pouvez retirer 'smooth' si vous préférez un défilement instantané
      });
    }
  }, [scrollContainerRef, todayRef]);

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1
    );
    setCurrentDate(nextMonth);
  };

  const goToPreviousMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1
    );
    setCurrentDate(prevMonth);
  };

  const daysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const dayOfWeek = (date) => {
    let day = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return day === 0 ? 6 : day - 1;
  };

  const generateCalendar = () => {
    const totalDays = daysInMonth(currentDate);
    const startDay = dayOfWeek(currentDate);
    const days = [];

    // Ajouter les en-têtes de jour de la semaine pour un affichage horizontal

    // Remplissage pour les jours vides au début
    for (let i = 0; i < startDay; i++) {
      days.push(
        <div key={`empty-${i}`} style={{ minWidth: 30, padding: 10 }}>
          {" "}
        </div>
      );
    }

    // Ajouter les jours du mois
    for (let day = 1; day <= totalDays; day++) {
      const isToday =
        day === new Date().getDate() &&
        currentDate.getMonth() === new Date().getMonth() &&
        currentDate.getFullYear() === new Date().getFullYear();

      const isSelected =
        date === moment(currentDate.setDate(day)).format("YYYY-MM-DD");

      days.push(
        <div
          onClick={() => {
            if (!isSelected) {
              setDate(moment(currentDate.setDate(day)).format("YYYY-MM-DD"));
            } else if (isSelected) {
              setDate(null);
            }
          }}
          className={classNames(
            "px-4 py-2 justify-center flex cursor-pointer ",
            isToday && "bg-sky-800 text-gray-50 ",
            "rounded-lg gap-4 w-20 shrink-0",
            !isSelected && "bg-gray-100 ",
            isSelected && !isToday && " bg-fuchsia-800 text-gray-50",
            isSelected && isToday && " border border-fuchsia-800 "
          )}
          key={day}
          ref={isToday ? todayRef : null}
        >
          <span>{day}</span>
        </div>
      );
    }

    return (
      <div
        ref={scrollContainerRef}
        className=" flex gap-4 pb-2 items-center overflow-x-auto "
      >
        {days}
      </div>
    );
  };

  return (
    <div>
      <div
        className=" text-sm  "
        onClick={(e) => {
          if (e.currentTarget.nextElementSibling.classList.contains("hidden")) {
            e.currentTarget.nextElementSibling.classList.remove("hidden");

            e.currentTarget.nextElementSibling.classList.add("grid");
          } else {
            e.currentTarget.nextElementSibling.classList.add("hidden");

            e.currentTarget.nextElementSibling.classList.remove("grid");
          }
        }}
      >
        <div className=" w-full flex xl:hidden justify-between items-center bg-gray-100 p-4 rounded-lg ">
          <span className=" font-semibold">Filter</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      <div className=" hidden xl:block p-2">
        {/*       */}

        <div className=" grid md:grid-cols-12 items-center gap-4 text-sm font-semibold text-sky-900">
          <div className=" md:col-span-2  p-2 rounded-lg flex bg-gray-100 justify-center items-center">
            <h2>
              {currentDate.toLocaleDateString("fr-FR", {
                month: "long",
                year: "numeric",
              })}
            </h2>
          </div>

          <div className=" md:col-span-8 col-span-full  overflow-hidden  flex gap-4 ">
            {generateCalendar()}
          </div>
          <div className=" grid grid-cols-2  gap-4 md:col-span-2 ">
            <button
              className=" bg-gray-100 p-2 flex justify-center items-center rounded-lg "
              onClick={goToPreviousMonth}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="md:size-6 size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <button
              className=" bg-gray-100 p-2 flex justify-center items-center rounded-lg "
              onClick={goToNextMonth}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 md:size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DaysFilter;

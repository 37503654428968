"use client";

import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

import { CircularGauge } from ".";

export default function ModalUp({ open, setOpen, endTime, startAt }) {
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      className="relative z-[1000000] xl:hidden"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
        <div className="flex min-h-full items-end justify-center p-4 text-center xl:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden sm:mb-0 mb-20 w-full rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full xl:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <CircularGauge endTime={endTime} startAt={startAt} />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import JobCard from "../components/Job/Job-card";
import useJob from "../hook/job";
import { useDispatch, useSelector } from "react-redux";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";
import AutoSuggest from "../components/input/auto-suggest";
import useFirestore from "../hook/firestore";
import moment from "moment";
import useGeolocation from "../hook/geolocation";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";
import { UsersIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { haversineDistance } from "../utils";
import ItemEmpty from "../components/utils/empty";
import Stars from "../components/rating/Stars";

function JobItemLoading({ item }) {
  return (
    <div className=" p-4 py-6 shrink-0  bg-white  shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-xs text-end text-transparent">text</h1>
      </div>

      <div className=" space-y-1">
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100 text-base font-semibold">
          text
        </h1>
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100 text-xs font-semibold">
          {" "}
          text
        </h1>
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100  text-end font-semibold">
          {" "}
          text
        </h1>
      </div>

      <div className=" flex justify-end items-center text-transparent text-green-600  ">
        <div className=" bg-green-50 flex rounded-lg px-4 gap-2 py-1">
          <UsersIcon className=" size-4" />
          <h1 className="text-end text-sm font-semibold   ">1/3</h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-2  *:rounded-lg ">
        <div className="  text-transparent bg-slate-100 animate-pulse p-2  col-span-full text-xs font-semibold text-center">
          text
        </div>
        <div className=" text-transparent bg-slate-100 animate-pulse  p-2 col-span-full  text-xs font-semibold text-center">
          text
        </div>

        <div className=" text-transparent bg-slate-100 animate-pulse   text-xs p-2 font-semibold text-center ">
          text
        </div>
        <div className=" text-transparent bg-slate-100 animate-pulse    text-xs p-2 font-semibold text-center">
          text
        </div>

        <div className="  text-transparent bg-gray-100 animate-pulse border text-sm p-2 col-span-full">
          text
        </div>
        <div className="  p-2 grid grid-cols-2 text-sm gap-4  col-span-full">
          <button className="  text-transparent  text-center animate-pulse bg-white p-2 font-semibold  rounded-lg">
            More details
          </button>
          <button className="  text-transparent text-center bg-sky-600 hover:bg-sky-800 animate-pulse p-2 font-semibold rounded-lg">
            Quick Apply
          </button>
        </div>
      </div>
    </div>
  );
}
function JobItem({ item }) {
  const { readDocument } = useFirestore("companies");

  const [wasApplied, setWasApplied] = useState();
  const [status, setStatus] = useState();
  const [company, setCompany] = useState(false);
  const dispatch = useDispatch();
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const navigate = useNavigate();
  const [distance, setDistance] = useState(0);

  const handleDetail = (id) => {
    navigate(`/jobs?id=${id}`);
  };

  const fetchCompany = async () => {
    const result = await readDocument(item.company.id);
    setCompany(result);
  };

  const CalculDistance = async () => {
    const result = await haversineDistance(
      latitude,
      longitude,
      item.location?.latitude,
      item.location?.longitude
    );
    if (result) {
      setDistance(result);
    }
  };

  useEffect(() => {
    fetchCompany();
    CalculDistance();
  }, [item]);

  return (
    <div className=" p-4 py-6 shrink-0 sm:w-96  bg-white border shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-xs text-end">
          {moment.unix(item.creatAt).local().fromNow()}
        </h1>
      </div>

      <div className=" space-y-1">
        <h1 className="text-base font-semibold">{item.jobName}</h1>
        <h1 className="text-xs font-semibold">{item.company.name}</h1>
        <h1 className=" text-end font-semibold">
          {company && <Stars number={company.averageStars || 0} />}
        </h1>
      </div>

      <div className=" flex justify-end items-center text-green-600  ">
        <div className=" bg-green-50 flex rounded-lg px-4 gap-2 py-1">
          <UsersIcon className=" size-4" />
          <h1 className="text-end text-sm font-semibold   ">
            {[item.applicationCount || 0, item.nbCandidate].join("/")}
          </h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-2  *:rounded-lg ">
        <div className=" p-2 border col-span-full text-xs font-semibold text-center">
          {[item.startAt.time, item.endAt.time].join("-")}
        </div>
        <div className=" p-2 col-span-full border text-xs font-semibold text-center">
          {item.startDate}
        </div>

        <div className=" border text-xs p-2 font-semibold text-center ">
          {distance.toFixed(2)} Km Away
        </div>
        <div className="  border text-xs p-2 font-semibold text-center">
          {[item.currency, item.hourlyRate].join(" ")}/hr
        </div>

        <div className="  border text-sm p-2 col-span-full">
          {item.description}
        </div>
        <div
          onClick={() => handleDetail(item.id)}
          className="  p-2 grid grid-cols-2 text-sm gap-4  col-span-full"
        >
          <button className=" border text-center bg-white p-2 font-semibold  rounded-lg">
            More details
          </button>

          <button
            disabled={wasApplied}
            className=" disabled:bg-gray-100 disabled:text-sky-600 text-center bg-sky-600 hover:bg-sky-800 p-2 font-semibold text-white rounded-lg"
          >
            {wasApplied ? status : " Quick Apply"}
          </button>
        </div>
      </div>
    </div>
  );
}

function JobSection({ id }) {
  useGeolocation();
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const [jobs, setJobs] = useState([]);
  const [selected, setSelected] = useState();
  const { readDocumentsWithQuery, loading, callableFunction } =
    useFirestore("jobs");

  const handleFilter = async () => {
    try {
      const now = moment.utc().unix();
      const response = await callableFunction("filterJobs", {
        radius: "*",
        hourlyRate: "*",
        latitude: latitude || 0,
        longitude: longitude || 0,
        filterBy: "near me",
        unix: 0,
        name: null,
      });

      const { data } = response;

      if (data && data.length > 0) {
        const firstJobId = data[0].id;
        setSelected(firstJobId);
      } else {
        setSelected(null);
      }

      setJobs(data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    handleFilter();

    const unsubscribe = onSnapshot(query(collection(db, "jobs")), () => {
      handleFilter();
    });

    return () => {
      unsubscribe();
    };
  }, [latitude, longitude]);

  return (
    <FadeInStagger>
      <div id={id} class="relative overflow-hidden mt-2">
        <FadeIn class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-24">
          <div class="text-center">
            <h1 class="text-2xl sm:text-4xl font-bold text-gray-800 dark:text-gray-200">
              Find My First Temp Job
            </h1>

            <p class="mt-3 text-sky-600 dark:text-gray-400">
              Book your first exeperience as a Temper today !
            </p>

            <div class="mt-7 sm:mt-12 mx-auto max-w-3xl relative">
              <div className=" border py-6 px-2 rounded-lg relative">
                <button className=" absolute bg-sky-600 text-white z-50  px-6 py-2 rounded-lg text-sm  right-3 top-1/2 -translate-y-1/2">
                  Find my new position
                </button>
                <AutoSuggest selected={selected} setSelected={setSelected} />
              </div>

              <div class="hidden md:block absolute top-0 right-0 -translate-y-12 translate-x-20">
                <svg
                  class="w-16 h-auto text-orange-500"
                  width="121"
                  height="135"
                  viewBox="0 0 121 135"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                    stroke="currentColor"
                    stroke-width="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                    stroke="currentColor"
                    stroke-width="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                    stroke="currentColor"
                    stroke-width="10"
                    stroke-linecap="round"
                  />
                </svg>
              </div>

              <div class="hidden md:block absolute bottom-0 left-0 translate-y-10 -translate-x-32">
                <svg
                  class="w-40 h-auto text-cyan-500"
                  width="347"
                  height="188"
                  viewBox="0 0 347 188"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426"
                    stroke="currentColor"
                    stroke-width="7"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>

            <div class="mt-10 sm:mt-20">
              <a
                class="m-1 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                href="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                  />
                </svg>
                Driver
              </a>
              <a
                class="m-1 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                href="#"
              >
                <svg
                  class="w-3 h-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                </svg>
                Plumber
              </a>
              <a
                class="m-1 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                href="#"
              >
                <svg
                  class="w-3 h-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                </svg>
                Hospitality
              </a>
              <a
                class="m-1 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                href="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
                Retails
              </a>
              <a
                class="m-1 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                href="#"
              >
                <svg
                  class="w-3 h-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                  />
                  <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                </svg>
                Warehouse & Logistics
              </a>
              <a
                class="m-1 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                href="#"
              >
                <svg
                  class="w-3 h-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 7V1.414a1 1 0 0 1 2 0V2h5a1 1 0 0 1 .8.4l.975 1.3a.5.5 0 0 1 0 .6L14.8 5.6a1 1 0 0 1-.8.4H9v10H7v-5H2a1 1 0 0 1-.8-.4L.225 9.3a.5.5 0 0 1 0-.6L1.2 7.4A1 1 0 0 1 2 7h5zm1 3V8H2l-.75 1L2 10h6zm0-5h6l.75-1L14 3H8v2z" />
                </svg>
                Consumer service
              </a>
            </div>
          </div>
        </FadeIn>
      </div>

      <FadeIn className="static max-w-xs overflow-hidden md:max-w-7xl grid    gap-2 px-2 py-1 sm:px-6 lg:px-8 mx-auto">
        <div className=" w-full flex gap-4 overflow-x-auto ">
          {jobs && !loading && jobs.length === 0 && <ItemEmpty />}

          {jobs && jobs.map((item) => <JobItem item={item} />)}

          {loading && [
            <JobItemLoading />,
            <JobItemLoading />,
            <JobItemLoading />,
          ]}
        </div>

        <a
          class="col-span-full lg:col-span-1 group flex flex-col justify-center text-center rounded-xl p-4 md:p-6 border border-dashed border-gray-200 hover:shadow-sm dark:border-gray-700"
          href="/jobs"
        >
          <h3 class="text-lg text-gray-800 dark:text-gray-200">Hiring !</h3>
          <div>
            <span class="inline-flex items-center gap-x-2 text-sky-600 group-hover:text-sky-700 dark:text-blue-500 dark:group-hover:text-blue-400">
              See live openings
              <svg
                class="w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </span>
          </div>
        </a>
      </FadeIn>
    </FadeInStagger>
  );
}

export default JobSection;

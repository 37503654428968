import { useState } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import AdminRoute from "./adminRoute";
import SideBarAdmin from "../../components/layout/side-bar-admin";
import Feeds from "../../components/feeds/feeds";
import { LogOut, Settings2, User2Icon } from "lucide-react";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";

export default function LayoutAdmin() {
  const [sidebarOpen, setSidebarOpen] = useState(false);



  return (
    <>
      <div className=" relative">
        <SideBarAdmin
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden ">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            Dashboard
          </div>
          <button
            onClick={(e) => {
              const classList = e.currentTarget.nextElementSibling.classList;
              if (classList.contains("hidden")) {
                classList.remove("hidden");
                classList.add("block");
              } else {
                classList.remove("block");
                classList.add("hidden");
              }
            }}
          >
            <User2Icon className="size-8 text-gray-800" />
          </button>

          <div className=" w-3/5 right-3 transition-all duration-200 rounded-lg hidden   top-16 mt-2 bg-white border absolute   z-[50]">
            <ul className=" divide-y text-sm ">
              <li className="p-3 bg-gray-100">
                <span className=" font-semibold break-words text-sky-900">
                  Log in as : {auth.currentUser.email}
                </span>
              </li>
              <li className="p-3 ">
                <Link
                  onClick={(e) => {
                    e.currentTarget.parentElement.parentElement.parentElement.classList.remove("block");
                    e.currentTarget.parentElement.parentElement.parentElement.classList.add("hidden");
                  }}
                  className="flex items-center gap-2"
                  to={"/account-settings"}
                >
                  <Settings2 className="size-5" />
                  <span>Account settings</span>
                </Link>
              </li>
              <li className="p-3 ">
                <Link
                  onClick={(e) => {
                    e.currentTarget.parentElement.parentElement.parentElement.classList.remove("block");
                    e.currentTarget.parentElement.parentElement.parentElement.classList.add("hidden");
                  }}
                  className="flex items-center gap-2"
                  to={"/log-out"}
                >
                  <LogOut className=" size-5" />
                  <span>Log out</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <main className="lg:pl-72">
          <div className="xl:pr-96">
            <div className="p-4">
              <AdminRoute />
            </div>
          </div>
        </main>

        <aside className="fixed z-50 bg-white inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 p-3 xl:block">
          <span className=" font-extralight text-2xl text-sky-600 ">
            Feeds{" "}
          </span>
          <hr className="my-4" />

          <Feeds />
        </aside>
      </div>
    </>
  );
}

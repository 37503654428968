import { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { openDeleteModal } from "../redux/users";
import { auth, storage } from "../firebase";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

import useCurrencies from "./currency";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { updateProfile as updateAuth } from "firebase/auth";
import Roles from "../Enum/UserRoles";

const useProfileUpdate = () => {
  const [profile, setProfile] = useState(null);
  const [availabilities, setAvailabilities] = useState(null);
  const [requestStatus, setRequestStatus] = useState({
    error: false,
    success: false,
  });
  const [load, setLoad] = useState(true);

  /* location */

  const [location, setLocation] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
  });

  /* password */

  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  /* account details */
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    logoUrl: "",
    phone: "",
    email: "",
    currency: "",
    location: "",
    owner: "",
    sector: "",
    crn: "",
    vat: "",
    country: "",
  });

  const dispatch = useDispatch();
  const db = getFirestore();
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;
  const userId = currentUser.uid;
  const currencies = useCurrencies();

  useEffect(() => {
    if (profile) {
      setLocation(profile.location);
    }
  }, [profile]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoad(false);

        // Vérifier si l'utilisateur est un propriétaire ou un sous-utilisateur
        const docRef = doc(db, "companies", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Si l'utilisateur est propriétaire
          setProfile({ id: docSnap.id, ...docSnap.data() });
        } else {
          // Si l'utilisateur n'est pas propriétaire, vérifier s'il est sous-utilisateur
          const companiesSnapshot = await getDocs(collection(db, "companies"));

          for (const companyDoc of companiesSnapshot.docs) {
            const companyData = companyDoc.data();

            if (companyData.subUsers && Array.isArray(companyData.subUsers)) {
              const subUser = companyData.subUsers.find(
                (sub) => sub.uid === userId
              );

              if (subUser) {
                // Si l'utilisateur est trouvé comme sous-utilisateur
                setProfile({
                  id: companyDoc.id,
                  ...companyData,
                  role: subUser.role, // Rôle spécifique du sous-utilisateur
                });
                break;
              }
            }
          }

          if (!profile) {
            console.log("User is not associated with any company.");
          }
        }

        setLoad(true);
      } catch (error) {
        setLoad(true);
        console.error("Error fetching profile: ", error);
      }
    };

    const unsubscribe = onSnapshot(
      doc(db, "companies", userId),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          fetchProfile();
        } else {
          console.log("No such document!");
        }
      }
    );

    if (userId) {
      fetchProfile();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe(); // Detach the listener when the component unmounts
      }
    };
  }, [userId, db]);

  useEffect(() => {
    if (profile) {
      setFormData(profile);
      if (!profile?.radius) {
        setFormData((prevData) => ({ ...prevData, radius: 10 }));
      }
    }
  }, [profile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "currency") {
      const selectedCurrency = currencies.find(
        (currency) => currency.code === value
      );
      setFormData({ ...formData, currency: selectedCurrency });
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setPassword((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeLocation = (e) => {
    const { name, value } = e.target;
    setLocation((prev) => ({ ...prev, [name]: value }));
  };

  const updateProfile = async (updatedData) => {
    try {
      setLoad(false);
      const docRef = doc(db, "companies", userId);
      await updateDoc(docRef, updatedData);
      setProfile((prevProfile) => ({ ...prevProfile, ...updatedData }));
      setLoad(true);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const updatePasswords = async () => {
    try {
      if (password.newPassword !== password.confirmPassword) {
        setRequestStatus({
          error: true,
          content: "New password and confirmation password do not match",
        });
        return;
      }

      setLoad(false);
      const currentUser = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        password.oldPassword
      );

      await reauthenticateWithCredential(currentUser, credential)
        .then(async () => {
          await updatePassword(currentUser, password.newPassword);
          setRequestStatus({ success: true, content: "Update successful" });
          setPassword({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        })
        .catch((error) => {
          setRequestStatus({
            error: true,
            content: "Something went wrong!",
          });
        });

      setLoad(true);
    } catch (error) {
      setLoad(true);
      throw new Error(error);
    }
  };

  const updateNotificationSettings = async (params) => {
    try {
      setLoad(false);
      const docRef = doc(db, "notification_settings", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, params);
      } else {
        await setDoc(doc(db, "notification_settings", userId), params);
      }
      setLoad(true);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const handleDeleteAccount = () => {
    dispatch(openDeleteModal());
  };

  const handleSave = async (file = false) => {
    const params = formData;

    if (file) {
      const url = await UploadDocument(file);

      params.logoUrl = url;

      await updateAuth(auth.currentUser, {
        photoURL: url,
      });
    } else {
      if (!auth.currentUser.displayName) {
        await updateAuth(auth.currentUser, {
          displayName: formData.name,
        });
      }

      params.location = location;
      await updateProfile(formData);
    }
  };

  const handleSavePassword = async (e) => {
    e.preventDefault();
    await updatePasswords();
  };

  const UploadDocument = async (file) => {
    try {
      setLoad(false);
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      setLoad(true);

      return url;
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const docRef = doc(db, "availabilities", userId);
    const fetchAvailabilities = async () => {
      try {
        setLoad(false);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAvailabilities(docSnap.data());
        } else {
          console.log("No such document!");
        }

        setLoad(true);
      } catch (error) {
        setLoad(true);
        console.error("Error fetching profile: ", error);
      }
    };

    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        fetchAvailabilities();
      } else {
        console.log("No such document!");
      }
    });

    if (userId) {
      fetchAvailabilities();
    }
  }, []);

  const updateAvailability = async (params) => {
    try {
      setLoad(false);
      const docRef = doc(db, "availabilities", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, params);
      } else {
        await setDoc(doc(db, "availabilities", userId), params);
      }
      setLoad(true);
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  return {
    profile,
    updateProfile,
    handleDeleteAccount,
    load,
    requestStatus,
    updatePasswords,
    setRequestStatus,
    updateNotificationSettings,
    formData,
    handleChange,
    handleSave,
    handleSavePassword,
    handleChangePassword,
    password,

    UploadDocument,
    setLoad,
    updateAvailability,
    availabilities,
    handleChangeLocation,
    location,
  };
};

export default useProfileUpdate;

import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  BookmarkIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  ChartPieIcon,
  DocumentTextIcon,
  ChatBubbleLeftIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { setOpenProfileSide } from "../../redux/admin";
import { classNames } from "../utils/className";
import { useDispatch } from "react-redux";
import MessagesIcon from "./messagesIcon";
import useFirestore from "../../hook/firestore";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase";
import GenericImg from "./generic-user-img";
import { LogOutIcon, User2Icon } from "lucide-react";

const navigation = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },
  { name: "Users", href: "/users", icon: UsersIcon, current: false },
  {
    name: "Invoices",
    href: "/invoices",
    icon: DocumentTextIcon,
    current: false,
  },
  { name: "Finance", href: "/finance", icon: ChartPieIcon, current: false },
  {
    name: "Industries",
    href: "/industries",
    icon: AdjustmentsHorizontalIcon,
    current: false,
  },
];

function SideBarAdmin({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { countAllDocuments } = useFirestore("inquires");
  const [countInquires, setCountInquires] = useState(0);

  const fetchCount = async () => {
    const result = await countAllDocuments();
    setCountInquires(result);
  };

  useEffect(() => {
    fetchCount();

    const unsubscribe = onSnapshot(query(collection(db, "inquires")), () => {
      fetchCount();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className=" relative">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <Link className="flex h-16 shrink-0 items-center" to={"/"}>
                    <img
                      className="h-8 w-auto"
                      src={require("../../assets/img/logo.png")}
                      alt="logo"
                    />
                  </Link>
                  <hr />
                  <nav className="flex  flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <Link
                          to={"/inquires"}
                          className={classNames(
                            "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <div className="indicator">
                            <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-sky-600">
                              {countInquires}
                            </span>
                            <BookmarkIcon
                              className={classNames(
                                "text-gray-400 group-hover:text-sky-600",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                          </div>
                          Inquiries
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/messages"}
                          className={classNames(
                            "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <MessagesIcon />
                          <span>Messages</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <hr />
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                className={classNames(
                                  location.pathname === item.href
                                    ? "bg-gray-50 text-sky-600"
                                    : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    location.pathname === item.href
                                      ? "text-sky-600"
                                      : "text-gray-400 group-hover:text-sky-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <Link className="flex h-16 shrink-0 items-center" to={"/"}>
            <img
              className="h-8 w-auto"
              src={require("../../assets/img/logo.png")}
              alt="Your Company"
            />
          </Link>
          <hr />
          <nav className="flex  flex-col">
            <ul role="list" className="-mx-2 space-y-1">
              <li>
                <Link
                  to={"/inquires"}
                  className={classNames(
                    "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  )}
                >
                  <div className="indicator">
                    <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-sky-600">
                      {countInquires}
                    </span>
                    <BookmarkIcon
                      className={classNames(
                        "text-gray-400 group-hover:text-sky-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  </div>
                  Inquiries
                </Link>
              </li>
              <li>
                <Link
                  to={"/messages"}
                  className={classNames(
                    "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  )}
                >
                  <MessagesIcon />
                  <span>Messages</span>
                </Link>
              </li>
            </ul>
          </nav>
          <hr />
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          location.pathname === item.href
                            ? "bg-gray-50 text-sky-600"
                            : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            location.pathname === item.href
                              ? "text-sky-600"
                              : "text-gray-400 group-hover:text-sky-600",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="-mx-6 flex items-center  mt-auto  justify-between border-t hover:bg-gray-50">
                <Link
                  to={"/account-settings"}
                  className="flex items-center   text-sky-600 gap-x-4 px-6 py-3 text-sm font-semibold leading-6  "
                >
                  <User2Icon />
                  <span>Account settings</span>
                </Link>
                <Link
                  to={"log-out"}
                  className=" z-40 px-4 py-2 me-3 bg-red-100 hover:bg-red-300 text-red-900 rounded-lg"
                >
                  <LogOutIcon className=" size-4" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default SideBarAdmin;

// LeadsComponent.js

import React, { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useDispatch } from "react-redux";
import useCurrentBreakpoint from "../../../hook/tailwindBreakPoint";
import moment from "moment";
import { setIdShift, setShiftPunch } from "../../../redux/shift";
import GenericImg from "../../../components/layout/generic-user-img";
import useFirestore from "../../../hook/firestore";
import useProfileUpdate from "../../../hook/company";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { classNames } from "../../../components/utils/className";
import { db } from "../../../firebase";
import { Shift } from "../../../Enum/shift";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";

const Row = ({ item }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIdShift(item.id));
    dispatch(setShiftPunch());
  };

  return (
    <div className="grid grid-cols-12 gap-4 p-4 py-8 rounded-lg text-sky-900  border relative">
      <div className="flex justify-center items-center col-span-2 absolute top-0 right-0">
        <p
          className={classNames(
            "flex items-center text-xs font-semibold  whitespace-nowrap px-4 py-2 rounded-bl-lg",
            item.status === Shift.CONFIRMED && "bg-green-100 text-green-800 ",
            item.status === Shift.PAID && " bg-green-800 text-green-50",
            item.status === Shift.CANCEL && " bg-red-800 text-red-50",
            item.status === Shift.IN_PROGRESS &&
              " animate-pulse bg-sky-800 text-sky-50",
            item.status === Shift.NO_SHOW && " bg-orange-600 text-orange-50",
            item.status === Shift.PENDING_PAYMENT &&
              " bg-indigo-600 animate-pulse text-indigo-50",
            item.status === Shift.PENDING_RATING &&
              " bg-yellow-600 text-yellow-50 animate-pulse",
            item.status === Shift.RATED && " bg-sky-600 text-sky-50"
          )}
        >
          {item.status}
        </p>
      </div>
      <div className=" flex justify-center  items-center col-span-2">
        <div className="size-8 rounded-full">
          {!item.candidate.avatarUrl && <GenericImg />}
          {item.candidate.avatarUrl && (
            <img
              src={item.candidate.avatarUrl}
              className="size-full rounded-full object-cover"
              alt=""
            />
          )}
        </div>
      </div>
      <div className=" col-span-8 my-2">
        <div className="">
          <div className="text-xs md:text-sm  font-semibold">
            {item.candidate.name}
          </div>
          <div className="font-normal text-xs md:text-sm">{item.job.name}</div>
          <div className="grid sm:grid-cols-2  gap-2 mt-3">
            <div className=" bg-indigo-600 text-white text-xs font-semibold flex justify-center rounded-md p-2 ">
              {moment.unix(item.job.startAt.unix).local().fromNow()}
            </div>
            <div className=" bg-green-600 text-white text-xs font-semibold flex justify-center rounded-md p-2 ">
              {item.job.hourlyRate}
            </div>
          </div>
        </div>
      </div>

      <div className=" col-span-2 flex justify-center items-center">
        <button
          type="button"
          onClick={handleClick}
          className={`rounded-full p-1  text-white bg-sky-900  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-800`}
        >
          <PlusIcon className="size-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const UpcomingShifts = () => {
  const dispatch = useDispatch();
  const { readDocumentsWithQuery, callableFunction, loading } =
    useFirestore("shifts");
  const { profile } = useProfileUpdate();
  const [shifts, setShits] = useState([]);
  const [name, setName] = useState(null);
  const [filterBy, setFilterBy] = useState("*");

  const fetchShifts = async () => {
    const result = await callableFunction("filterShifts", {
      name,
      status: filterBy,
      company: profile.id,
    });
    const { data } = result;

    setShits(data);
  };

  useEffect(() => {
    if (profile) {
      fetchShifts();

      const unsubscribe = onSnapshot(
        query(collection(db, "shifts"), where("job.company", "==", profile.id)),
        () => {
          fetchShifts();
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile, name, filterBy]);

  return (
    <div className="grid ">
      <div className="relative overflow-hidden p-4  h-[35rem] pb-8  border rounded-lg">
        {loading && <SpinnerLoadData />}
        <span className=" text-xs md:text-sm font-semibold mt-4">Shifts</span>
        <div className="mt-4 px-2">
          <div className=" grid sm:grid-cols-2 gap-4 ">
            <div className="">
              <select
                id="location"
                name="location"
                className=" p-2  w-full text-sm border-none  rounded-lg  bg-gray-100"
                defaultValue="All"
                onChange={(e) => setFilterBy(e.currentTarget.value)}
              >
                <option value={"*"}>All</option>
                <option value={"confirmed"}>Confirmed</option>
                <option value={"cancel"}>Cancelled</option>
                <option value={"no_show"}>No show</option>
              </select>
            </div>

            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                onChange={(e) => setName(e.currentTarget.value)}
                id="table-search-users"
                className="  pl-10 p-2 w-full  text-sm border-none rounded-lg   bg-gray-100"
                placeholder="Search for users"
              />
            </div>
          </div>
        </div>

        <div className="h-[80%] mt-4   px-2 flex flex-col gap-4 overflow-hidden hover:overflow-y-scroll">
          {shifts && shifts.map((item) => <Row item={item} />)}
        </div>
      </div>
    </div>
  );
};

export default UpcomingShifts;

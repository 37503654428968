import React, { useEffect, useState } from "react";
import useCompanies from "../../../hook/companies";
import { useDispatch, useSelector } from "react-redux";
import Stars from "../../../components/rating/Stars";
import Chart from "./chart";
import ListBoxChart from "../../company/Dashboard/listboxchart";
import useFirestore from "../../../hook/firestore";
import useProfileUpdate from "../../../hook/candidate";
import moment from "moment";
import { setId, setOpen } from "../../../redux/application";
import { Link, useNavigate } from "react-router-dom";
import { haversineDistance } from "../../../utils";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import Application from "../../../Enum/Applicatition";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import GenericImg from "../../../components/layout/generic-user-img";
import ItemEmpty from "../../../components/utils/empty";
import Map from "./Map";

function StatsView() {
  const [number, setNumber] = useState(0);
  const [upcoming, setUpcoming] = useState(0);
  const [dataType, setDataType] = useState("");
  const { calculateShiftEarnings, loading } = useFirestore("shifts");
  const { profile, load } = useProfileUpdate();
  const {
    countDocumentsWithQuery: countStatApplication,
    loading: loadApplications,
  } = useFirestore("applications");
  const { countDocumentsWithQuery: shiftsCount, loading: loadShifts } =
    useFirestore("shifts");
  const [count, setCount] = useState({
    applications: 0,
    shifts: 0,
    accepted: 0,
    stars: 0,
  });

  window.tesFunction = {
    maFonction: function (param) {
      window.ReactNativeWebView.postMessage(param);
    },
  };

  const fetchCounts = async () => {
    const query = [where("candidate.id", "==", profile.id)];
    const appt = await countStatApplication(query);
    const accpt = await countStatApplication([
      where("status", "==", Application.ACCEPTED),
      where("candidate.id", "==", profile.id),
    ]);

    const sc = await shiftsCount(query);

    const params = {
      applications: appt,
      shifts: sc,
      accepted: accpt,
      stars: profile.averageStars || 0,
    };

    setCount(params);
  };

  const fetchSumTotalEarn = async () => {
    const result = await calculateShiftEarnings(
      [
        where("candidate.id", "==", profile.id),
        where("invoice.success", "==", true),
        where("paid", "==", true),
      ],
      {
        earning: true,
        hour: false,
      }
    );

    setNumber(result);
  };

  const fetchSumTotalEarnUpcoming = async () => {
    const result = await calculateShiftEarnings(
      [
        where("candidate.id", "==", profile.id),
        where("invoice.success", "==", true),
        where("paid", "==", false),
      ],
      {
        earning: true,
        hour: false,
      }
    );

    setUpcoming(result);
  };

  useEffect(() => {
    if (profile) {
      fetchCounts();
      fetchSumTotalEarn();
      fetchSumTotalEarnUpcoming();
      const unsubscribe = onSnapshot(
        query(
          collection(db, "applications"),
          where("candidate.id", "==", profile?.id) // Condition: where name is "test"
        ),
        (querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              fetchCounts();
            });
          } else {
            console.log("No matching documents!");
          }
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile]);

  return (
    <div className="  md:h-[25rem]   rounded-lg border p-2">
      <div className=" flex gap-4 *:rounded-lg w-full overflow-x-auto ">
        <div
          onClick={() => setDataType("")}
          className=" w-fit cursor-pointer bg-gray-100 flex-shrink-0 col-span-1 gap-2 flex items-center  p-2  relative"
        >
          {loadApplications && <SpinnerLoadData />}
          <div className=" bg-gray-100 p-2 rounded-lg ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-sky-900 to-sky-500">
              {count.applications}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Applications</h1>
          </div>
        </div>
        <div
          onClick={() => setDataType(Application.ACCEPTED)}
          className=" w-fit cursor-pointer bg-gray-100 flex-shrink-0 col-span-1 gap-2 flex items-center  p-2 relative "
        >
          {loadApplications && <SpinnerLoadData />}
          <div className=" bg-gray-100 p-2 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-green-500 to-sky-500">
              {count.accepted}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Accepted</h1>
          </div>
        </div>
        <div
          onClick={() => setDataType("shifts")}
          className=" w-fit cursor-pointer bg-gray-100 flex-shrink-0 col-span-1 gap-2 flex items-center  p-2 relative "
        >
          {loadShifts && <SpinnerLoadData />}
          <div className=" bg-gray-100 p-2 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-violet-700 to-sky-700">
              {count.shifts}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400">Shifts scheduled</h1>
          </div>
        </div>
        <div
          onClick={() => setDataType("stars")}
          className="  w-fit cursor-pointer bg-gray-100 flex-shrink-0 col-span-1 gap-2 flex items-center  p-2  relative"
        >
          {!load && <SpinnerLoadData />}
          <div className=" bg-gray-100 p-2 rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-orange-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
              />
            </svg>
          </div>

          <div className=" flex flex-col">
            <h1 className="text-xl block font-extrabold bg-clip-text bg-gradient-to-tr text-transparent from-orange-600 to-orange-500">
              {count.stars}
            </h1>
            <h1 className=" text-[0.65rem]  text-gray-400"> Stars rating</h1>
          </div>
        </div>
      </div>
      <hr className="my-2" />
      <div className=" grid sm:grid-cols-2 gap-2 *:rounded-lg ">
        <div className=" col-span-full gap-2 flex items-center  p-2 ">
          <div className=" bg-gray-100 p-2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 text-sky-800"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
              />
            </svg>
          </div>
          <div className=" flex flex-col">
            <h1 className="text-3xl block font-bold bg-clip-text bg-gradient-to-tr text-transparent from-indigo-800 to-sky-500">
              {profile && [profile.currency?.code, number].join(" ")}
            </h1>

            <div className="inline-flex text-xs  font-semibold text-sky-600">
              <div className=" size-4  rounded-full bg-gray-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
                  />
                </svg>
              </div>

              <span>
                {" "}
                {profile && [profile.currency?.code, upcoming].join(" ")}
              </span>
            </div>
            <h1 className=" text-[0.65rem] font-normal text-gray-400">Earnt</h1>
          </div>
        </div>
      </div>
      <div className=" max-sm:h-28 max-md:h-[10rem] h-[12rem]">
        <Chart dataType={dataType} setDataType={setDataType} />
      </div>
    </div>
  );
}

const UpcomingItem = ({ item }) => {
  return (
    <>
      <div className=" text-sky-900 grid grid-cols-2 gap-2  py-4 border-b  items-center  text-xs     ">
        <span className="font-semibold  ">{item.job.name}</span>
        <span className="font-semibold  text-end">{item.job.hourlyRate}</span>

        <span className="font-semibold ">
          {moment.unix(item.job.startAt.unix).local().fromNow()}
        </span>
        <span className="  font-semibold  text-end  ">{item.status}</span>
      </div>
    </>
  );
};

function UpcomingView() {
  const [shifts, setShits] = useState([]);
  const { profile } = useProfileUpdate();
  const { readDocumentsWithQuery, loading } = useFirestore("shifts");

  const fetchShifts = async () => {
    const result = await readDocumentsWithQuery([
      where("candidate.id", "==", profile.id),
    ]);
    setShits(result);
  };

  useEffect(() => {
    if (profile) {
      fetchShifts();

      const unsubscribe = onSnapshot(
        query(
          collection(db, "shifts"),
          where("candidate.id", "==", profile.id)
        ),
        () => {
          fetchShifts();
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile]);

  return (
    <div className=" bg-white overflow-hidden  border gap-2  h-[25rem] flex flex-col p-4 rounded-lg   ">
      <h1 className="text-sm text-sky-900">Upcoming</h1>
      <div className=" flex mt-2 rounded-lg shadow-sm-light flex-col h-80 overflow-y-scroll p-4  bg-gray-100 ">
        {shifts && shifts.map((item) => <UpcomingItem item={item} />)}
        {!loading && shifts.length === 0 && <ItemEmpty />}
      </div>
    </div>
  );
}

function JobItem({ item }) {
  const { readDocument } = useFirestore("companies");
  const { profile } = useProfileUpdate();
  const { readDocumentsWithQuery: jobApplied } = useFirestore("applications");
  const [wasApplied, setWasApplied] = useState();
  const [status, setStatus] = useState();
  const [company, setCompany] = useState();
  const dispatch = useDispatch();
  const [distance, setDistance] = useState(0);
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );

  const checkIfApplied = async () => {
    const result = await jobApplied([
      where("job.id", "==", item.id),
      where("candidate.id", "==", profile.id),
    ]);

    console.log(result);

    if (result.length > 0) {
      setWasApplied(true);
      setStatus(result[0].status);
    }
  };
  const handleClick = () => {
    dispatch(setId(item.id));
    dispatch(setOpen(true));
  };

  const fetchCompany = async () => {
    const result = await readDocument(item.company.id);
    setCompany(result);
  };

  const CalculDistance = async () => {
    const result = await haversineDistance(
      latitude,
      longitude,
      item.location?.latitude,
      item.location?.longitude
    );
    if (result) {
      setDistance(result);
    }
  };

  useEffect(() => {
    if (profile) {
      fetchCompany();
      CalculDistance();
      checkIfApplied();
    }
  }, [item, profile]);

  return (
    <div className=" p-4 py-6 shrink-0  border bg-white shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-[.65rem] text-end">
          {moment.unix(item.creatAt).local().fromNow()}
        </h1>
      </div>

      <div className=" grid md:grid-cols-12 gap-4 py-2 ">
        <div className="  md:col-span-4 flex flex-col gap-2">
          <h1 className="text-sm font-semibold">{item.jobName}</h1>
          <h1 className="text-xs font-semibold">{item.company.name}</h1>
          <h1 className=" text-end font-semibold">
            {company && <Stars number={company.averageStars || 0} />}
          </h1>
        </div>
        <div className=" grid grid-cols-2 gap-2 md:col-span-8  *:rounded-lg ">
          <div className=" bg-gray-100 p-2  text-sky-900 text-xs font-semibold text-center">
            {item.startDate}
          </div>
          <div className=" bg-gray-100 p-2  text-sky-900 text-xs font-semibold text-center">
            {[item.startAt.time, item.endAt.time].join("-")}
          </div>
          <div className=" bg-gray-100  text-sky-900 text-xs p-2 font-semibold text-center ">
            {distance.toFixed(2)} Km Away
          </div>
          <div className=" bg-gray-100   text-sky-900 text-xs p-2 font-semibold text-center">
            {[item.currency, item.hourlyRate].join(" ")}/h
          </div>

          <div className="  grid grid-cols-2 gap-2   col-span-full">
            <Link
              to={`/jobs?id=${item.id}`}
              className=" border text-center text-xs col-span-full bg-white p-2 font-semibold  rounded-lg"
            >
              More details
            </Link>
            <button
              onClick={handleClick}
              disabled={wasApplied}
              className=" disabled:bg-gray-100 disabled:text-sky-600 text-center bg-sky-600 text-xs p-2 col-span-full font-semibold text-white rounded-lg"
            >
              {wasApplied ? status : " Quick Apply"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function JobItemEmpty() {
  return (
    <div className="  border rounded-lg  flex-col gap-4  h-54 w-full  flex justify-center items-center p-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
        />
      </svg>

      <h1 className="text-sky-900 text-sm font-medium">
        No more job to load
      </h1>
    </div>
  );
}
function JobItemLoading() {
  return (
    <div className=" p-4 py-6 shrink-0  border bg-white shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-[.65rem] text-end rounded-lg px-3 pt-1 bg-gray-100 h-4 w-8 animate-pulse "></h1>
      </div>

      <div className=" grid md:grid-cols-12 gap-4 py-2 ">
        <div className="  md:col-span-4 flex flex-col gap-2">
          <h1 className="text-base font-semibold bg-gray-100 animate-pulse rounded-lg w-full h-4"></h1>
          <h1 className="text-base font-semibold bg-gray-100 animate-pulse rounded-lg w-full h-4"></h1>
          <h1 className="text-base font-semibold bg-gray-100 animate-pulse rounded-lg w-full h-4"></h1>
        </div>
        <div className=" grid grid-cols-2 gap-2 md:col-span-8  *:rounded-lg ">
          <div className=" bg-gray-100 p-2  animate-pulse text-sky-50 text-xs font-semibold text-center">
            2024-01-01
          </div>
          <div className=" bg-gray-100 p-2  animate-pulse text-sky-50 text-xs font-semibold text-center">
            07:15 PM - 11:15 PM
          </div>
          <div className=" bg-gray-100 animate-pulse  text-sky-50 text-xs p-2 font-semibold text-center ">
            12km Away
          </div>
          <div className=" bg-gray-100 animate-pulse   text-sky-50 text-xs p-2 font-semibold text-center">
            21.00 GBP/H
          </div>

          <div className="  grid grid-cols-2 gap-2   col-span-full">
            <button className="  text-sky-50 text-center text-xs col-span-full bg-gray-100 animate-pulse p-2 font-semibold  rounded-lg">
              More details
            </button>
            <button className=" text-center bg-sky-600 animate-pulse text-xs p-2 col-span-full font-semibold text-sky-600 rounded-lg">
              {" "}
              Quick Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function EmployersItem({ item }) {
  const { countDocumentsWithQuery } = useFirestore("jobs");
  const [number, setNumber] = useState();

  const fetchCountJobsCount = async () => {
    const result = await countDocumentsWithQuery([
      where("company.id", "==", item.id),
    ]);

    setNumber(result);
  };

  useEffect(() => {
    fetchCountJobsCount();
  }, [item]);

  return (
    <div className=" flex flex-col relative border size-[20rem] overflow-hidden shadow-sm shrink-0 gap-6  bg-white rounded-lg items-center py-2 ">
      <div className=" absolute text-sm font-semibold top-0 right-0 p-2  border border-t-0 border-e-0 gap-2 bg-sky-800 text-white flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
          />
        </svg>

        <span>{[number, number > 10 ? "+" : ""].join("")}</span>
      </div>

      <div className="  rounded-lg size-32 ">
        {item.logoUrl && (
          <img
            src={item.logoUrl}
            className=" size-full aspect-square rounded-lg  object-cover"
            alt=""
          />
        )}
        {!item.logoUrl && <GenericImg />}
      </div>

      <div className=" grid grid-cols-2 gap-2 p-2 *:rounded-lg w-full">
        <div className=" text-center p-2 font-semibold col-span-full bg-slate-100 text-sky text-sm">
          {item.name}
        </div>
        <div className=" p-2 bg-slate-100 text-sky-900 flex justify-center items-center  text-sm">
          <Stars number={item.averageStars || 0} />
        </div>
        <div className=" p-2  text-sm bg-slate-100 text-sky-900 text-center">
          {item.sector}
        </div>
        <Link
          to={`/company?id=${item.id}`}
          className=" p-2 bg-sky-600 text-sm  font-semibold text-white flex justify-center items-center col-span-full"
        >
          About Company
        </Link>
      </div>
    </div>
  );
}
function EmployersItemLoading() {
  return (
    <div className=" flex flex-col relative border size-[20rem] overflow-hidden shadow-sm shrink-0  bg-white rounded-lg items-center py-2 ">
      <div className=" absolute text-sm font-semibold top-0 right-0 p-2 animate-pulse  border border-t-0 border-e-0 bg-sky-800 text-transparent">
        {" "}
        <span>10+</span>
      </div>

      <div className=" bg-gray-100 animate-pulse rounded-lg *:rounded-lg size-40 "></div>

      <div className=" grid grid-cols-2 gap-2 p-2 *:rounded-lg w-full">
        <div className=" bg-gray-100 animate-pulse p-2 text-transparent font-semibold col-span-full  text-sm">
          ESPACE NUMERIQUE Mayotte
        </div>
        <div className=" bg-gray-100 animate-pulse p-2  text-sm text-transparent">
          text
        </div>
        <div className=" bg-gray-100 animate-pulse p-2  text-transparent text-sm">
          Domain
        </div>
        <div className="  animate-pulse p-2 text-transparent bg-sky-600 text-sm  font-semibold  flex justify-center items-center col-span-full">
          About Company
        </div>
      </div>
    </div>
  );
}

function Dashboard() {
  const { readDocumentsWithQuery: readAllDocumentsJobs, loading: loadingJobs } =
    useFirestore("jobs");
  const {
    readAllDocuments: readAllDocumentsCompanies,
    loading: loadingCompanies,
  } = useFirestore("companies");
  const { profile } = useProfileUpdate();
  const [jobs, setJobs] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    (async () => {
      const now = moment.utc().unix();
      const result = await readAllDocumentsJobs([
        where("startAt.unix", ">=", parseInt(now)),
        orderBy("creatAt", "desc"),
      ]);
      setJobs(result);
    })();
  }, [profile]);

  useEffect(() => {
    (async () => {
      const result = await readAllDocumentsCompanies();
      setCompanies(result);
    })();
  }, [profile]);

  useEffect(() => {
    if (profile) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, "applications"),
          where("candidate.id", "==", profile.id)
        ),
        async () => {
          const now = moment.utc().unix();
          const result = await readAllDocumentsJobs([
            where("startAt.unix", ">=", parseInt(now)),
            orderBy("creatAt", "desc"),
          ]);
          setJobs(result);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile]);

  return (
    <>
      <div className=" overflow-y-scroll  max-h-[calc(100vh-4rem)] pb-20">
        <div className=" lg:grid flex flex-col  lg:grid-cols-12 gap-4 p-4">
          <div className="md:hidden  w-full  flex flex-col gap-4 overflow-hidden ">
            <h1 className="text-sky-900 text-sm">Instant apply</h1>

            {!loadingJobs && (
              <div className="    gap-4  w-full overflow-x-hidden hover:overflow-x-scroll flex rounded-lg">
                {jobs.map((item) => (
                  <JobItem item={item} />
                ))}
              </div>
            )}

            {jobs.length === 0 && !loadingJobs && <JobItemEmpty />}

            {loadingJobs && (
              <div className="    gap-4  w-full overflow-x-hidden hover:overflow-x-scroll flex rounded-lg">
                <JobItemLoading />
                <JobItemLoading />
                <JobItemLoading />
                <JobItemLoading />
              </div>
            )}
          </div>


<div className="max-lg:flex hidden rounded-lg h-[30vh]">
<Map />

</div>
         
          <div className=" col-span-6 flex flex-col ">
            <StatsView />
          </div>

          <div className=" col-span-6  flex flex-col  gap-4 ">
            <UpcomingView />
          </div>
        </div>

        <div className="max-md:hidden  w-full p-4 flex flex-col gap-4 overflow-hidden ">
          <h1 className="text-sky-900 text-sm">Instant apply</h1>

          {!loadingJobs && (
            <div className="    gap-4  w-full overflow-x-hidden hover:overflow-x-scroll flex rounded-lg">
              {jobs.map((item) => (
                <JobItem item={item} />
              ))}
            </div>
          )}

          {jobs.length === 0 && !loadingJobs && <JobItemEmpty />}

          {loadingJobs && (
            <div className="    gap-4  w-full overflow-x-hidden hover:overflow-x-scroll flex rounded-lg">
              <JobItemLoading />
              <JobItemLoading />
              <JobItemLoading />
              <JobItemLoading />
            </div>
          )}
        </div>
        <div className=" w-full p-4 flex flex-col gap-4 overflow-hidden ">
          <h1 className="text-sky-900 text-sm">Your are in good company</h1>

          {!loadingCompanies && (
            <div className="   gap-4  w-full  overflow-x-hidden hover:overflow-x-scroll flex rounded-lg">
              {companies.map((item) => (
                <EmployersItem item={item} />
              ))}
            </div>
          )}
          {loadingCompanies && (
            <div className="   gap-4  w-full  overflow-x-hidden hover:overflow-x-scroll flex rounded-lg">
              <EmployersItemLoading />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;

import React, { useEffect, useState } from "react";
import Stars from "../../../components/rating/Stars";
import useFirestore from "../../../hook/firestore";
import useProfileUpdate from "../../../hook/company";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { useSearchParams } from "react-router-dom";

function SkillsItem({ title }) {
  return (
    <span className=" rounded-lg p-2 text-xs w-fit px-4 text-sky-800 font-semibold bg-gray-200 ">
      {title}
    </span>
  );
}

function Details({ id }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { readDocument, loading } = useFirestore("jobs");
  const { profile } = useProfileUpdate();
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    (async () => {
      const result = await readDocument(searchParams.get("id") || id);
      setDetail(result);
    })();
  }, [searchParams.get("id") || id]);

  console.log(detail);

  return (
    <div className=" border size-full rounded-lg  p-4 md:divide-x grid md:grid-cols-12 overflow-hidden hover:overflow-y-scroll relative">
      {loading && <SpinnerLoadData />}

      {detail && (
        <div className=" col-span-4 flex p-4 gap-4 flex-col ">
          <div className=" flex gap-2 flex-col">
            <div className=" size-32 border rounded-lg p-1">
              {profile.logoUrl && (
                <img
                  src={profile.logoUrl}
                  className=" size-full  object-cover"
                  alt="logo"
                />
              )}
            </div>
            <div className=" flex  flex-col items-start">
              <div className=" space-y-2 rounded-lg p-2">
                <h1 className="  text-sm font-semibold text-sky-900">{}</h1>
                <Stars number={2} />
                <hr />
                <div className="gap-2 flex flex-col">
                  <p className="text-xs font-semibold text-gray-500">email</p>
                  <p className="text-sm font-semibold text-gray-600">
                    {" "}
                    {profile.email}
                  </p>
                </div>

                <hr />

                <div className="gap-2 flex flex-col">
                  <p className="text-xs font-semibold text-gray-500">
                    Location
                  </p>
                  <p className="text-sm font-semibold text-gray-600">
                    {[
                      profile.location.street,
                      profile.location.city,
                      profile.location.zip,
                      profile.location.state,
                    ].join(" ")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {detail && (
        <div className=" col-span-8 p-4 flex flex-col gap-2">
          <h1 className=" text-2xl text-center font-bold text-sky-900">
            {detail.jobName}
          </h1>

          <hr />

          <div className=" grid grid-cols-2 *:rounded-lg gap-2">
            <div className=" p-4 border text-sm   font-semibold text-center">
              {detail.startDate}
            </div>

            <div className="   border text-sm   p-4 font-semibold text-center">
              {[detail.currency, detail.hourlyRate].join(" ")}
            </div>
            <div className=" p-4 border  text-sm col-span-full   font-semibold text-center">
              {[detail.startAt.time, detail.endAt.time].join("-")}
            </div>
            <div className=" border col-span-full  p-4  text-sm   font-semibold text-center ">
              ---
            </div>
          </div>

          <hr />

          <h1 className="text-base  text-center font-semibold text-sky-900">
            Location
          </h1>
          <hr />
          <p className=" text-gray-700 text-center py-4 font-semibold mt-2 text-sm">
            {[
              detail.location.street,
              detail.location.city,
              detail.location.zip,
              detail.location.state,
            ].join(" ")}
          </p>
          <hr />

          <h1 className="text-base  text-center font-semibold text-sky-900">
            Description
          </h1>
          <hr />
          <p className=" text-gray-700 text-center py-4 font-semibold mt-2 text-sm">
            {detail.description}
          </p>
          <hr />
          <h1 className="text-base  text-center font-semibold text-sky-900">
            Skill(s)
          </h1>
          <hr />

          <div className=" flex  gap-2 flex-wrap">
            {detail.skill &&
              detail.skill.map((title) => <SkillsItem title={title} />)}
          </div>
        </div>
      )}
    </div>
  );
}

export default Details;

"use client";

import { useRef, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import useFirestore from "../../hook/firestore";
import { SpinnerLoadData } from "../utils/spinner-load";

export default function ModalEmail({ open, setOpen, email }) {
  const { callableFunction, loading } = useFirestore("mail");
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, ""); // Supprimer tout ce qui n'est pas un chiffre
    e.target.value = value;

    if (value && index < 5) {
      // Passer au champ suivant
      inputsRef.current[index + 1].focus();
    }

    // Si tous les champs sont remplis, vous pouvez récupérer le code complet
    // const code = inputsRef.current.map(input => input.value).join('');
    // console.log('Code saisi :', code);
  };

  const handleCheck = async (e) => {
    e.preventDefault();

    const code = inputsRef.current.map((input) => input.value).join("");

    await callableFunction("verifyEmailCode", {
      email,
      code,
    }).then(() => setOpen(false));
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData
      .getData("text")
      .slice(0, 6)
      .replace(/\D/g, "");

    if (pasteData.length === 6) {
      pasteData.split("").forEach((char, index) => {
        inputsRef.current[index].value = char;
      });
      inputsRef.current[5].focus(); // Placer le focus sur le dernier champ
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      // Si le champ est vide et que l'utilisateur appuie sur Backspace, revenir au champ précédent
      inputsRef.current[index - 1].focus();
    }
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {loading && <SpinnerLoadData />}
            <form onSubmit={handleCheck}>
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  ⚬
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Verification code
                  </DialogTitle>
                  <p className="text-xs text-gray-700 ">Check your email.</p>

                  <div className="flex gap-x-3 mt-10 justify-center">
                    {[...Array(6)].map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        name={`p${index + 1}`}
                        ref={(el) => (inputsRef.current[index] = el)}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={index === 0 ? handlePaste : null}
                        required
                        maxLength={1}
                        className="block w-[38px] text-center border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                        placeholder="⚬"
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-6">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Confirm
                </button>
              </div>
            </form>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileCandidate } from "../../redux/Calendar";
import { setOpenRepost } from "../../redux/company";
import { useSearchParams } from "react-router-dom";
import useFirestore from "../../hook/firestore";
import { SpinnerLoadData } from "../utils/spinner-load";
import moment from "moment";
import useProfileUpdate from "../../hook/company";
import JobType from "../../Enum/JobType";

export default function ModalRepost() {
  const { profile } = useProfileUpdate();
  const { openRepost } = useSelector((state) => state.companySlice);
  const { readDocument, loading, createDocument } = useFirestore("jobs");
  const [searchParams, setSearchParams] = useSearchParams();
  const [job, setJob] = useState();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    nbCandidate: 1,
    hourlyRate: 10,
  });

  const fetchJobs = async () => {
    const result = await readDocument(searchParams.get("id"));
    setJob(result);
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      fetchJobs();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const params = {
      ...job,
      ...form,
    };

    const startAt = moment(
      `${form.startDate} ${form.startHour}`,
      "YYYY-MM-DD HH:mm"
    );
    const endAt = moment(
      `${form.startDate} ${form.endHour}`,
      "YYYY-MM-DD HH:mm"
    );
    const totalHour = endAt.diff(startAt, "hours", true);
    params.creatAt = moment.utc().unix();

    params.totalHour = totalHour;

    delete params.id;
    delete params.applicationCount;

    await createDocument(params).then(() => dispatch(setOpenRepost(false)));
  };

  return (
    <Transition.Root show={openRepost} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => dispatch(setOpenRepost(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 xl:mb-0 mb-20 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
                <div className="relative">
                  {loading && <SpinnerLoadData />}
                  <h1 className="text-sm font-semibold">Repost job</h1>
                  <hr className="my-4" />

                  <p className="text-base font-semibold">
                    {job && job.jobName}
                  </p>
                  <p className="text-sm font ">{job && job.company.name}</p>
                  <form onSubmit={handleSubmit} className=" grid  gap-4 my-4">
                    <div>
                      <select
                        id="type"
                        name="type"
                        onChange={handleChange}
                        required
                        className={` block  transition-all bg-gray-100 text-sky-900  duration-500 ${
                          form.type === JobType.URGENT
                            ? "bg-orange-500 text-white font-semibold focus:ring-orange-800"
                            : form.type === JobType.TODAY
                            ? "bg-red-700 text-white font-semibold focus:ring-red-800"
                            : ""
                        }  w-full font-semibold rounded-md border-0 py-4 pl-3 pr-10     text-sm`}
                      >
                        <option disabled selected>
                          Urgency
                        </option>
                        <option value={JobType.DEFAULT}>
                          {JobType.DEFAULT}
                        </option>
                        <option value={JobType.URGENT}>{JobType.URGENT}</option>
                        <option value={JobType.TODAY}>{JobType.TODAY}</option>
                      </select>
                    </div>
                    <div className=" relative">
                      <span className=" pl-3 text-sm font-semibold absolute top-1/2 text-sky-900  -translate-y-1/2">
                        Date
                      </span>
                      <input
                        type="date"
                        required
                        min={moment().format("YYYY-MM-DD")}
                        onChange={handleChange}
                        value={form.startDate}
                        name="startDate"
                        id="date"
                        className=" block w-full pl-20 font-semibold rounded-md border-0 py-4   bg-gray-100 text-sky-900    text-sm "
                      />
                    </div>
                    <div className=" relative">
                      <span className=" pl-3 text-sm font-semibold absolute top-1/2 text-sky-900  -translate-y-1/2">
                        From
                      </span>
                      <input
                        datepicker
                        type="time"
                        required
                        value={form.startHour}
                        onChange={handleChange}
                        name="startHour"
                        className=" block w-full pl-20 font-semibold rounded-md border-0 py-4   bg-gray-100 text-sky-900    text-sm "
                        placeholder="Select date"
                      />
                    </div>
                    <div className=" relative">
                      <span className=" pl-3 text-sm font-semibold absolute top-1/2 text-sky-900  -translate-y-1/2">
                        To
                      </span>
                      <input
                        datepicker
                        type="time"
                        required
                        onChange={handleChange}
                        value={form.endHour}
                        min={form.startHour && form.startHour}
                        name="endHour"
                        className=" block w-full pl-20 font-semibold rounded-md border-0 py-4   bg-gray-100 text-sky-900    text-sm "
                        placeholder="Select date"
                      />
                    </div>
                    <div className="relative bg-gray-100 text-sky-900 p-4 rounded-lg  ">
                      <div className="flex flex-col gap-4  ">
                        <span className="text-sm font-semibold">
                          {" "}
                          Hourly rate
                        </span>
                        <input
                          id="default-range"
                          type="range"
                          step={0.05}
                          onChange={handleChange}
                          value={form.hourlyRate}
                          name="hourlyRate"
                          class="w-full h-2 bg-gray-200  rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        />
                        <span className="p-2 w-full font-semibold border rounded-lg  bg-sky-800 text-white  text-center text-sm">
                          {[profile?.currency?.code, form.hourlyRate].join(" ")}
                        </span>
                      </div>
                    </div>
                    <div className="relative bg-gray-100 text-sky-900 p-4 rounded-lg  ">
                      <div className="flex flex-col gap-4 h-fit ">
                        <span className="text-sm font-semibold">
                          {" "}
                          Headcount
                        </span>
                        <input
                          id="default-range"
                          type="range"
                          step={1}
                          name="nbCandidate"
                          value={form.nbCandidate}
                          onChange={handleChange}
                          class="w-full h-2 bg-gray-200  rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        />
                        <span className="p-2 w-full font-semibold border rounded-lg  bg-sky-800 text-white  text-center text-sm">
                          {form.nbCandidate}
                        </span>
                      </div>
                    </div>

                    <div className=" inline-flex w-full justify-center ">
                      <button
                        type="submit"
                        className="p-2 text-sm w-1/2 text-sky-50 bg-sky-900 rounded-lg"
                      >
                        Repost
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

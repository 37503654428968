import { useState } from "react";
import {
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  limit,
  orderBy,
  setDoc,
  writeBatch,
} from "firebase/firestore";

import { httpsCallable } from "firebase/functions";
import { auth, db, functions, storage } from "../firebase";
import moment from "moment";
import { useSelector } from "react-redux";
import Roles from "../Enum/UserRoles";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const useFirestore = (collectionName) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;

  const createDocument = async (data) => {
    setLoading(true);
    try {
      const docRef = await addDoc(collection(db, collectionName), data);
      setLoading(false);
      return docRef.id;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };
  const createDocumentCurrentUser = async (data) => {
    setLoading(true);
    try {
      const docRef = await setDoc(
        doc(db, collectionName, currentUser.uid),
        data
      );
      setLoading(false);
      return docRef.id;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const readDocument = async (id) => {
    setLoading(true);
    try {
      const docRef = doc(db, collectionName, id);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        throw new Error("No such document!");
      }
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const readAllDocuments = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const docs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLoading(false);
      return docs;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const readDocumentsWithQuery = async (queryConstraints) => {
    setLoading(true);
    try {
      const q = query(collection(db, collectionName), ...queryConstraints);
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLoading(false);
      return docs;
    } catch (err) {
      setError(err);
      setLoading(false);

      throw new Error(err);
    }
  };

  const updateDocument = async (id, data) => {
    setLoading(true);
    try {
      const docRef = doc(db, collectionName, id);
      await updateDoc(docRef, data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const deleteDocument = async (id) => {
    setLoading(true);
    try {
      const docRef = doc(db, collectionName, id);
      await deleteDoc(docRef);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  // New function to count all documents in a collection
  const countAllDocuments = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      setLoading(false);
      return querySnapshot.size;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  // New function to count documents with a query
  const countDocumentsWithQuery = async (queryConstraints) => {
    setLoading(true);
    try {
      const q = query(collection(db, collectionName), ...queryConstraints);
      const querySnapshot = await getDocs(q);
      setLoading(false);
      return querySnapshot.size;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const calculateShiftEarnings = async (queryConstraints, options) => {
    setLoading(true);
    try {
      const shiftsQuery = query(
        collection(db, collectionName),
        ...queryConstraints
      );
      const shiftsSnapshot = await getDocs(shiftsQuery);
      let totalEarnings = 0;
      let totalHours = 0;

      for (let shiftDoc of shiftsSnapshot.docs) {
        const shiftData = shiftDoc.data();

        const jobDoc = await getDoc(doc(db, "jobs", shiftData.job.id));

        if (jobDoc.exists()) {
          const jobData = jobDoc.data();

          if (options.earning) {
            const earnings =
              (parseFloat(jobData.totalHour) || 0) *
              (parseFloat(jobData.hourlyRate) || 0);
            totalEarnings += earnings;

            setLoading(false);
          }
          if (options.hour) {
            const hours = parseFloat(jobData.totalHour) || 0;
            totalHours += hours;

            setLoading(false);
          }
        }
      }

      return options.earning ? totalEarnings.toFixed(2) : totalHours.toFixed(2);
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };
  // New function to call the  Cloud Function
  const callableFunction = async (name, filterParams) => {
    setLoading(true);
    try {
      const callable = httpsCallable(functions, name);
      const result = await callable(filterParams);
      setLoading(false);

      return result.data;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const readMessagesFromConversation = async (conversationId) => {
    setLoading(true);
    try {
      const messagesRef = collection(
        db,
        collectionName,
        conversationId,
        "messages"
      );
      const q = query(messagesRef, orderBy("creatAt", "asc"));
      const querySnapshot = await getDocs(q);
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLoading(false);
      return messages;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const sendMessageToConversation = async (
    conversationId,
    senderId,
    messageContent,
    mood
  ) => {
    setLoading(true);
    try {
      const messagesRef = collection(
        db,
        collectionName,
        conversationId,
        "messages"
      );

      // Créer le nouveau message avec isRead = false par défaut
      const newMessage = {
        senderId: senderId,
        content: messageContent,
        creatAt: moment.utc().unix(),
        isRead: false,
        mood,
      };

      // Ajouter le message à la sous-collection "messages"
      await addDoc(messagesRef, newMessage);

      // Mettre à jour le champ lastMessage dans le document de la conversation
      const conversationRef = doc(db, "conversations", conversationId);
      await updateDoc(conversationRef, {
        lastMessage: newMessage,
      });

      setLoading(false);
      return newMessage;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  // Dans votre composant ou hook où vous définissez readConversationsForUser
  const readConversationsForUser = async (userId) => {
    setLoading(true);
    try {
      let q;
      if (role === Roles.admin) {
        q = query(collection(db, collectionName), where("admin", "==", userId));
      } else {
        q = query(
          collection(db, collectionName),
          where(
            [Roles.company, Roles.member, Roles.manager].includes(role)
              ? "company"
              : "candidate",
            "==",
            userId
          )
        );
      }

      const querySnapshot = await getDocs(q);

      const conversations = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setLoading(false);
      return conversations;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const markMessagesAsRead = async (conversationId, userId) => {
    setLoading(true);
    try {
      const messagesRef = collection(
        db,
        collectionName,
        conversationId,
        "messages"
      );

      // Rechercher tous les messages non lus pour cet utilisateur
      const q = query(
        messagesRef,
        where("isRead", "==", false),
        where("senderId", "!=", userId)
      );
      const querySnapshot = await getDocs(q);

      // Initialiser un batch pour effectuer des mises à jour en masse
      const batch = writeBatch(db); // Appeler writeBatch sur l'instance Firestore

      // Ajouter chaque mise à jour au batch
      querySnapshot.forEach((doc) => {
        const messageRef = doc.ref;
        batch.update(messageRef, { isRead: true });
      });

      // Committer toutes les mises à jour en une seule opération
      await batch.commit();
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const markLastMessageAsRead = async (conversationId, userId) => {
    setLoading(true);
    try {
      const conversationRef = doc(db, collectionName, conversationId);
      const conversationSnap = await getDoc(conversationRef);

      if (conversationSnap.exists()) {
        const conversationData = conversationSnap.data();

        // Vérifiez que l'utilisateur actuel n'est pas l'expéditeur du dernier message
        if (
          conversationData.lastMessage &&
          conversationData.lastMessage.senderId !== userId
        ) {
          // Mettre à jour le champ lastMessage.isRead à true
          await updateDoc(conversationRef, {
            "lastMessage.isRead": true,
          });
          console.log("Le dernier message a été marqué comme lu.");
        } else {
          console.log(
            "L'utilisateur est l'expéditeur du dernier message ou il n'y a pas de message."
          );
        }
      } else {
        throw new Error("Conversation non trouvée.");
      }

      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const countUnreadMessages = async (conversationId, userId) => {
    setLoading(true);
    try {
      const messagesRef = collection(
        db,
        "conversations",
        conversationId,
        "messages"
      );

      // Rechercher les messages non lus pour cet utilisateur
      const q = query(
        messagesRef,
        where("isRead", "==", false),
        where("senderId", "!=", userId)
      );
      const querySnapshot = await getDocs(q);

      const unreadCount = querySnapshot.size;
      setLoading(false);
      return unreadCount;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const updateSubUser = async (subUserUid, updatedSubUserData) => {
    const companyDocRef = doc(
      db,
      collectionName,
      currentUser.uid || subUserUid
    );

    try {
      // Récupérer le document de l'entreprise
      const companyDoc = await getDoc(companyDocRef);

      if (companyDoc.exists()) {
        const companyData = companyDoc.data();

        // Trouver l'index du sous-utilisateur dans le tableau des sous-utilisateurs
        const subUserIndex = companyData.subUsers.findIndex(
          (subUser) => subUser.uid === subUserUid
        );

        if (subUserIndex !== -1) {
          // Créer une copie des sous-utilisateurs pour modification
          const updatedSubUsers = [...companyData.subUsers];

          // Mettre à jour les données du sous-utilisateur spécifié
          updatedSubUsers[subUserIndex] = {
            ...updatedSubUsers[subUserIndex],
            ...updatedSubUserData, // Les données mises à jour du sous-utilisateur
          };

          // Mettre à jour le document de l'entreprise avec le nouveau tableau de sous-utilisateurs
          await updateDoc(companyDocRef, { subUsers: updatedSubUsers });

          console.log("Sub-user updated successfully");
        } else {
          console.log("Sub-user not found in company");
        }
      } else {
        console.log("Company not found");
      }
    } catch (error) {
      console.error("Error updating sub-user: ", error);
    }
  };

  const countUnreadConversations = async (userId, role) => {
    setLoading(true);
    try {
      // Rechercher les conversations où le dernier message n'a pas été lu
      const q = query(
        collection(db, collectionName),
        where(role, "==", userId),
        where("lastMessage.isRead", "==", false),
        where("lastMessage.senderId", "!=", userId) // S'assurer que l'utilisateur actuel n'est pas l'expéditeur du dernier message
      );
      const querySnapshot = await getDocs(q);

      const unreadConversationsCount = querySnapshot.size;
      setLoading(false);
      return unreadConversationsCount;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const UploadDocument = async (file) => {
    try {
      setLoading(true);
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      setLoading(false);

      return url;
    } catch (error) {
      throw new Error(error);
    }
  };

  const getOneSubUser = async (subUserUid) => {
    const companyDocRef = doc(db, collectionName, currentUser.uid);
    setLoading(true);
    try {
      // Récupérer le document de l'entreprise
      const companyDoc = await getDoc(companyDocRef);

      if (companyDoc.exists()) {
        const companyData = companyDoc.data();

        // Trouver le sous-utilisateur dans le tableau des sous-utilisateurs
        const subUser = companyData.subUsers?.find(
          (sub) => sub.uid === subUserUid
        );

        if (subUser) {
          setLoading(false);
          return subUser;
        } else {
          setLoading(false);
          console.log("Sub-user not found in company");
          return null; // Sous-utilisateur non trouvé
        }
      } else {
        setLoading(false);
        console.log("Company not found");
        return null; // Entreprise non trouvée
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching sub-user: ", error);
      return null;
    }
  };

  return {
    createDocument,
    readDocument,
    readAllDocuments,
    readDocumentsWithQuery,
    updateDocument,
    deleteDocument,
    countAllDocuments, // Add new count functions to the returned object
    countDocumentsWithQuery,
    callableFunction, // Add the new function to the returned object
    calculateShiftEarnings,
    createDocumentCurrentUser,
    loading,
    error,
    readMessagesFromConversation,
    sendMessageToConversation,
    readConversationsForUser,
    countUnreadMessages,
    markMessagesAsRead,
    countUnreadConversations,
    markLastMessageAsRead,
    updateSubUser,
    getOneSubUser,
    UploadDocument,
  };
};

export default useFirestore;

import { useDispatch, useSelector } from "react-redux";
import { setToogle } from "../../../redux/teams";
import { auth } from "../../../firebase";
import Roles from "../../../Enum/UserRoles";
import useProfileUpdate from "../../../hook/company";
import ItemEmpty from "../../../components/utils/empty";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

function Item({ item }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = () => {
    dispatch(setToogle());
    setSearchParams({
      id: item.uid,
      role: item.role,
    });
  };

  return (
    <div className=" shrink-0  rounded-lg  bg-gray-50 gap-4 grid grid-cols-12 p-4 relative">
      <div className="absolute bottom-2 p-2 text-xs text-sky-900">
        <span className="bg-sky-100 rounded-lg px-4 py-2  font-semibold">
          last login : {moment.unix(item.lastLogin).local().fromNow()}
        </span>
      </div>
      <span className=" absolute top-1 bg-sky-900 first-letter:capitalize text-sky-50  rounded-bl-lg px-4 py-2 lowercase  right-0 text-sm">
        {item.role}
      </span>
      <div className=" col-span-2 py-4 ">
        <div className="border size-12 bg-gray-50 rounded-full object-cover">
          <img
            src={require("../../../assets/img/inquiries.jpg")}
            className=" size-full rounded-full"
            alt=""
          />
        </div>
      </div>
      <div className=" py-4 grid gap-1 col-span-10 text-sky-900">
        <h1 className="text-sm  font-semibold">{item.name}</h1>
        <span className="text-xs">{item.email}</span>
        <span className="text-xs">{item.phone}</span>

        <div className="  inline-flex w-full justify-end">
          <button
            onClick={handleClick}
            className=" size-6 flex justify-center text-indigo-900 items-center bg-indigo-100 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className=" size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default function NewMember() {
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const { profile } = useProfileUpdate();
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID.displayName
    : auth.currentUser.displayName;
  const dispatch = useDispatch();

  return (
    <div className="p-4">
      <h3 className="text-sm font-medium text-gray-500 uppercase">
        {currentUser}'s Teams
      </h3>

      <div className=" flex justify-end ">
        <button
          type="button"
          onClick={() => dispatch(setToogle())}
          className="relative text-sky-50  rounded-lg border inline-flex px-4 py-2  gap-4  text-center  bg-sky-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="mx-auto size-4 "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <span className="  text-sm  ">Add team member</span>
        </button>
      </div>

      <hr className=" my-4" />
      <div className=" grid grid-cols-12 divide-x">
        <div className=" col-span-4 h-[calc(100vh-12rem)] overflow-hidden ">
          <div className=" h-full flex-col  flex  gap-4 overflow-y-auto px-2">
            {profile && profile?.subUsers?.length > 0 && profile?.subUsers?.map((item) => <Item item={item} />)}
            {profile && profile?.subUsers?.length === 0 && <ItemEmpty />}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

import { BookOpenCheck, ClockArrowDown, FileChartColumn } from "lucide-react";
import { useEffect, useState } from "react";
import useFirestore from "../../../hook/firestore";
import { where } from "firebase/firestore";
import { Shift } from "../../../Enum/shift";

export default function Stats() {
  const [balance, setBalance] = useState();
  const {
    calculateShiftEarnings,
    countDocumentsWithQuery,
    loading: loadNumber,
    callableFunction,
  } = useFirestore("shifts");
  const [number, setNumber] = useState({
    hour: 0,
    expense: 0,
    upcoming: 0,
  });

  const getBalance = async () => {
    const result = await callableFunction("getBalance");

    const { balance } = result;
    setBalance(balance);
  };

  const fetchSumTotalSpentHour = async () => {
    const result = await calculateShiftEarnings([where("paid", "==", true)], {
      earning: false,
      hour: true,
    });

    setNumber((prev) => ({
      ...prev,
      hour: result,
    }));
  };
  const fetchSumTotalExpense = async () => {
    const result = await calculateShiftEarnings([where("paid", "==", true)], {
      earning: true,
      hour: false,
    });

    setNumber((prev) => ({
      ...prev,
      expense: result,
    }));
  };

  const fetchCountShift = async () => {
    const result = await countDocumentsWithQuery([
      where("status", "==", Shift.CONFIRMED),
    ]);

    setNumber((prev) => ({
      ...prev,
      upcoming: result,
    }));
  };

  useEffect(() => {
    fetchSumTotalSpentHour();
    fetchSumTotalExpense();
    fetchCountShift();
    getBalance();
  }, []);

  return (
    <>
      <div className=" bg-white border rounded-md p-4">
        <h1 className="text-2xl font-medium text-sky-600">Balance</h1>
        <h1 className="mt-4 text-4xl font-bold uppercase inline-block bg-gradient-to-r from-indigo-800 text-transparent bg-clip-text to-green-600 ">
          {balance &&
            [balance.available[0].currency, balance.available[0].amount]
              .join(" ")
              .toUpperCase()}
        </h1>
        <div className="mt-8 grid sm:grid-cols-2 gap-3">
          <div className="  rounded-md p-3 bg-gray-100 ">
            <ClockArrowDown className="  text-sky-700 size-8" />

            <h1 className="mt-3 font-light   ">Total hours</h1>
            <h1 className="mt-1 font-bold text-xl text-sky-600 ">
              {number.hour} H
            </h1>
          </div>

          <div className=" bg-gray-100 rounded-md p-3 ">
            <BookOpenCheck className=" text-sky-700 size-8 " />
            <h1 className="mt-3 font-light ">Upcoming shift</h1>
            <h1 className="mt-1 font-bold text-xl text-sky-600">
              {number.upcoming}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}

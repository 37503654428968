import Footer from "./footer";
import Header from "./header";
import { Outlet, useLocation } from "react-router-dom";
import ModalRegistration from "./modal-registration";

export default function LayoutLanding() {
  const location = useLocation();
  return (
    <div className="bg-white scroll-smooth">
      {!["/login", "/registration", "/reset-password"].includes(
        location.pathname
      ) &&
        !window.ReactNativeWebView && <Header />}
      <Outlet />
      {!["/login", "/registration", "/reset-password"].includes(
        location.pathname
      ) &&
        !window.ReactNativeWebView && <Footer />}
      <ModalRegistration />
    </div>
  );
}

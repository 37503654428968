import React, { useEffect, useState } from "react";
import useFirestore from "../../../hook/firestore";
import { classNames } from "../../../components/utils/className";
import { Eye, MessageSquareMore } from "lucide-react";
import Details from "./Details";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { auth } from "../../../firebase";

function Item({ item }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { callableFunction } = useFirestore("shifts");
  const navigate = useNavigate();

  const createConversation = async () => {
    const id = auth.currentUser.uid;
    const result = await callableFunction("createConversationWithAdmin", {
      admin: id,
      user: item.id,
    });

    if (result) {
      navigate(`/messages`);
    }
  };

  return (
    <div
      className={classNames(
        "flex flex-wrap shrink-0 flex-col gap-4 overflow-hidden justify-between items-center border border-dashed p-4 rounded-lg",
        searchParams.get("id") === item.id && " border-indigo-800 "
      )}
    >
      <div className=" space-y-1">
        <h1 className="text-sm font-medium text-sky-800">{item.name}</h1>
        <p className="text-xs font-medium text-gray-800">{item.email}</p>
      </div>

      <button
        onClick={createConversation}
        className=" flex border px-6 py-1  gap-2 items-center justify-center  flex-col rounded-lg  hover:bg-gray-100 text-sky-800 "
      >
        <MessageSquareMore />
        <span className="text-xs font-semibold">Chat</span>
      </button>

      <div className=" flex justify-center mt-5  w-full flex-wrap gap-4 text-xs *:rounded-lg ">
        <div className="px-6  flex justify-center items-center font-semibold  bg-gray-50  text-gray-800">
          <span>{item.role}</span>
        </div>
        <div
          className={classNames(
            "px-6  flex justify-center items-center font-semibold",
            item.emailVerified
              ? "bg-green-50 text-green-800"
              : "bg-red-50 text-red-800"
          )}
        >
          <span>verified</span>
        </div>
        <div
          className={classNames(
            "px-6 flex justify-center items-center font-semibold ",
            item.visible
              ? "bg-green-50 text-green-800"
              : "bg-red-50 text-red-800"
          )}
        >
          <span>visible</span>
        </div>
        <Link
          to={`.?id=${item.id}&role=${item.role}`}
          className="px-6  flex justify-center items-center font-semibold bg-sky-50 text-sky-800"
        >
          <Eye />
        </Link>
      </div>
    </div>
  );
}
function ItemLoad() {
  return (
    <div className="flex flex-wrap gap-4 overflow-hidden justify-between items-center border p-4 rounded-lg">
      <div className=" space-y-2">
        <h1 className="text-sm font-medium  text-transparent animate-pulse bg-gray-100  rounded-lg   ">
          data name
        </h1>
        <p className="text-xs font-medium  text-transparent animate-pulse bg-gray-100  rounded-lg ">
          data for name
        </p>
      </div>

      <div className=" flex justify-end  w-full flex-wrap gap-4 text-sm *:rounded-lg ">
        <div className="p-2 font-medium text-transparent animate-pulse bg-gray-100  rounded-lg ">
          <span>data</span>
        </div>
        <div
          className={classNames(
            "p-2 text-transparent animate-pulse bg-gray-100  rounded-lg "
          )}
        >
          <span>verified</span>
        </div>
        <div
          className={classNames(
            "p-2 text-transparent animate-pulse bg-gray-100  rounded-lg "
          )}
        >
          <span>visible</span>
        </div>
        <div className="p-2 text-transparent animate-pulse bg-gray-100  rounded-lg ">
          <Eye />
        </div>
      </div>
    </div>
  );
}

function Users() {
  const { callableFunction, loading } = useFirestore("candidates");
  const [users, setUsers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState();

  const fetchUser = async () => {
    const result = await callableFunction(
      "retrieveUsersWithRoleAndCreationDate",
      {
        nameFilter: name || null,
      }
    );

    const { users } = result;

    setUsers(users);
  };

  useEffect(() => {
    fetchUser();
  }, [name]);

  return (
    <div className=" flex flex-col">
      <div className="px-4 text-sky-800 text-2xl">Users</div>
      <hr className="my-3" />
      <div className=" grid px-4  my-2">
        <div className=" bg-gray-100  rounded-lg ">
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder=" Search candidate , company ..."
            className=" w-full rounded-lg p-4 text-sm bg-gray-100 border-none"
          />
        </div>
      </div>
      <div className=" mt-4 grid md:grid-cols-12 divide-x">
        <div className=" col-span-5 px-3 h-[88vh] overflow-y-auto flex flex-col gap-4">
          {!loading && users && users.map((item) => <Item item={item} />)}
          {loading && [<ItemLoad />, <ItemLoad />, <ItemLoad />]}
        </div>

        <div className=" col-span-7 h-[88vh] overflow-y-auto p-4 items-center flex justify-center ">
          {searchParams.get("id") ? (
            <Details />
          ) : (
            <div>
              <span className="  animate-pulse text-lg text-sky-800 border border-dashed rounded-lg px-10 py-4">
                Select user
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Users;

import React from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./Dashboard";
import Jobs from "./Jobs";
import NotFound404 from "../../components/not-found-page/404-not-found";
import Upcoming from "./Upcoming";
import Availability from "./Availability";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Details from "./Jobs/details";
import { Company } from "../../components/details-user/company";
import Messages from "../../components/Messages";
import History from "./History";
import Index from "./Profile";
import Finance from "./Profile/Finance";
import NotificationsSetting from "./Profile/Notifications";
import AccountDetails from "./Profile/Account";
import DocumentID from "../../landing-page/document-id";
import { StripeDashboard } from "./Finance/stripe-dashbord";

function Router() {
  return <Outlet />;
}

function Candidate() {
  let location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} timeout={500} classNames="page">
        <Routes>
          <Route exact static path="/" element={<Router />}>
            <Route index element={<Dashboard />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/jobs/:id" element={<Details />} />
            <Route path="/upcoming" element={<Upcoming />} />
            <Route path="/availability" element={<Availability />} />
            <Route path="/history" element={<History />} />
            <Route path="/profile" element={<Index />} />
            <Route path="profile/finance" element={<Finance />} />
            <Route
              path="profile/notification-settings"
              element={<NotificationsSetting />}
            />

            <Route
              path="profile/account-details"
              element={<AccountDetails />}
            />
            <Route path="profile/document-id" element={<DocumentID />} />
            <Route path="profile/payouts" element={<StripeDashboard />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/company" element={<Company />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default Candidate;

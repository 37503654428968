import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { styleMap } from "../../../utils";
import { useDispatch } from "react-redux";
import { CandidateItem } from "./candidate";
import useFirestore from "../../../hook/firestore";
import useProfileUpdate from "../../../hook/company";

function Map({ home = false, candidate = false }) {
  const dispatch = useDispatch();
  const { readAllDocuments } = useFirestore("candidates");
  const { profile } = useProfileUpdate();
  const [candidates, setCandidates] = useState([]);

  const fetchAllCandidates = async () => {
    const result = await readAllDocuments();
    setCandidates(result);
  };

  useEffect(() => {
    fetchAllCandidates();
  }, [profile]);

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE,
  });

  return (
    <div className="h-full  w-full">
      {isLoaded && profile && (
        <GoogleMap
          mapContainerClassName=" size-full rounded-lg  text-sm relative"
          center={{
            lat: profile.location.latitude || 48.8582,
            lng: profile.location.longitude || 2.3387,
          }}
          zoom={14}
          options={{
            styles: styleMap,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          {candidates && candidate && (
            <div className=" w-full flex gap-3 absolute p-2   overflow-x-hidden hover:overflow-x-scroll bg-white/30 ">
              {candidates.map((item) => (
                <CandidateItem item={item} />
              ))}
            </div>
          )}

          {candidates
            .filter((el) => el.location?.latitude && el.location?.longitude)
            .map((candidate) => (
              <Marker
                key={candidate.id}
                onClick={() =>
                  dispatch({
                    type: "ApplicationSlice/setCandidateLaunch",
                    payload: candidate.id,
                  })
                }
                position={{
                  lat: candidate.location?.latitude || 0,
                  lng: candidate.location?.longitude || 0,
                }}
                title={candidate.name}
                icon={{
                  url: require("../../../assets/img/worker.png"),
                  scaledSize: {
                    width: 50,
                    height: 50,
                  },
                }}
              />
            ))}

          {profile && (
            <Marker
              position={{
                lat: profile.location.latitude || 48.8582,
                lng: profile.location.longitude || 2.3387,
              }}
              title={profile.name}
              icon={{
                url: require("../../../assets/img/company.png"),
                scaledSize: {
                  width: 50,
                  height: 50,
                },
              }}
            />
          )}
        </GoogleMap>
      )}
    </div>
  );
}

export default Map;

import { Eye, UsersRound } from "lucide-react";
import React, { useEffect, useState } from "react";
import useFirestore from "../../../hook/firestore";
import { classNames } from "../../../components/utils/className";
import { Link } from "react-router-dom";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";

function Item({ item }) {
  return (
    <div className="flex shrink-0 gap-4 overflow-hidden flex-col    border p-4 rounded-lg">
      <div>
        <h1 className="text-sm font-medium text-sky-800">{item.name}</h1>
        <p className="text-xs font-medium">{item.email}</p>
      </div>

      <div className=" flex justify-end  flex-wrap gap-4 text-sm *:rounded-lg ">
        <div className="p-2 bg-gray-50 font-medium text-gray-800">
          <span>{item.role}</span>
        </div>
        <div
          className={classNames(
            "p-2",
            item.emailVerified
              ? "bg-green-50 text-green-800"
              : "bg-red-50 text-red-800"
          )}
        >
          <span>verified</span>
        </div>
        <div
          className={classNames(
            "p-2",
            item.visible
              ? "bg-green-50 text-green-800"
              : "bg-red-50 text-red-800"
          )}
        >
          <span>visible</span>
        </div>
        <Link
          to={`users?id=${item.id}&role=${item.role}`}
          className="p-2 bg-sky-50 text-sky-800"
        >
          <Eye />
        </Link>
      </div>
    </div>
  );
}

function Users() {
  const { callableFunction, loading } = useFirestore("candidates");
  const [users, setUsers] = useState([]);
  const [name, setName] = useState();
  const fetchUser = async () => {
    const result = await callableFunction(
      "retrieveUsersWithRoleAndCreationDate",
      {
        nameFilter: name || null,
      }
    );

    const { users } = result;

    setUsers(users);
  };

  useEffect(() => {
    fetchUser();
  }, [name]);

  return (
    <div className=" my-4 bg-white shadow rounded-lg border p-4 border-dashed relative">
      {loading && <SpinnerLoadData />}
      <div className="flex gap-4 items-center text-sky-800">
        <UsersRound />
        <h1 className="text-sm font-semibold ">Users registred</h1>
      </div>
      <div className=" grid  md:grid-cols-2 gap-4 my-4 ">
        <input
          placeholder="name..."
          onChange={(e) => setName(e.target.value)}
          className={"border-none bg-gray-100 rounded-lg text-sm"}
          type="text"
        />

        <input
          className={"border-none bg-gray-100 rounded-lg text-sm"}
          type="date"
        />
      </div>
      <hr className="my-3" />
      <div className="flex flex-col gap-4 max-h-[30rem] overflow-y-scroll">
        {!loading && users && users.map((item) => <Item item={item} />)}
      </div>
    </div>
  );
}

export default Users;

import React, { useEffect, useState } from "react";
import Layout from "./apps/layout";
import { LandingRoute } from "./landing-page/Route";
import { useDispatch, useSelector } from "react-redux";
import {
  isTokenExpired,
  setIsConnected,
  setOwnerUID,
  setRole,
} from "./redux/authentication";
import Roles from "./Enum/UserRoles";
import LayoutAdmin from "./apps/admin/layout";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase";
import { getUserFromCollection } from "./functions/sign-in-up";
import useFirestore from "./hook/firestore";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

function DirectionRoute() {
  const { updateSubUser } = useFirestore("companies");
  const location = useLocation();
  const navigate = useNavigate();
  const { token, role, isConnected } = useSelector(
    (state) => state.authenticationSlice
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleAuthStateChanged = async (user) => {
      if (user) {
        const role_user = await getUserFromCollection(user.uid);
        dispatch(setRole(role_user.role));

        if ([Roles.member, Roles.manager].includes(role_user.role)) {
          dispatch(
            setOwnerUID({
              ...user,
              ...role_user,
              uid: role_user.companyId,
              displayName: role_user.displayName,
              photoURL: role_user.photoURL,
            })
          );

          updateSubUser(user.uid, {
            lastLogin: moment.utc().unix(),
          });
        }

        localStorage.setItem("isConnected", "true");
        dispatch(setIsConnected(true));
      } else {
        localStorage.removeItem("isConnected");
        dispatch(setIsConnected(false));
      }
      setIsLoading(false);
    };

    const isConnectedStored = localStorage.getItem("isConnected") === "true";
    dispatch(setIsConnected(isConnectedStored));

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    if (isConnected && location.pathname == "/login") {
      navigate("/");
    }
  }, [isConnected]);

  if (isLoading) {
    return null; // ou un composant de chargement si vous en avez un
  }

  const componentsArray = [
    {
      condition: [
        Roles.candidate,
        Roles.company,
        Roles.member,
        Roles.manager,
      ].includes(role),
      component: <Layout />,
    },
    { condition: [Roles.admin].includes(role), component: <LayoutAdmin /> },
  ];

  const selectedComponent = componentsArray.find((item) => item.condition)
    ?.component || <LandingRoute />;

  return isConnected ? selectedComponent : <LandingRoute />;
}

export default DirectionRoute;

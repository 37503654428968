import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import useFirestore from "../../hook/firestore";

export default function DropdownSkills({ selected, handleCheckboxChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [skills, setSkills] = useState([]);
  const { readDocument } = useFirestore("industries");
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selected) {
      (async () => {
        await readDocument(selected.sector).then((elements) =>
          setSkills(elements.skills)
        );
      })();
    }
  }, [selected?.sector]);

  console.log(skills);
  return (
    <Menu
      as="div"
      className="relative inline-block text-left w-full  "
      ref={menuRef}
    >
      <div>
        <Menu.Button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex justify-between w-full text-sky-900   rounded-md bg-gray-100  p-4  text-sm font-semibold "
        >
          Skills
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          static
          className="absolute right-0 z-30 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full max-h-40 overflow-scroll"
        >
          <div className="py-1">
            {skills &&
              skills.map((el, index) => (
                <Menu.Item key={index}>
                  <div className="flex items-center pl-3">
                    <input
                      id="react-checkbox"
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      value={el}
                      name="skill"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="react-checkbox"
                      className="w-full py-3 ml-2 text-xs md:text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {el}
                    </label>
                  </div>
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

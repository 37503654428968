import React, { useEffect, useState } from "react";
import useFirestore from "../../hook/firestore";
import { auth, db } from "../../firebase";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "../utils/className";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useSelector } from "react-redux";
import Roles from "../../Enum/UserRoles";

function MessagesIcon() {
  const { countUnreadConversations } = useFirestore("conversations");
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;
  const isCandidate = role === Roles.candidate;
  const isAdmin = role === Roles.admin;
  const [count, setCount] = useState();

  const fetchCount = async () => {
    const result = await countUnreadConversations(
      currentUser.uid,
      role.toLowerCase()
    );
    setCount(result);
  };

  useEffect(() => {
    fetchCount();

    const conversationRef = collection(db, "conversations");

    const q = query(
      conversationRef,
      where(
        !isAdmin ? (isCandidate ? "candidate" : "company") : "admin",
        "==",
        currentUser.uid
      )
    );

    // Mise en place de l'écoute des changements en temps réel
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        fetchCount();
      },
      (err) => {
        console.error(err);
      }
    );

    // Nettoyage : désinscrire l'écoute lorsqu'on démonte le composant
    return () => unsubscribe();
  }, [currentUser.uid]);

  return (
    <div className="indicator">
      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-red-600">
        {count || 0}
      </span>
      <ChatBubbleLeftRightIcon
        className={classNames(
          "text-gray-400 group-hover:text-sky-600",
          "h-6 w-6 shrink-0"
        )}
        aria-hidden="true"
      />
    </div>
  );
}

export default MessagesIcon;

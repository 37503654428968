import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SuccessAlert, { ErrorAlert } from "../../../components/alert/alerts";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import useProfileUpdate from "../../../hook/candidate";
import useCurrencies from "../../../hook/currency";
import { openDeleteModal } from "../../../redux/users";
import GenericImg from "../../../components/layout/generic-user-img";
import { Description, Field, Label, Switch } from "@headlessui/react";
import ModalEmail from "../../../components/modal/modalEmail";
import useFirestore from "../../../hook/firestore";
import { auth } from "../../../firebase";

function AccountDetails() {
  const {
    profile,
    load,
    requestStatus,
    handleSave,
    handleSavePassword,
    formData,
    location,
    handleChange,
    password,
    handleChangePassword,
    handleChangeLocation,
  } = useProfileUpdate();

  const dispatch = useDispatch();
  const { callableFunction, loading } = useFirestore("mail");
  const currencies = useCurrencies();
  const [file, setFile] = useState(null);
  const classNames = (...classes) => classes.filter(Boolean).join(" ");

  const handleChecked = (e) => {
    const params = {
      target: {
        name: "hot_job",
        value: e,
      },
    };

    handleChange(params);
  };

  const [open, setOpen] = useState(false);

  const handleCheckEmail = async () => {
    await callableFunction("sendEmailVerificationCode", {
      email: profile.email,
      name: profile.name,
    }).then(() => setOpen(true));
  };

  return (
    <>
      <div className="p-4">
        <div
          onClick={() => window.history.back()}
          className="flex w-fit justify-start gap-4 text-sky-900  items-center  cursor-pointer "
        >
          <span>
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              className="size-5 "
              color="currentColor"
            >
              <g id="_4" data-name="4">
                <path d="m492 276h-472a20 20 0 0 1 0-40h472a20 20 0 0 1 0 40z" />
                <path d="m189 445a19.937 19.937 0 0 1 -14.142-5.858l-169-169a20 20 0 0 1 0-28.284l169-169a20 20 0 0 1 28.284 28.284l-154.858 154.858 154.858 154.858a20 20 0 0 1 -14.142 34.142z" />
              </g>
            </svg>
          </span>
          <span>Account Details</span>
        </div>

        <hr className="my-5" />
        <div className=" grid  sm:grid-cols-2 xl:grid-cols-3 gap-4 ">
          <div className=" relative p-4 border flex flex-col items-center gap-8 rounded-lg">
            {!load && <SpinnerLoadData />}
            {loading && <SpinnerLoadData />}
            <div className=" flex flex-col items-center justify-center">
              <h1 className="text-sm font-semibold">{formData.name}</h1>
              <p className="text-xs">{formData.title}</p>
            </div>

            <div className=" size-20  overflow-hidden rounded-full bg-gray-100">
              {(file && URL.createObjectURL(file)) || formData.avatarUrl ? (
                <img
                  src={
                    (file && URL.createObjectURL(file)) || formData.avatarUrl
                  }
                  alt="avatar"
                  className="size-full object-cover rounded-full"
                />
              ) : (
                <GenericImg />
              )}
            </div>

            <div className="flex items-center">
              <button
                type="button"
                onClick={(e) => e.currentTarget.nextElementSibling.click()}
                className="rounded-md flex items-center gap-3 bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
                Change avatar
              </button>
              <input
                className="hidden"
                onChange={(e) => setFile(e.target.files[0])}
                type="file"
                accept="image/*"
              />
            </div>

            <div className=" grid w-full sm:grid-cols-2  gap-4 *:rounded-lg">
              <input
                type="text"
                placeholder="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className={classNames(
                  " p-2 col-span-full text-sm border-none   bg-gray-100"
                )}
              />

              <input
                placeholder="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className={classNames(
                  " p-2 col-span-full text-sm border-none   bg-gray-100"
                )}
              />

              <input
                placeholder="Email"
                name="email"
                value={formData.email}
                disabled
                onChange={handleChange}
                className={classNames(
                  " p-2 col-span-full text-sm border-none disabled:bg-gray-100 disabled:cursor-not-allowed"
                )}
              />

              {!auth.currentUser.emailVerified && (
                <button
                  onClick={handleCheckEmail}
                  className=" bg-red-50 animate-pulse text-red-800 text-sm p-2  col-span-full"
                >
                  Verify email
                </button>
              )}
            </div>

            <div className="w-full flex justify-end mt-auto">
              <button
                type="button"
                onClick={() => handleSave(file)}
                className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Save
              </button>
            </div>
          </div>

          <div className=" relative  p-4 border flex flex-col items-center gap-8 rounded-lg">
            {!load && <SpinnerLoadData />}
            <h1 className="text-sm  font-semibold">Job Preference</h1>

            <div className=" grid w-full   gap-4 *:rounded-lg">
              <div className="flex flex-col bg-gray-100  p-4 justify-end items-end space-y-6">
                <h1 className="text-xs  w-full text-gray-500 justify-start">
                  Hourly Rate
                </h1>
                <select
                  id="currency"
                  onChange={handleChange}
                  name="currency"
                  className="h-full rounded-md border-0 w-full bg-transparent py-0 pl-2 pr-7  text-gray-500 focus:ring-2  font-semibold focus:ring-inset focus:ring-sky-600 text-sm"
                >
                  {currencies &&
                    currencies.map((val) => (
                      <option
                        selected={profile?.currency?.code === val.code}
                        value={val.code}
                      >
                        {val.code}
                      </option>
                    ))}
                </select>

                <input
                  id="default-range"
                  type="range"
                  step={0.05}
                  value={formData.hourlyRate || 10}
                  onChange={handleChange}
                  name="hourlyRate"
                  required
                  class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                />

                {/* currency */}
                <span className="p-2 text-sm w-full flex justify-center rounded-lg bg-sky-900  text-gray-100">
                  {[formData.currency?.code, formData.hourlyRate || 10].join(
                    " "
                  )}
                </span>
              </div>
              {/* radius */}
              <div className="flex flex-col bg-gray-100  p-4 justify-end items-end space-y-6">
                <h1 className="text-xs  w-full text-gray-500 justify-start">
                  Prefered Distance To Work
                </h1>
                <input
                  id="default-range"
                  type="range"
                  name="radius"
                  value={formData.radius || 10}
                  onChange={handleChange}
                  step={0.05}
                  required
                  class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                />
                {/* km away */}
                <span className="p-2 text-sm w-full flex justify-center rounded-lg bg-sky-900  text-gray-100">
                  {formData.radius || 10} Km away
                </span>
              </div>
            </div>

            <div className="w-full flex justify-end">
              <button
                type="button"
                onClick={() => handleSave(file)}
                className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Save
              </button>
            </div>
          </div>

          <div className="relative  p-4 border flex flex-col items-center gap-8 rounded-lg">
            {!load && <SpinnerLoadData />}
            <h1 className="text-sm  font-semibold">Home Location</h1>

            <div className=" grid w-full   gap-4 *:rounded-lg">
              <select
                id="country"
                name="country"
                onChange={handleChange}
                required
                className=" p-2 text-sm border-none   bg-gray-100"
              >
                <option
                  selected={!["UK", "KSA", "UAR"].includes(formData.country)}
                  disabled
                >
                  Country
                </option>
                <option selected={formData.country === "UK"} value={"UK"}>
                  {" "}
                  United Kingdom{" "}
                </option>
                <option selected={formData.country === "KSA"} value={"KSA"}>
                  Kingdom of Saudi Arabia{" "}
                </option>
                <option selected={formData.country === "UAE"} value={"UAE"}>
                  United Arab Emirates{" "}
                </option>
              </select>

              <input
                placeholder="Street"
                name="street"
                value={location.street}
                onChange={handleChangeLocation}
                className={classNames(
                  " p-2 col-span-full text-sm border-none   bg-gray-100",
                  !load && "opacity-20 ease-in-out animate-pulse"
                )}
              />
              <input
                placeholder="City"
                value={location.city}
                onChange={handleChangeLocation}
                name="city"
                className={classNames(
                  " p-2 col-span-full text-sm border-none   bg-gray-100"
                )}
              />

              <input
                placeholder="State"
                onChange={handleChangeLocation}
                name="state"
                value={location.state}
                className=" p-2 text-sm border-none   bg-gray-100"
              />
              <input
                placeholder="Zip"
                value={location.zip}
                name="zip"
                onChange={handleChangeLocation}
                className={classNames(
                  " p-2 col-span-full text-sm border-none   bg-gray-100"
                )}
              />
            </div>

            <div className="w-full mt-auto flex justify-end">
              <button
                type="button"
                onClick={() => handleSave(file)}
                className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Save
              </button>
            </div>
          </div>
          <div className="  relative p-4 border flex flex-col items-center gap-8 rounded-lg">
            {!load && <SpinnerLoadData />}
            <h1 className="text-sm  font-semibold">Job Emergency</h1>
            <div className="flex justify-center items-center">
              <Field className="flex flex-col gap-8 items-center justify-between">
                <span className="flex flex-grow flex-col">
                  <Label
                    as="span"
                    passive
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Enable hot jobs
                  </Label>
                  <Description as="span" className="text-sm text-gray-500">
                    See prior all jobs that require to apply with emergency
                  </Description>
                </span>
                <Switch
                  onChange={handleChecked}
                  checked={formData.hot_job}
                  className="group relative inline-flex h-10 w-20 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2 data-[checked]:bg-orange-600"
                >
                  <span
                    aria-hidden="true"
                    className="pointer-events-none text-orange-600 inline-flex size-9 transform rounded-full justify-center items-center bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className={classNames(
                        "size-6",
                        formData.hot_job && " animate-pulse"
                      )}
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.963 2.286a.75.75 0 0 0-1.071-.136 9.742 9.742 0 0 0-3.539 6.176 7.547 7.547 0 0 1-1.705-1.715.75.75 0 0 0-1.152-.082A9 9 0 1 0 15.68 4.534a7.46 7.46 0 0 1-2.717-2.248ZM15.75 14.25a3.75 3.75 0 1 1-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 0 1 1.925-3.546 3.75 3.75 0 0 1 3.255 3.718Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </Switch>
              </Field>
            </div>
            <div className="w-full mt-auto flex justify-end">
              <button
                onClick={() => handleSave(file)}
                className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Save
              </button>
            </div>
          </div>
          <form
            onSubmit={handleSavePassword}
            className="  relative p-4 border flex flex-col items-center gap-8 rounded-lg"
          >
            {!load && <SpinnerLoadData />}
            <h1 className="text-sm  font-semibold">Change password</h1>
            <SuccessAlert show={requestStatus.success} />
            <ErrorAlert
              show={requestStatus.error}
              content={requestStatus.content}
            />
            <div className=" grid w-full   gap-4 *:rounded-lg">
              <input
                type="password"
                placeholder="Current password"
                name="oldPassword"
                required
                value={password.oldPassword}
                onChange={handleChangePassword}
                className={classNames(
                  " p-2 col-span-full text-sm border-none   bg-gray-100"
                )}
              />

              <input
                placeholder="New password"
                type="password"
                onChange={handleChangePassword}
                value={password.newPassword}
                required
                name="newPassword"
                className={classNames(
                  " p-2 col-span-full text-sm    bg-gray-100",
                  requestStatus.error ? "border border-red-600" : "border-none"
                )}
              />
              <input
                placeholder="Confirm password"
                required
                type="password"
                onChange={handleChangePassword}
                value={password.confirmPassword}
                name="confirmPassword"
                className={classNames(
                  " p-2 col-span-full text-sm    bg-gray-100",

                  requestStatus.error ? "border border-red-600" : "border-none"
                )}
              />
            </div>

            <div className="w-full mt-auto flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              >
                Save
              </button>
            </div>
          </form>
          <div className="  p-4 border flex flex-col items-center gap-8 rounded-lg">
            <h1 className="text-sm  font-semibold text-red-600">
              Delete account{" "}
            </h1>

            <p className="mt-1 text-sm text-center leading-6 text-gray-600">
              No longer want to use our service? You can delete your account
              here. This action is not reversible. All information related to
              this account will be deleted permanently.
            </p>

            <div className="w-full mt-auto  flex justify-center">
              <button
                type="button"
                onClick={() => dispatch(openDeleteModal())}
                className="rounded-md w-full bg-red-50 px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
              >
                Delete my account
              </button>
            </div>
          </div>
        </div>
      </div>
      {profile && (
        <ModalEmail email={profile.email} open={open} setOpen={setOpen} />
      )}
    </>
  );
}

export default AccountDetails;

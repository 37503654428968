import {
  BookmarkIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LatestApplicationIcon from "./Latest-applicationIcon";
import MessagesIcon from "./messagesIcon";

function BottomMobilCompany({ setSidebarOpen }) {
  const dispatch = useDispatch();
  const { countNotification, countMessages } = useSelector(
    (state) => state.conversationSlice
  );

  return (
    <div class="fixed z-[10000000000000000000] lg:hidden w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-1 left-1/2 dark:bg-gray-700 dark:border-gray-600">
      <div class="grid h-full max-w-lg grid-cols-5 mx-auto">
        <Link
          to={"/"}
          data-tooltip-target="tooltip-home"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </Link>
        <button
          data-tooltip-target="tooltip-wallet"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <a
            onClick={() => {
              setSidebarOpen(false);
              dispatch({
                type: "ApplicationSlice/setApplicationLaunch",
              });
            }}
            class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
          >
            <LatestApplicationIcon />
          </a>
        </button>

        <div class="flex items-center justify-center">
          <Link
            to={"/job/create"}
            data-tooltip-target="tooltip-new"
            type="button"
            class="inline-flex items-center justify-center w-10 h-10 font-medium bg-sky-600 rounded-full hover:bg-sky-700 group focus:ring-4 focus:ring-sky-300 focus:outline-none dark:focus:ring-sky-800"
          >
            <svg
              class="w-4 h-4 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 1v16M1 9h16"
              />
            </svg>
          </Link>
        </div>

        <button
          data-tooltip-target="tooltip-settings"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <Link
            to={"/messages"}
            onClick={() => {
              setSidebarOpen(false);
            }}
            class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
          >
            <MessagesIcon />
          </Link>
        </button>

        <Link
          to={"/shift"}
          data-tooltip-target="tooltip-profile"
          type="button"
          class="inline-flex flex-col items-center justify-center px-5 rounded-r-full hover:bg-gray-50 dark:hover:bg-gray-800 group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            class="w-7 h-7 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}

export default BottomMobilCompany;

import React, { useEffect, useState } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import useProfileUpdate from "../hook/candidate";
import { FilePreview } from "../components/utils/preview";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, db, storage } from "../firebase";
import { SpinnerLoadData } from "../components/utils/spinner-load";
import useFirestore from "../hook/firestore";
import { doc, onSnapshot } from "firebase/firestore";

function DocumentID() {
  const {
    handleChangeDocumentID,
    handleSaveDocument,
    load,
    setDocumentID,
    documentID,
    setLoad,
  } = useProfileUpdate();

  const { readDocument } = useFirestore("documents_ID");

  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleSaveDocument(file);
  };

  useEffect(() => {
    const uid = auth.currentUser.uid;
    const docRef = doc(db, "documents_ID", uid);

    const fetchDocument = async () => {
      setLoad(false);
      const documentID = await readDocument(uid);
      setDocumentID(documentID);
      setLoad(true);
    };

    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        fetchDocument();
      } else {
        console.log("No such document!");
      }
    });

    if (uid) {
      fetchDocument();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe(); // Detach the listener when the component unmounts
      }
    };
  }, [db]);

  console.log(documentID);

  return (
    <form onSubmit={handleSubmit} className="  flex justify-center   ">
      <div className=" border p-4 rounded-lg mt-10 relative max-w-md">
        {!load && <SpinnerLoadData />}
        <div
          onClick={() => window.history.back()}
          className=" cursor-pointer w-fit flex gap-4 items-center text-lg font-semibold text-sky-900"
        >
          <span>
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              className="size-5 "
              color="currentColor"
            >
              <g id="_4" data-name="4">
                <path d="m492 276h-472a20 20 0 0 1 0-40h472a20 20 0 0 1 0 40z" />
                <path d="m189 445a19.937 19.937 0 0 1 -14.142-5.858l-169-169a20 20 0 0 1 0-28.284l169-169a20 20 0 0 1 28.284 28.284l-154.858 154.858 154.858 154.858a20 20 0 0 1 -14.142 34.142z" />
              </g>
            </svg>
          </span>{" "}
          <span>Document ID</span>
        </div>
        <div class="inline-flex items-center justify-center text-sm font-semibold text-sky-900 relative w-full">
          <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
          <div class="absolute px-4  -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
            Document Number
            <span className="text-red-600 after:content-['*']"></span>
          </div>
        </div>
        <div className=" flex  flex-col items-center justify-center md:min-w-96 ">
          <div className="w-full">
            <div className="relative  rounded-md shadow-sm">
              <input
                type="text"
                name="documentNumber"
                value={documentID?.documentNumber}
                onChange={handleChangeDocumentID}
                required
                autoCapitalize="characters"
                className=" p-2 text-sm border-none  rounded-lg w-full  bg-gray-100"
                placeholder="ABC00000"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <QuestionMarkCircleIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div class="inline-flex items-center justify-center text-sm font-semibold text-sky-900 relative w-full">
            <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
            <div class="absolute px-4  -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
              Issue Date
              <span className="text-red-600 after:content-['*']"></span>
            </div>
          </div>
          <div className="w-full">
            <input
              type="date"
              name="issueDate"
              value={documentID?.issueDate}
              onChange={handleChangeDocumentID}
              required
              id="date-of-issue"
              className=" p-2 text-sm border-none w-full rounded-lg  bg-gray-100"
              placeholder={moment("YYYY-MM-DD")}
            />
          </div>
          <div class="inline-flex items-center justify-center text-sm font-semibold text-sky-900 relative w-full">
            <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
            <div class="absolute px-4  -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
              Expiry Date
              <span className="text-red-600 after:content-['*']"></span>
            </div>
          </div>

          <div className="w-full">
            <input
              type="date"
              required
              name="expiryDate"
              value={documentID?.expiryDate}
              id="expiry-date"
              onChange={handleChangeDocumentID}
              className=" p-2 text-sm border-none  w-full rounded-lg  bg-gray-100"
              placeholder={moment("YYYY-MM-DD")}
            />
          </div>
          <div class="inline-flex items-center justify-center text-sm font-semibold text-sky-900 relative w-full">
            <hr class="w-full h-1 my-8 bg-gray-200 border-0 rounded dark:bg-gray-700" />
            <div class="absolute px-4  -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
              Document File
              <span className="text-red-600 after:content-['*']"></span>
            </div>
          </div>
          <div className=" w-full">
            <div className=" flex justify-center flex-col rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              {documentID && documentID.status === "not-allowed" && (
                <p className=" bg-orange-50 text-orange-700 rounded-lg border-dashed border text-sm p-2">
                  {documentID?.error?.description}
                </p>
              )}
              <div className="text-center">
                <div className="mt-4 flex text-sm justify-center items-center leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-900 focus-within:outline-none focus-within:ring-2 focus-within:ring-sky-600 focus-within:ring-offset-2 hover:text-sky-500"
                  >
                    {false ? (
                      <span>Upload a file</span>
                    ) : (
                      <span>Upload a new file ?</span>
                    )}

                    <input
                      id="file-upload"
                      name="filepath"
                      onChange={(e) => setFile(e.target.files[0])}
                      type="file"
                      accept=".jpg, .jpeg, .png, .gif, .pdf"
                      className="sr-only"
                    />
                  </label>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  PNG, JPG, GIF up to 10MB
                </p>

                <h1 className="px-4 py-2 bg-gray-100 rounded-lg text-sky-900 text-sm my-4">
                  {file?.name || documentID?.document?.name}
                </h1>

                <FilePreview
                  fileType={file?.type || documentID?.document?.type}
                  filePath={
                    (file && URL.createObjectURL(file)) ||
                    documentID?.document?.url
                  }
                />
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end mt-6">
            <button
              type="submit"
              className="rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default DocumentID;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useIndustries from "../../../hook/industries";
import GoBack from "../../../components/utils/goBack";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import ItemEmpty from "../../../components/utils/empty";
import { ArrowUp10, ClipboardMinus, ReplaceAll } from "lucide-react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

function ItemSubSector({ item }) {
  const { deleteSubsector } = useIndustries("industries");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDelete = async () => {
    await deleteSubsector(searchParams.get("id"), item.id);
  };

  return (
    <div className=" p-4 bg-gray-100 rounded-lg flex justify-between ">
      <h1 className="text-sm text-sky-800 font-medium">{item.name}</h1>
      <a onClick={handleDelete} className="cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5 hover:text-red-500 text-red-700"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
          />
        </svg>
      </a>
    </div>
  );
}
function ItemSkills({ item, index }) {
  const { deleteSkill } = useIndustries("industries");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDelete = async () => {
    await deleteSkill(searchParams.get("id"), index);
  };

  return (
    <div className=" p-4 bg-gray-100 rounded-lg flex justify-between">
      <h1 className="text-sm text-sky-800 font-medium">{item}</h1>
      <a onClick={handleDelete} className="cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5 hover:text-red-500 text-red-700"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
          />
        </svg>
      </a>
    </div>
  );
}

function SubSectorView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { readSubsectorsByIndustry, addSubsector, loading } =
    useIndustries("industries");
  const [subsectors, setSubSectors] = useState([]);
  const [newSubsector, setNewSubsector] = useState("");
  const fetchSubIndustries = async () => {
    const id = searchParams.get("id");
    const result = await readSubsectorsByIndustry(id);
    setSubSectors(result);
  };

  useEffect(() => {
    fetchSubIndustries();

    const unsubscribe = onSnapshot(query(collection(db, "industries")), () => {
      fetchSubIndustries();
    });

    return () => {
      unsubscribe();
    };
  }, [searchParams.get("id")]);

  const handleAddSubsector = async (e) => {
    e.preventDefault();
    const id = searchParams.get("id");
    if (!newSubsector) return;

    await addSubsector(id, { name: newSubsector });
    setNewSubsector("");
    // Refresh subsectors list
    const updatedSubsectors = await readSubsectorsByIndustry(id);
    setSubSectors(updatedSubsectors);
  };

  return (
    <div className="size-full ">
      {loading && <SpinnerLoadData />}
      <div className=" grid  gap-4">
        <div className=" inline-flex justify-between items-center gap-4 bg-gray-100  font-semibold p-2 px-6 rounded-lg">
          <div>
            <GoBack />
          </div>{" "}
          <h1 className="text-sm text-gray-700">Sub sectors</h1>
        </div>
      </div>

      <hr className="my-4" />

      <div className="flex flex-col px-3 gap-3 h-[90%] overflow-y-scroll">
        {subsectors.map((item) => (
          <ItemSubSector item={item} />
        ))}

        {subsectors.length === 0 && <ItemEmpty />}
      </div>

      <form
        onSubmit={handleAddSubsector}
        className=" bg-gray-100 flex  items-center  absolute w-full left-0 p-4 rounded-b-lg bottom-0"
      >
        <input
          type="text"
          value={newSubsector}
          onChange={(e) => setNewSubsector(e.target.value)}
          placeholder="sub sector"
          className="w-full  p-4 border-none text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className=" border-none absolute right-6 text-sm   bg-sky-600 text-sky-50 hover:bg-sky-800 rounded-lg p-2 font-semibold px-8"
        >
          Add more
        </button>
      </form>
    </div>
  );
}
function SkillView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { readSkillsByIndustry, addSkill, loading } =
    useIndustries("industries");
  const [newSkill, setNewSkill] = useState();
  const [skills, setskills] = useState([]);
  const fetchSubIndustries = async () => {
    const id = searchParams.get("id");
    const result = await readSkillsByIndustry(id);
    setskills(result);
  };

  const handleAddSubsector = async (e) => {
    e.preventDefault();
    const id = searchParams.get("id");
    if (!newSkill) return;

    await addSkill(id, newSkill);
    setNewSkill("");
    // Refresh subsectors list
    const updatedSkill = await readSkillsByIndustry(id);
    setskills(updatedSkill);
  };

  useEffect(() => {
    fetchSubIndustries();

    const unsubscribe = onSnapshot(query(collection(db, "industries")), () => {
      fetchSubIndustries();
    });

    return () => {
      unsubscribe();
    };
  }, [searchParams.get("id")]);

  return (
    <div className=" size-full ">
      {loading && <SpinnerLoadData />}
      <div className=" grid  gap-4">
        <div className=" inline-flex justify-between items-center gap-4 bg-gray-100  font-semibold p-2 px-6 rounded-lg">
          <div>
            <GoBack />
          </div>{" "}
          <h1 className="text-sm text-gray-700">Skills</h1>
        </div>
      </div>

      <hr className="my-4" />

      <div className="flex flex-col px-3 gap-3 h-[90%] overflow-y-scroll">
        {skills.map((item, index) => (
          <ItemSkills item={item} index={index} />
        ))}
        {skills.length === 0 && <ItemEmpty />}
      </div>

      <form
        onSubmit={handleAddSubsector}
        className=" bg-gray-100 flex  items-center  absolute w-full left-0 p-4 rounded-b-lg bottom-0"
      >
        <input
          type="text"
          value={newSkill}
          onChange={(e) => setNewSkill(e.target.value)}
          placeholder="skill"
          className="w-full  p-4 border-none text-sm border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className=" border-none absolute right-6 text-sm   bg-sky-600 text-sky-50 hover:bg-sky-800 rounded-lg p-2 font-semibold px-8"
        >
          Add more
        </button>
      </form>
    </div>
  );
}

function ChooseSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { deleteIndustry, loading } = useIndustries("industries");

  const handleDeleteIndustry = async () => {
    const cf = window.confirm(
      `Confirm removing of ${searchParams.get("id")} ?`
    );

    if (cf) {
      await deleteIndustry(searchParams.get("id")).then(() => {
        setSearchParams({});
      });
    }
  };

  return (
    <div className=" size-full flex justify-center  flex-col gap-8 items-center ">
      {loading && <SpinnerLoadData />}
      <h1 className=" text-xl  font-semibold">{searchParams.get("id")}</h1>
      <div className=" h-52   grid grid-cols-2 w-full gap-4">
        <button
          onClick={() =>
            setSearchParams({
              id: searchParams.get("id"),
              cs: "sub_sectors",
            })
          }
          className=" hover:underline gap-3 flex justify-center items-center text-sky-800 bg-gray-100 hover:bg-gray-200 border-dashed rounded-lg p-4 "
        >
          <ReplaceAll />
          <span>Sub sectors</span>
        </button>
        <button
          onClick={() =>
            setSearchParams({
              id: searchParams.get("id"),
              cs: "skills",
            })
          }
          className=" gap-3 flex hover:underline justify-center items-center text-sky-800 bg-gray-100 hover:bg-gray-200 border-dashed rounded-lg p-4 "
        >
          <ArrowUp10 />
          <span> Skills</span>
        </button>
        <button
          onClick={handleDeleteIndustry}
          className="  gap-3 hover:underline col-span-full flex justify-center items-center text-red-800 bg-red-100 hover:bg-red-200 border-dashed rounded-lg p-4 "
        >
          <ClipboardMinus /> <span>Delete</span>
        </button>
      </div>
    </div>
  );
}

function Details() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className=" border rounded-lg size-full p-4 pb-24 relative">
      {searchParams.get("cs") === "sub_sectors" ? (
        <SubSectorView />
      ) : searchParams.get("cs") === "skills" ? (
        <SkillView />
      ) : searchParams.get("id") ? (
        <ChooseSection />
      ) : (
        <div className=" size-full flex justify-center items-center">
          <span className=" text-sm p-8 py-3 border rounded-lg  font-semibold text-sky-800 animate-pulse">
            Select industry
          </span>
        </div>
      )}
    </div>
  );
}

export default Details;

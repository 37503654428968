export default class Notification {
  static INCOMMING = "INCOMMING";
  static NEED_RECONF = "NEED_RECONFIRMATION";
  static RECONFIRMED = "RECONFIRMED_APPLICATION";
  static NOT_RECONFIRMED = "NOT_RECONFIRMED";
  static INVITE = "INVITE_APPLY";
  static RATE_EMPLOYER = "RATE_EMPLOYER";
  static NEW_APPLICATION = "NEW_APPLICATION";
  static REJECTED = "REJECTED_APPLICATION";
  static PAYMENT_REFUSED = "PAYMENT_REFUSED";
  static RATE = "RATE";

  static TYPE = (msg) => {
    switch (msg) {
      case this.INCOMMING:
        return "Incoming";
      case this.NEED_RECONF:
        return "Reconfirm your position !";
      case this.RECONFIRMED:
        return "Application reconfirmed";
      case this.NOT_RECONFIRMED:
        return "Not reconfirmed";
      case this.INVITE:
        return "You're invited to apply.";
      case this.NEW_APPLICATION:
        return "You've received a new application !";
      case this.REJECTED:
        return "Application not accepted.";
      case this.RATE_EMPLOYER:
        return "Rate your shift.";
      case this.PAYMENT_REFUSED:
        return "We can't charge your credit card, please check your credit card details.";
      default:
        return "Unknown notification type.";
    }
  };
}

import { useState } from "react";
import {
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  writeBatch,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase"; // Importez votre instance Firestore

const useIndustries = (collectionName) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // CRUD Operations for Industries

  const createIndustry = async (industryData, subsectors = [], skills = []) => {
    setLoading(true);
    try {
      const industryRef = doc(db, collectionName, industryData.name); // Utilisation de industryData.name comme ID

      // Créer le document avec le nom comme ID
      await setDoc(industryRef, industryData);

      // Ajouter les sous-secteurs
      if (subsectors.length > 0) {
        const subsectorsCollectionRef = collection(
          db,
          `${collectionName}/${industryData.name}/subsectors`
        );
        const batch = writeBatch(db);
        subsectors.forEach((subsector) => {
          const subsectorDocRef = doc(subsectorsCollectionRef);
          batch.set(subsectorDocRef, { name: subsector });
        });
        await batch.commit();
      }

      // Ajouter les compétences
      if (skills.length > 0) {
        await updateDoc(industryRef, { skills });
      }

      setLoading(false);
      return industryData.name; // Retourne l'ID qui est maintenant le nom de l'industrie
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const readAllIndustries = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const industries = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLoading(false);
      return industries;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const readIndustryById = async (industryId) => {
    setLoading(true);
    try {
      const industryRef = doc(db, collectionName, industryId);
      const industrySnap = await getDoc(industryRef);
      if (industrySnap.exists()) {
        setLoading(false);
        return { id: industrySnap.id, ...industrySnap.data() };
      } else {
        setLoading(false);
        throw new Error("No such industry found");
      }
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  const updateIndustry = async (
    industryId,
    industryData,
    subsectors = [],
    skills = []
  ) => {
    setLoading(true);
    try {
      const industryRef = doc(db, collectionName, industryId);
      await updateDoc(industryRef, industryData);

      // Update subsectors
      if (subsectors.length > 0) {
        const subsectorsCollectionRef = collection(
          db,
          `${collectionName}/${industryId}/subsectors`
        );
        const batch = writeBatch(db);

        const subsectorDocs = await getDocs(subsectorsCollectionRef);
        subsectorDocs.forEach((doc) => {
          batch.delete(doc.ref);
        });

        subsectors.forEach((subsector) => {
          const subsectorDocRef = doc(subsectorsCollectionRef);
          batch.set(subsectorDocRef, { name: subsector });
        });
        await batch.commit();
      }

      // Update skills
      if (skills.length > 0) {
        await updateDoc(industryRef, { skills });
      }

      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const deleteIndustry = async (industryId) => {
    setLoading(true);
    try {
      const industryRef = doc(db, collectionName, industryId);
      const subsectorsCollectionRef = collection(
        db,
        `${collectionName}/${industryId}/subsectors`
      );

      // Delete subsectors first
      const subsectorDocs = await getDocs(subsectorsCollectionRef);
      const batch = writeBatch(db);
      subsectorDocs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // Now delete the industry
      await deleteDoc(industryRef);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  // CRUD Operations for Sub-Sectors with id, name, and skills
  const addSubsector = async (industryId, subsectorData) => {
    setLoading(true);
    try {
      const subsectorsCollectionRef = collection(
        db,
        `${collectionName}/${industryId}/subsectors`
      );

      // Ajouter le sous-secteur et récupérer le document ID généré par Firebase
      const docRef = await addDoc(subsectorsCollectionRef, {
        name: subsectorData.name,
      });

      // Mettre à jour le document avec son propre ID dans le champ id
      await updateDoc(docRef, { id: docRef.id });

      setLoading(false);
      return docRef.id; // Retourner l'ID du sous-secteur
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const updateSubsector = async (industryId, subsectorId, subsectorData) => {
    setLoading(true);
    try {
      const subsectorRef = doc(
        db,
        `${collectionName}/${industryId}/subsectors`,
        subsectorId
      );
      await updateDoc(subsectorRef, subsectorData);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const deleteSubsector = async (industryId, subsectorId) => {
    setLoading(true);
    try {
      const subsectorRef = doc(
        db,
        `${collectionName}/${industryId}/subsectors`,
        subsectorId
      );
      await deleteDoc(subsectorRef);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const readSubsectorsByIndustry = async (industryId) => {
    setLoading(true);
    try {
      const subsectorsCollectionRef = collection(
        db,
        `${collectionName}/${industryId}/subsectors`
      );
      const querySnapshot = await getDocs(subsectorsCollectionRef);
      const subsectors = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLoading(false);
      return subsectors;
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  // CRUD Operations for Skills

  const addSkill = async (industryId, newSkill) => {
    setLoading(true);
    try {
      const industryRef = doc(db, collectionName, industryId);
      const industrySnap = await getDoc(industryRef);

      if (industrySnap.exists()) {
        const industryData = industrySnap.data();
        const updatedSkills = [...(industryData.skills || []), newSkill];
        await updateDoc(industryRef, { skills: updatedSkills });
      } else {
        throw new Error("Industry not found");
      }

      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const updateSkill = async (industryId, skillIndex, updatedSkill) => {
    setLoading(true);
    try {
      const industryRef = doc(db, collectionName, industryId);
      const industrySnap = await getDoc(industryRef);

      if (industrySnap.exists()) {
        const industryData = industrySnap.data();
        const updatedSkills = [...industryData.skills];
        updatedSkills[skillIndex] = updatedSkill; // Update specific skill by index
        await updateDoc(industryRef, { skills: updatedSkills });
      } else {
        throw new Error("Industry not found");
      }

      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const deleteSkill = async (industryId, skillIndex) => {
    setLoading(true);
    try {
      const industryRef = doc(db, collectionName, industryId);
      const industrySnap = await getDoc(industryRef);

      if (industrySnap.exists()) {
        const industryData = industrySnap.data();
        const updatedSkills = industryData.skills.filter(
          (_, index) => index !== skillIndex
        ); // Remove specific skill
        await updateDoc(industryRef, { skills: updatedSkills });
      } else {
        throw new Error("Industry not found");
      }

      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const readSkillsByIndustry = async (industryId) => {
    setLoading(true);
    try {
      const industryRef = doc(db, collectionName, industryId);
      const industrySnap = await getDoc(industryRef);
      if (industrySnap.exists()) {
        setLoading(false);
        return industrySnap.data().skills || [];
      } else {
        setLoading(false);
        throw new Error("Industry not found");
      }
    } catch (err) {
      setError(err);
      setLoading(false);
      throw new Error(err);
    }
  };

  return {
    // Industry
    createIndustry,
    readAllIndustries,
    readIndustryById,
    updateIndustry,
    deleteIndustry,
    // Subsector
    addSubsector,
    updateSubsector,
    deleteSubsector,
    readSubsectorsByIndustry,
    // Skills
    addSkill,
    updateSkill,
    deleteSkill,
    readSkillsByIndustry,
    loading,
    error,
  };
};

export default useIndustries;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useFirestore from "../../../hook/firestore";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";

function Details() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { readDocument, loading } = useFirestore("inquires");
  const [inquire, setInquire] = useState();

  const fetchInquire = async () => {
    const result = await readDocument(searchParams.get("id"));

    setInquire(result);
  };

  useEffect(() => {
    fetchInquire();
  }, [searchParams.get("id")]);

  return (
    <div className=" w-full relative ">
      {loading && <SpinnerLoadData />}

      {inquire && (
        <div className="bg-gray-100 rounded-lg ">
          <p className="  text-gray-800  h-32  p-4 ">{inquire.description}</p>
          <hr className="my-3" />
          <textarea
            name=""
            className=" my-4  p-4 text-sm w-full border-none  bg-transparent "
            placeholder="Reply here..."
            rows={5}
            id=""
          />

          <hr className="my-3" />
          <div className="w-full py-3 pe-3 flex justify-end items-center">
            <button className=" bg-green-100 text-green-900  px-10 py-2 text-sm rounded-lg">
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Details;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalCardOpen } from "../../../redux/company";
import { SpinnerLoadData } from "../../../components/utils/spinner-load";
import { FaStripe } from "react-icons/fa6";
import useFirestore from "../../../hook/firestore";
import useProfileUpdate from "../../../hook/company";
import { RiBankLine } from "react-icons/ri";
import { CiCreditCard1 } from "react-icons/ci";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { classNames } from "../../../components/utils/className";
import { Shift } from "../../../Enum/shift";
import moment from "moment";
import Roles from "../../../Enum/UserRoles";

function ItemEmpty() {
  return (
    <div className=" text-sky-900 text-sm col-span-full bg-gray-100 rounded-lg p-4">
      No more data to load
    </div>
  );
}

function StripeItem() {
  const { profile, load } = useProfileUpdate();
  const dispatch = useDispatch();
  const [StripeAccount, setStripeAccount] = useState();
  const [payementMethod, setPaymentMethods] = useState();
  const { callableFunction, loading } = useFirestore("companies");
  const { role } = useSelector((state) => state.authenticationSlice);
  const { modalCardOpen } = useSelector((state) => state.companySlice);

  const handleStripe = async () => {
    const result = await callableFunction("createAccountLink", {
      refreshUrl: "https://www.google.com",
      returnUrl: "https://www.google.com",
      role,
      userID: profile.id,
    });

    if (result.url) {
      window.open(result.url, "_blank");
    }
  };

  useEffect(() => {
    if (profile && profile.stripeAccount && !modalCardOpen) {
      (async () => {
        const result = await callableFunction("retrieveStripeAccount", {
          stripeAccount: profile.stripeAccount,
        });
        const result_payments = await callableFunction(
          "retrieveStripeCustomerPaymentMethod",
          {
            stripeCustomer: profile.stripeCustomer,
          }
        );

        setPaymentMethods(result_payments.paymentMethods.data);
        setStripeAccount(result.account);
      })();
    }
  }, [profile, modalCardOpen]);

  console.log(StripeAccount);

  return (
    <div className="p-4 overflow-hidden flex justify-center h-[30rem] flex-col gap-4 items-center xl:col-span-4 border rounded-lg relative">
      {(!load || loading) && <SpinnerLoadData />}
      <FaStripe className=" size-20 text-indigo-600" />
      <h1 className=" text-lg font-semibold text-sky-900">Stripe Account</h1>
      {profile && !profile.stripeAccount && (
        <>
          <div className=" flex justify-center  flex-col items-center border rounded-lg p-4 mt-5">
            <h1 className="my-4 text-sm font-semibold">Activate my Account</h1>
            <p className="text-center text-xs">
              To facilitate your financial management, your acceptance of the
              Stripe Service Agreement is essential. (Provide your IBAN)
            </p>
          </div>

          <button
            type="button"
            disabled={role === Roles.member}
            onClick={handleStripe}
            className=" disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
          >
            Activate
          </button>
        </>
      )}

      {profile && profile.stripeAccount && (
        <>
          <button
            type="button"
            onClick={handleStripe}
            disabled={role === Roles.member}
            className="rounded-md disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
          >
            Update my Account Details
          </button>
        </>
      )}

      <div className="grid w-full gap-2">
        {StripeAccount && StripeAccount.external_accounts.data[0] && (
          <div className=" shadow-sm p-2 text-sm font-semibold text-sky-900 flex justify-between rounded-lg bg-gray-50 cursor-pointer">
            <div className=" inline-flex gap-2 items-center">
              <RiBankLine className="size-6 text-gray-500" />
              <span>{StripeAccount.external_accounts.data[0].bank_name}</span>
            </div>
            <span>
              ****
              {StripeAccount && StripeAccount.external_accounts.data[0].last4}
            </span>
          </div>
        )}

        {payementMethod &&
          payementMethod.map((item) => (
            <div
              onClick={() => dispatch(setModalCardOpen())}
              className=" shadow-sm p-2 text-sm font-semibold text-sky-900 flex justify-between bg-gray-50 rounded-lg cursor-pointer"
            >
              <div className=" inline-flex gap-2 items-center">
                <CiCreditCard1 className="size-6 text-gray-500" />
                <span>{item.card.brand}</span>
              </div>

              <div className=" inline-flex items-center gap-3">
                <span>****{item.card.last4}</span>
              </div>
            </div>
          ))}
        {payementMethod && payementMethod.length === 0 && (
          <div
            onClick={() => dispatch(setModalCardOpen())}
            className=" border-y p-2 text-xs   text-orange-900 flex justify-between cursor-pointer"
          >
            <div className=" cursor-pointer inline-flex gap-2 items-center">
              <CiCreditCard1 className="size-6 text-gray-500" />
            </div>

            <div className=" inline-flex gap-2 cursor-pointer items-center">
              <span>Add new card</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </div>
          </div>
        )}
        {payementMethod && payementMethod.length > 0 && (
          <p className=" text-sm font-light p-2  rounded-lg bg-orange-50 text-orange-700">
            You will be automatically charged on every shift scheduled
          </p>
        )}
      </div>
    </div>
  );
}

function TotalSpentItem() {
  const { profile, load } = useProfileUpdate();
  const [number, setNumber] = useState(0);
  const { readDocumentsWithQuery, loading } = useFirestore("shifts");
  const { calculateShiftEarnings, loading: loadNumber } =
    useFirestore("shifts");
  const [shifts, setShifts] = useState(null);

  const fetchSumTotalSpent = async () => {
    const result = await calculateShiftEarnings(
      [where("paid", "==", true), where("job.company", "==", profile.id)],
      {
        earning: true,
        hour: false,
      }
    );

    setNumber(result);
  };

  const fetchShifts = async () => {
    const result = await readDocumentsWithQuery([
      where("job.company", "==", profile.id),
    ]);

    setShifts(result);
  };

  useEffect(() => {
    if (profile) {
      fetchShifts();
      fetchSumTotalSpent();

      const unsubscribe = onSnapshot(
        query(
          collection(db, "shifts"),
          where("job.company", "==", profile.id) // Condition: where name is "test"
        ),
        () => {
          fetchShifts();
          fetchSumTotalSpent();
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile]);

  return (
    <div className="p-4 overflow-hidden flex justify-center flex-col items-center xl:col-span-4 border rounded-lg relative">
      {(loading || !load) && <SpinnerLoadData />}

      <h1 className=" text-lg font-semibold text-sky-900">Total Spent</h1>

      <div className=" flex justify-center  flex-col items-center w-full gap-4 mt-3 ">
        <div className=" flex justify-center  flex-col items-center gap-2 rounded-lg px-4 p-2">
          <h1 className=" text-5xl bg-gradient-to-tr from-indigo-600 to-sky-900 text-transparent bg-clip-text font-extrabold">
            {profile && [profile?.currency?.code, number].join(" ")}
          </h1>
          <p className="text-xs text-green-600 bg-green-50 px-4 py-1 rounded-lg shadow">
            Paid shifts
          </p>
        </div>
      </div>

      <div className=" mt-2 h-44 w-full  overflow-hidden hover:overflow-y-scroll gap-2 flex flex-col ">
        {shifts && shifts.map((item) => <ItemEarn item={item} />)}
        {shifts && shifts.length === 0 && <ItemEmpty />}
      </div>
    </div>
  );
}

function TotalHours() {
  const { profile, load } = useProfileUpdate();
  const [number, setNumber] = useState(0);
  const { readDocumentsWithQuery, loading } = useFirestore("shifts");
  const { calculateShiftEarnings, loading: loadNumber } =
    useFirestore("shifts");
  const [shifts, setShifts] = useState(null);

  const fetchSumTotalSpent = async () => {
    const result = await calculateShiftEarnings(
      [where("paid", "==", true), where("job.company", "==", profile.id)],
      {
        earning: false,
        hour: true,
      }
    );

    setNumber(result);
  };

  const fetchShifts = async () => {
    const result = await readDocumentsWithQuery([
      where("job.company", "==", profile.id),
    ]);

    setShifts(result);
  };

  useEffect(() => {
    if (profile) {
      fetchShifts();
      fetchSumTotalSpent();

      const unsubscribe = onSnapshot(
        query(
          collection(db, "shifts"),
          where("job.company", "==", profile.id) // Condition: where name is "test"
        ),
        () => {
          fetchShifts();
          fetchSumTotalSpent();
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile]);

  return (
    <div className="p-4 overflow-hidden flex justify-center flex-col items-center xl:col-span-4 border rounded-lg relative">
      {(loading || !load) && <SpinnerLoadData />}
      <h1 className=" text-lg font-semibold text-sky-900">Total Hours</h1>

      <div className=" flex justify-center items-center w-full gap-4 mt-3 ">
        <div className=" flex justify-center items-center flex-col gap-2  rounded-lg px-4 p-2">
          <h1 className=" text-5xl bg-gradient-to-tr from-sky-600 to-orange-900 text-transparent bg-clip-text font-extrabold">
            {[number, number > 1 ? "Hrs" : "Hr"].join(" ")}
          </h1>
          <p className="text-xs text-green-600 bg-green-50 px-4 py-1 rounded-lg shadow">
            Paid shifts
          </p>
        </div>
      </div>

      <div className=" mt-2 h-44 w-full  overflow-hidden hover:overflow-y-scroll gap-2 flex flex-col ">
        {shifts && shifts.map((item) => <ItemHourSpend item={item} />)}
        {shifts && shifts.length === 0 && <ItemEmpty />}
      </div>
    </div>
  );
}

function NoShow() {
  const { profile, load } = useProfileUpdate();
  const [number, setNumber] = useState(0);
  const [shifts, setShifts] = useState([]);
  const { countDocumentsWithQuery, readDocumentsWithQuery, loading } =
    useFirestore("shifts");

  const fetchNoShowCount = async () => {
    const result = await countDocumentsWithQuery([
      where("job.company", "==", profile.id),
      where("status", "==", Shift.NO_SHOW),
    ]);

    setNumber(parseInt(result));
  };

  const fetchShiftsNoShow = async () => {
    const result = await readDocumentsWithQuery([
      where("job.company", "==", profile.id),
      where("status", "==", Shift.NO_SHOW),
    ]);

    setShifts(result);
  };

  useEffect(() => {
    if (profile) {
      fetchNoShowCount();
      fetchShiftsNoShow();

      const unsubscribe = onSnapshot(
        query(
          collection(db, "shifts"),
          where("job.company", "==", profile.id) // Condition: where name is "test"
        ),
        () => {
          fetchNoShowCount();
          fetchShiftsNoShow();
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [profile]);

  return (
    <div className="p-4 overflow-hidden flex justify-center flex-col items-center xl:col-span-4  border rounded-lg relative">
      {(loading || !load) && <SpinnerLoadData />}
      <h1 className=" text-lg font-semibold text-sky-900">
        Total Candidate No-Show
      </h1>

      <div className=" flex justify-center items-center w-full gap-4 mt-3 ">
        <div className=" flex flex-col  gap-4 items-center justify-center  rounded-lg p-4">
          <h1 className=" text-6xl bg-gradient-to-tr from-red-600 to-green-900 text-transparent bg-clip-text font-extrabold">
            {number}
          </h1>
          <p className="text-sm text-orange-700 bg-orange-50 px-4 py-1 rounded-lg ">
            candidates did not attend the scheduled
          </p>
        </div>
      </div>

      <div className=" mt-2 h-36 w-full  overflow-hidden hover:overflow-y-scroll gap-2 flex flex-col ">
        {shifts && shifts.map((item) => <ItemNoshow item={item} />)}
        {shifts && shifts.length === 0 && <ItemEmpty />}
      </div>
    </div>
  );
}

function ItemEarn({ item }) {
  const [job, setJob] = useState(null);
  const { readDocument, loading } = useFirestore("jobs");

  useEffect(() => {
    (async () => {
      const result = await readDocument(item.job.id);
      setJob(result);
    })();
  }, []);

  console.log(job);
  return (
    <div className=" bg-gray-100 text-sky-900 p-4 flex justify-between items-center  rounded-lg relative">
      {loading && <SpinnerLoadData />}
      {job && (
        <>
          {" "}
          <div className="text-xs ">
            {[job.currency, (job.hourlyRate * job.totalHour).toFixed(2)].join(
              " "
            )}
          </div>
          <div>
            <div className="text-xs   text-end">
              {moment.unix(item.createdAt).local().fromNow()}
            </div>
          </div>
          <h1
            className={classNames(
              "text-xs font-semibold px-4 py-2 rounded-lg",
              item.status === Shift.CONFIRMED && "bg-green-50 text-green-700 ",
              item.status === Shift.IN_PROGRESS && "bg-sky-50 text-sky-700 ",
              item.status === Shift.CANCEL && "bg-red-50 text-red-700 ",
              item.status === Shift.PENDING_PAYMENT &&
                " bg-fuchsia-50 text-fuchsia-700 ",
              item.status === Shift.NO_SHOW && "bg-orange-50 text-orange-700 "
            )}
          >
            {item.status}
          </h1>
        </>
      )}
    </div>
  );
}
function ItemHourSpend({ item }) {
  const [job, setJob] = useState(null);
  const { readDocument, loading } = useFirestore("jobs");

  useEffect(() => {
    (async () => {
      const result = await readDocument(item.job.id);
      setJob(result);
    })();
  }, []);
  return (
    <div className=" text-sky-900 bg-gray-100 p-4  flex justify-between items-center rounded-lg relative">
      {loading && <SpinnerLoadData />}
      <div className="text-xs  ">
        {job && parseFloat(job.totalHour).toFixed(2)} Hrs
      </div>
      <div className="text-end text-xs">
        {moment.unix(item.createdAt).local().fromNow()}
      </div>

      <h1
        className={classNames(
          "text-xs font-semibold px-4 py-2 rounded-lg",
          item.status === Shift.CONFIRMED && "bg-green-50 text-green-700 ",
          item.status === Shift.IN_PROGRESS && "bg-sky-50 text-sky-700 ",
          item.status === Shift.CANCEL && "bg-red-50 text-red-700 ",
          item.status === Shift.PENDING_PAYMENT &&
            " bg-fuchsia-50 text-fuchsia-700 ",
          item.status === Shift.NO_SHOW && "bg-orange-50 text-orange-700 "
        )}
      >
        {item.status}
      </h1>
    </div>
  );
}
function ItemNoshow({ item }) {
  const [job, setJob] = useState(null);
  const { readDocument, loading } = useFirestore("jobs");

  useEffect(() => {
    (async () => {
      const result = await readDocument(item.job.id);
      setJob(result);
    })();
  }, []);

  return (
    <div className=" bg-gray-100 p-4 grid gap-2 text-sky-900 grid-cols-2 rounded-lg relative">
      {loading && <SpinnerLoadData />}

      <div className="text-sm font-semibold col-span-full grid sm:grid-cols-2 gap-4 ">
        <div className="bg-gray-200 p-2 rounded-lg inline-flex items-center gap-4 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
            />
          </svg>

          {item.job.name}
        </div>
        <div className="bg-gray-200 p-2 rounded-lg inline-flex items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
            />
          </svg>

          {item.candidate.name}
        </div>
      </div>
      <div className="text-sm font-semibold col-span-full grid sm:grid-cols-2 gap-4 ">
        <div className="bg-gray-200 p-2 rounded-lg inline-flex items-center gap-4 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
            />
          </svg>
          {moment.unix(item.job.startAt.unix).format("DD-MM-YYYY")}
        </div>
        <div className="bg-gray-200 p-2 rounded-lg inline-flex items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>

          {moment.unix(item.job.startAt.unix).format("hh:mm A")}
        </div>
      </div>
    </div>
  );
}

function Finance() {
  return (
    <>
      <div className=" p-4">
        <div
          onClick={() => window.history.back()}
          className="flex w-fit justify-start gap-4 text-sky-900  items-center  cursor-pointer "
        >
          <span>
            <svg
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
              className="size-5 "
              color="currentColor"
            >
              <g id="_4" data-name="4">
                <path d="m492 276h-472a20 20 0 0 1 0-40h472a20 20 0 0 1 0 40z" />
                <path d="m189 445a19.937 19.937 0 0 1 -14.142-5.858l-169-169a20 20 0 0 1 0-28.284l169-169a20 20 0 0 1 28.284 28.284l-154.858 154.858 154.858 154.858a20 20 0 0 1 -14.142 34.142z" />
              </g>
            </svg>
          </span>
          <span>Finance</span>
        </div>
        <hr className="my-5" />
        <div className=" grid md:grid-cols-2  xl:grid-cols-12 gap-4  ">
          <StripeItem />
          <TotalSpentItem />
          <TotalHours />
          <NoShow />
        </div>
      </div>
    </>
  );
}

export default Finance;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AutoSuggest from "../components/input/auto-suggest";
import useCurrentBreakpoint from "../hook/tailwindBreakPoint";
import { classNames } from "../components/utils/className";
import useFirestore from "../hook/firestore";
import ItemEmpty from "../components/utils/empty";
import { UsersIcon } from "lucide-react";
import { haversineDistance } from "../utils";
import moment from "moment";
import Stars from "../components/rating/Stars";
import Details from "../apps/candidate/Jobs/details";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase";
import useGeolocation from "../hook/geolocation";

function JobItemLoading({ item }) {
  return (
    <div className=" p-4 py-6 shrink-0  bg-white  shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-xs text-end text-transparent">text</h1>
      </div>

      <div className=" space-y-1">
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100 text-base font-semibold">
          text
        </h1>
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100 text-xs font-semibold">
          {" "}
          text
        </h1>
        <h1 className="text-transparent rounded-lg animate-pulse bg-slate-100  text-end font-semibold">
          {" "}
          text
        </h1>
      </div>

      <div className=" flex justify-end items-center text-transparent text-green-600  ">
        <div className=" bg-green-50 flex rounded-lg px-4 gap-2 py-1">
          <UsersIcon className=" size-4" />
          <h1 className="text-end text-sm font-semibold   ">1/3</h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-2  *:rounded-lg ">
        <div className="  text-transparent bg-slate-100 animate-pulse p-2  col-span-full text-xs font-semibold text-center">
          text
        </div>
        <div className=" text-transparent bg-slate-100 animate-pulse  p-2 col-span-full  text-xs font-semibold text-center">
          text
        </div>

        <div className=" text-transparent bg-slate-100 animate-pulse   text-xs p-2 font-semibold text-center ">
          text
        </div>
        <div className=" text-transparent bg-slate-100 animate-pulse    text-xs p-2 font-semibold text-center">
          text
        </div>

        <div className="  text-transparent bg-gray-100 animate-pulse border text-sm p-2 col-span-full">
          text
        </div>
        <div className="  p-2 grid grid-cols-2 text-sm gap-4  col-span-full">
          <button className="  text-transparent  text-center animate-pulse bg-white p-2 font-semibold  rounded-lg">
            More details
          </button>
          <button className="  text-transparent text-center bg-sky-600 hover:bg-sky-800 animate-pulse p-2 font-semibold rounded-lg">
            Quick Apply
          </button>
        </div>
      </div>
    </div>
  );
}
function JobItem({ item }) {
  const { readDocument } = useFirestore("companies");

  const [wasApplied, setWasApplied] = useState();
  const [status, setStatus] = useState();
  const [company, setCompany] = useState(false);
  const dispatch = useDispatch();
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const navigate = useNavigate();
  const [distance, setDistance] = useState(0);

  const handleDetail = (id) => {
    navigate(`?id=${id}`);
  };

  const fetchCompany = async () => {
    const result = await readDocument(item.company.id);
    setCompany(result);
  };

  const CalculDistance = async () => {
    const result = await haversineDistance(
      latitude,
      longitude,
      item.location?.latitude,
      item.location?.longitude
    );
    if (result) {
      setDistance(result);
    }
  };

  useEffect(() => {
    fetchCompany();
    CalculDistance();
  }, [item]);

  return (
    <div className=" p-4 py-6 shrink-0  bg-white border shadow-sm rounded-lg relative">
      <div className=" absolute top-0 p-2 right-0">
        <h1 className="text-xs text-end">
          {moment.unix(item.creatAt).local().fromNow()}
        </h1>
      </div>

      <div className=" space-y-1">
        <h1 className="text-base font-semibold">{item.jobName}</h1>
        <h1 className="text-xs font-semibold">{item.company.name}</h1>
        <h1 className=" text-end font-semibold">
          {company && <Stars number={company.averageStars || 0} />}
        </h1>
      </div>

      <div className=" flex justify-end items-center text-green-600  ">
        <div className=" bg-green-50 flex rounded-lg px-4 gap-2 py-1">
          <UsersIcon className=" size-4" />
          <h1 className="text-end text-sm font-semibold   ">
            {[item.applicationCount || 0, item.nbCandidate].join("/")}
          </h1>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-2  *:rounded-lg ">
        <div className=" p-2 border col-span-full text-xs font-semibold text-center">
          {[item.startAt.time, item.endAt.time].join("-")}
        </div>
        <div className=" p-2 col-span-full border text-xs font-semibold text-center">
          {item.startDate}
        </div>

        <div className=" border text-xs p-2 font-semibold text-center ">
          {distance.toFixed(2)} Km Away
        </div>
        <div className="  border text-xs p-2 font-semibold text-center">
          {[item.currency, item.hourlyRate].join(" ")}/hr
        </div>

        <div className="  border text-sm p-2 col-span-full">
          {item.description}
        </div>
        <div
          onClick={() => handleDetail(item.id)}
          className="  p-2 grid grid-cols-2 text-sm gap-4  col-span-full"
        >
          <button className=" border text-center bg-white p-2 font-semibold  rounded-lg">
            More details
          </button>

          <button
            disabled={wasApplied}
            className=" disabled:bg-gray-100 disabled:text-sky-600 text-center bg-sky-600 hover:bg-sky-800 p-2 font-semibold text-white rounded-lg"
          >
            {wasApplied ? status : " Quick Apply"}
          </button>
        </div>
      </div>
    </div>
  );
}

function JobConversion() {
  useGeolocation();
  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const brk = useCurrentBreakpoint();
  const scrollContainerRef = useRef();
  const [jobs, setJobs] = useState([]);
  const scrollToRef = useRef();
  const navigate = useNavigate();
  const [selected, setSelected] = useState();
  const [selectedSuggestion, setSelectedSuggestion] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { readDocumentsWithQuery, loading, callableFunction } =
    useFirestore("jobs");

  const handleFilter = async () => {
    try {
      const now = moment.utc().unix();
      const response = await callableFunction("filterJobs", {
        radius: "*",
        hourlyRate: "*",
        latitude: latitude || 0,
        longitude: longitude || 0,
        filterBy: "near me",
        unix: 0,
        name: searchParams.get("name") || null,
      });

      const { data } = response;

      if (data && data.length > 0) {
        const firstJobId = data[0].id;

        if (!["sm", "xs"].includes(brk)) {
          setSelected(firstJobId);
        }
      } else {
        setSelected(null);
      }

      setJobs(data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    handleFilter();

    const unsubscribe = onSnapshot(query(collection(db, "jobs")), () => {
      handleFilter();
    });

    return () => {
      unsubscribe();
    };
  }, [latitude, longitude, searchParams.get("name")]);

  return (
    <div class="max-w-[85rem] relative  px-4 py-32 sm:px-6 lg:px-8  mx-auto">
      <div className=" bg-gray-50 flex pt-10 p-28 rounded-lg px-8  justify-center  flex-col items-center w-full">
        <h2 class="text-2xl  font-bold md:text-4xl md:leading-tight text-sky-900">
          Hook me up !
        </h2>
        <p class="mt-1 text-center text-gray-600 dark:text-gray-400">
          Join <span className="text-sky-600">Flexer</span> to find roles
          tailored to your skills and location.
        </p>

        <div class="grid  w-full relative pe-28  p-4 mt-6 gap-y-2 gap-x-4 rounded-lg  bg-white">
          <AutoSuggest
            selected={selectedSuggestion}
            setSelected={setSelectedSuggestion}
          />
          <button
            onClick={() =>
              navigate(
                `/jobs?name=${selectedSuggestion.name}&sector=${selectedSuggestion.sector}`
              )
            }
            className="absolute right-0 me-9 bg-sky-600 top-1/2 -translate-y-1/2 p-4 text-sky-50 rounded-lg"
          >
            Get started
          </button>
        </div>
      </div>

      <div className=" grid xl:grid-cols-12 gap-2 -mt-16  overflow-hidden">
        <div
          ref={scrollContainerRef}
          className={classNames(
            " xl:col-span-4 p-4 scroll-smooth  overflow-hidden hover:overflow-y-auto flex flex-col gap-4 border-y  rounded-t-lg rounded-b-lg  h-[calc(100vh-14rem)] ",
            searchParams.get("id") && " max-xl:hidden"
          )}
        >
          {jobs && !loading && jobs.length === 0 && <ItemEmpty />}

          {jobs &&
            jobs.map((item) => (
              <div
                ref={searchParams.get("id") === item.id ? scrollToRef : null}
                className={classNames(
                  searchParams.get("id") === item.id &&
                    "border border-sky-900 rounded-lg shadow-sm-light"
                )}
              >
                <JobItem item={item} />
              </div>
            ))}

          {loading && [
            <JobItemLoading />,
            <JobItemLoading />,
            <JobItemLoading />,
          ]}
        </div>

        <div
          className={classNames(
            " block xl:col-span-8 px-4  max-h-[calc(100vh-14rem)] overflow-y-auto",
            !searchParams.get("id") && "max-xl:hidden"
          )}
        >
          {selected && <Details id={selected} />}
        </div>
      </div>
    </div>
  );
}

export default JobConversion;

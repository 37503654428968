import React, { useEffect, useState } from "react";
import useFirestore from "../../hook/firestore";
import { auth, db } from "../../firebase";
import GenericImg from "../layout/generic-user-img";
import { useSelector } from "react-redux";
import Roles from "../../Enum/UserRoles";
import { SpinnerLoadData } from "../utils/spinner-load";
import moment from "moment";
import Details from "./details";
import { collection, onSnapshot, query } from "firebase/firestore";
import { classNames } from "../utils/className";

function ConversationItemLoading() {
  return (
    <div className=" rounded-lg bg-gray-100 p-4 flex h-24  gap-4 justify-between  cursor-pointer relative">
      <div className=" absolute top-0 right-2 px-8 text-[0.60rem] font-semibold py-1 animate-pulse text-transparent bg-gray-50 ">
        Text
      </div>

      <div className=" size-14 bg-slate-50  animate-pulse rounded-full  shrink-0"></div>
      <div className="flex flex-col gap-2 w-full py-4 ">
        <h1 className="text-sm font-semibold text-transparent animate-pulse bg-gray-50 px-10 rounded-lg">
          name
        </h1>
        <p className="text-xs  font-normal text-transparent px-8 animate-pulse bg-slate-50 rounded-lg">
          content content
        </p>
      </div>
    </div>
  );
}

function ConversationItem({ item, onClick, ...props }) {
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;
  const [user, setUser] = useState();
  const { readDocument, loading } = useFirestore(
    role !== Roles.admin
      ? role === Roles.candidate
        ? item.admin
          ? "admins"
          : "companies"
        : item.admin
        ? "admins"
        : "candidates"
      : item.candidate
      ? "candidates"
      : "companies"
  );
  const [count, setCount] = useState(0);

  const { countUnreadMessages } = useFirestore("conversations");
  const isCandidate = role === Roles.candidate;
  const isAdmin = role === Roles.admin;
  const isCompany = [Roles.company, Roles.member, Roles.manager].includes(role);

  const fetchConversation = async () => {
    const count = await countUnreadMessages(item.id, currentUser.uid);
    setCount(count);
  };

  const fetchUser = async () => {
    const user = await readDocument(
      role !== Roles.admin
        ? role === Roles.candidate
          ? item.admin
            ? item.admin
            : item.company
          : item.admin
          ? item.admin
          : item.candidate
        : item.candidate
        ? item.candidate
        : item.company
    );

    setUser(user);
  };

  useEffect(() => {
    fetchUser();
    fetchConversation();
  }, [item]);

  useEffect(() => {
    const conversationRef = collection(db, "conversations");

    const q = query(conversationRef);

    // Mise en place de l'écoute des changements en temps réel
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        fetchConversation();
      },
      (err) => {
        console.error(err);
      }
    );

    // Nettoyage : désinscrire l'écoute lorsqu'on démonte le composant
    return () => unsubscribe();
  }, []);

  const imageUrl = !isAdmin
    ? isCandidate
      ? user && user.logoUrl
      : isCompany
      ? user && user.avatarUrl
      : null
    : user?.role === Roles.candidate
    ? user && user.avatarUrl
    : user?.role === Roles.company
    ? user && user.logoUrl
    : null;

  return (
    <div
      {...props}
      onClick={() => onClick(item.id, user)}
      className=" rounded-lg bg-gray-100 p-4 flex h-24  gap-4 justify-between  cursor-pointer relative"
    >
      {loading && !user && <SpinnerLoadData />}

      <div className=" absolute top-0 right-2 text-[0.60rem] font-semibold py-1 ">
        {moment.unix(item.lastMessage.creatAt).local().fromNow()}
      </div>
      {count > 0 && (
        <div className=" absolute bottom-2 rounded-full flex justify-center items-center   bg-red-400 size-4 right-2 text-[0.60rem] font-semibold py-1 "></div>
      )}

      <div className=" size-14 bg-slate-50 rounded-full shrink-0">
        {(isCandidate || isCompany || isAdmin) &&
          (imageUrl ? (
            <img
              src={imageUrl}
              alt="img"
              className="size-full rounded-full object-cover"
            />
          ) : (
            <GenericImg />
          ))}
      </div>
      <div className="flex flex-col gap-2 w-full">
        <h1 className="text-sm font-semibold text-sky-900">
          {(user && user.name) || "ADMIN"}
        </h1>
        <p className="text-xs  font-normal text-sky-900/90">
          {item.lastMessage.content}
        </p>
      </div>
    </div>
  );
}

function ConversationView(props) {
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;
  const [openned, setOpenned] = useState();
  const [conversations, setConversations] = useState([]);

  const { callableFunction, loading } = useFirestore("conversations");

  const handleClick = (id, user) => {
    const { onSelect } = props;

    setOpenned(id);
    onSelect({
      id,
      user,
    });
  };

  const fetchConversations = async () => {
    const conversations = await callableFunction("readConversationsForUser", {
      userId: auth.currentUser.uid,
      role,
      nameFilter: props.name || null,
    });
    setConversations(conversations.conversations);
  };

  useEffect(() => {
    fetchConversations();
  }, [currentUser.uid, props.name]);

  /*   useEffect(() => {
    if (openned) {
      const conversationRef = collection(
        db,
        "conversations",
        openned,
        "messages"
      );

      const q = query(conversationRef);

      // Mise en place de l'écoute des changements en temps réel
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          fetchConversations();
        },
        (err) => {
          console.error(err);
        }
      );

      // Nettoyage : désinscrire l'écoute lorsqu'on démonte le composant
      return () => unsubscribe();
    }
  }, [openned]); */

  return (
    <div {...props}>
      {loading && [
        <ConversationItemLoading key="loading-1" />,
        <ConversationItemLoading key="loading-2" />,
        <ConversationItemLoading key="loading-3" />,
        <ConversationItemLoading key="loading-4" />,
      ]}

      {!loading &&
        conversations.map((item) => (
          <ConversationItem
            key={item.id}
            onClick={(id, user) => handleClick(id, user)}
            item={item}
          />
        ))}
    </div>
  );
}

function Messages() {
  const [select, setSelected] = useState();
  const [name, setName] = useState();

  return (
    <div className="xl:grid xl:grid-cols-12 h-[calc(100vh-4rem)] xl:divide-x overflow-hidden  ">
      <div
        className={classNames(
          " xl:col-span-4  col-span-full",
          select && " max-xl:hidden"
        )}
      >
        <h1 className=" text-2xl font-semibold text-sky-900 p-4">
          Recent Chat
        </h1>
        <hr className=" my-4" />
        <div className=" py-3 flex items-center justify-end my-4">
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder="Search a conversation "
            className=" rounded-s-lg  bg-gray-100 border-none text-sm w-3/4"
            id=""
          />
        </div>
        <ConversationView
          name={name}
          onSelect={setSelected}
          className={
            "gap-2 p-2 h-[calc(100vh-16rem)] flex flex-col  overflow-hidden hover:overflow-y-scroll scroll-smooth"
          }
        />
      </div>
      {select && (
        <Details
          select={select.id}
          user={select.user}
          setSelected={setSelected}
          className={classNames(
            "col-span-8 px-4 py-6 h-[calc(100vh-4rem)] overflow-hidden   relative flex flex-col justify-between ",
            !select && "max-xl:hidden"
          )}
        />
      )}

      {!select && (
        <div className="col-span-8 md:flex hidden justify-center items-center">
          <span className=" rounded-lg border border-dashed p-4 text-sm bg-gray-100 text-sky-900">
            Select a conversation
          </span>
        </div>
      )}
    </div>
  );
}

export default Messages;

import { BookmarkIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import useFirestore from "../../hook/firestore";
import { where } from "firebase/firestore";
import Application from "../../Enum/Applicatition";
import useProfileUpdate from "../../hook/company";
import { classNames } from "../utils/className";

function LatestApplicationIcon() {
  const { profile } = useProfileUpdate();
  const { countDocumentsWithQuery } = useFirestore("applications");
  const [count, setCount] = useState(0);

  const fetchCount = async () => {
    const result = await countDocumentsWithQuery([
      where("status", "==", Application.PENDING),
    ]);

    setCount(result);
  };

  useEffect(() => {
    if (profile) {
      fetchCount();
    }
  }, [profile]);

  return (
    <div className="indicator">
      <span className="indicator-item  h-4 inline-flex justify-center items-center rounded-full font-semibold text-white text-[0.650rem] w-4 bg-sky-600">
        {count}
      </span>
      <BookmarkIcon
        className={classNames(
          "text-gray-400 group-hover:text-sky-600",
          "h-6 w-6 shrink-0"
        )}
        aria-hidden="true"
      />
    </div>
  );
}

export default LatestApplicationIcon;

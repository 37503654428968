import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Roles from "../../Enum/UserRoles";

import {
  BellIcon,
  BookmarkIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  ClockIcon,
  Cog6ToothIcon,
  HomeIcon,
  TableCellsIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { FireIcon } from "@heroicons/react/20/solid";

import useCandidate from "../../hook/candidate";
import { setStage } from "../../redux/navigation";
import { classNames } from "../utils/className";
import { updateProfileCandidate } from "../../redux/candidate";
import {
  NotificationBellCandidate,
  NotificationBellCompany,
} from "./notificationBell";
import MessagesIcon from "./messagesIcon";
import LatestApplicationIcon from "./Latest-applicationIcon";
import useFirestore from "../../hook/firestore";
import { auth, db } from "../../firebase";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";

const navigation = {
  company: [
    {
      name: "Home",
      to: "/",
      icon: HomeIcon,
      current: window.location.pathname == "/",
      roles: [Roles.company, Roles.manager, Roles.member],
    },
    {
      name: "Jobs",
      to: "/jobs",
      icon: BriefcaseIcon,
      current: window.location.pathname == "/jobs",
      roles: [Roles.company, Roles.manager, Roles.member],
    },
    {
      name: "Candidate",
      to: "/candidate",
      icon: UserIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.company, Roles.manager, Roles.member],
    },
    {
      name: "Shift",
      to: "/shift",
      icon: CalendarDaysIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.company, Roles.manager, Roles.member],
    },
    {
      name: "Finance",
      to: "/profile/finance",
      icon: ChartPieIcon,
      tab: 2,
      roles: [Roles.company, Roles.manager],
    },
  ],

  candidate: [
    {
      name: "Home",
      to: "/",
      icon: HomeIcon,
      current: window.location.pathname == "/",
      roles: [Roles.candidate],
    },
    {
      name: "Jobs",
      to: "/jobs",
      icon: BriefcaseIcon,
      current: window.location.pathname == "/jobs",
      roles: [Roles.candidate],
    },
    {
      name: "Upcoming",
      to: "/upcoming",
      icon: ClockIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.candidate],
    },
    {
      name: "Availability",
      to: "/availability",
      icon: CalendarDaysIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.candidate],
    },
    {
      name: "History",
      to: "/history",
      icon: TableCellsIcon,
      current: window.location.pathname == "/#",
      roles: [Roles.candidate],
    },
    {
      name: "Finance",
      to: "/profile/finance",
      icon: ChartPieIcon,
      tab: 2,
      roles: [Roles.candidate],
    },
  ],
};

function SidebarMobile({ setSidebarOpen }) {
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;
  const dispatch = useDispatch();
  const location = useLocation();
  const [countNotification, setCountNotification] = useState(0);
  const { countDocumentsWithQuery, loading } = useFirestore("notifications");
  const { readDocument } = useFirestore("notifs_log");

  const fetchCountNotifications = async () => {
    const log = await readDocument(currentUser?.uid);

    const result = await countDocumentsWithQuery([
      where("to", "==", currentUser?.uid),
      where("creatAt", ">=", log?.date),
      where("read", "==", false),
    ]);

    setCountNotification(result);
  };

  useEffect(() => {
    fetchCountNotifications();
    const docRef = doc(db, "notifs_log", currentUser?.uid);

    const unsubscribe = onSnapshot(docRef, () => {
      fetchCountNotifications();
    });

    const unsubscribe2 = onSnapshot(
      query(
        collection(db, "notifications"),
        where("to", "==", currentUser?.uid) // Condition: where name is "test"
      ),
      () => {
        fetchCountNotifications();
      }
    );

    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto  bg-white px-6 pb-4">
      <Link to={"/"} className="flex h-16 shrink-0 items-center">
        <img
          className="h-8 w-auto"
          src={require("../../assets/img/logo.png")}
          alt="logo"
        />
      </Link>

      <nav className="flex  flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {[Roles.company, Roles.manager, Roles.member].includes(role) && (
                <li>
                  <a
                    onClick={() => {
                      setSidebarOpen(false);
                      dispatch({
                        type: "ApplicationSlice/setApplicationLaunch",
                      });
                    }}
                    className={classNames(
                      "text-gray-700 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <LatestApplicationIcon />
                    Latest Applications
                  </a>
                </li>
              )}
              <li>
                <a
                  onClick={() => {
                    setSidebarOpen(false);
                    dispatch({
                      type: "conversation/setNotifOpen",
                    });
                  }}
                  className={classNames(
                    "text-gray-400 hover:text-sky-600 cursor-pointer hover:bg-gray-50",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  )}
                >
                  {role === Roles.candidate && (
                    <NotificationBellCandidate
                      countNotification={countNotification || 0}
                    />
                  )}
                  {[Roles.company, Roles.manager, Roles.member].includes(
                    role
                  ) && (
                    <NotificationBellCompany
                      countNotification={countNotification || 0}
                    />
                  )}
                  <span className="text-gray-700">Notification</span>
                </a>
              </li>
              <li>
                <Link
                  to={"/messages"}
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                  className={classNames(
                    location.pathname === "/messages"
                      ? "bg-gray-50 text-sky-600"
                      : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  )}
                >
                  <MessagesIcon />
                  <span>Message</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <hr />

      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation[
                [Roles.member, Roles.manager, Roles.company].includes(role)
                  ? "company"
                  : "candidate"
              ].map((item) => (
                <li
                  key={item.name}
                  onClick={() =>
                    item.tab ? dispatch(setStage(item.tab)) : undefined
                  }
                >
                  <Link
                    onClick={() => setSidebarOpen(false)}
                    to={item.to}
                    className={classNames(
                      location.pathname === item.to
                        ? "bg-gray-50 text-sky-600"
                        : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        location.pathname === item.to
                          ? "text-sky-600"
                          : "text-gray-400 group-hover:text-sky-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="mt-auto">
            <Link
              to="/profile"
              onClick={() => {
                setSidebarOpen(false);
              }}
              className={classNames(
                location.pathname === "/profile"
                  ? "bg-gray-50 text-sky-600"
                  : "text-gray-700 hover:text-sky-600 hover:bg-gray-50",
                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
              )}
            >
              <Cog6ToothIcon
                className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-sky-600"
                aria-hidden="true"
              />
              Profile
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SidebarMobile;

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from "react";
import { CheckCircleIcon, QrCodeIcon } from "@heroicons/react/24/solid";
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import { fetchLogs } from "../../redux/admin";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EnvelopeIcon, XCircleIcon } from "@heroicons/react/24/outline";
import useFirestore from "../../hook/firestore";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { ArrowDownToDot, User2Icon } from "lucide-react";

const activity = [
  {
    id: 1,
    type: "created",
    person: { name: "Chelsea Hagon" },
    date: "7d ago",
    dateTime: "2023-01-23T10:32",
  },
  {
    id: 2,
    type: "edited",
    person: { name: "Chelsea Hagon" },
    date: "6d ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 3,
    type: "sent",
    person: { name: "Chelsea Hagon" },
    date: "6d ago",
    dateTime: "2023-01-23T11:24",
  },
  {
    id: 4,
    type: "commented",
    person: {
      name: "Chelsea Hagon",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    comment:
      "Called client, they reassured me the invoice would be paid by the 25th.",
    date: "3d ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 5,
    type: "viewed",
    person: { name: "Alex Curren" },
    date: "2d ago",
    dateTime: "2023-01-24T09:12",
  },
  {
    id: 6,
    type: "paid",
    person: { name: "Alex Curren" },
    date: "1d ago",
    dateTime: "2023-01-24T09:20",
  },
];
const moods = [
  {
    name: "Excited",
    value: "excited",
    icon: FireIcon,
    iconColor: "text-white",
    bgColor: "bg-red-500",
  },
  {
    name: "Loved",
    value: "loved",
    icon: HeartIcon,
    iconColor: "text-white",
    bgColor: "bg-pink-400",
  },
  {
    name: "Happy",
    value: "happy",
    icon: FaceSmileIcon,
    iconColor: "text-white",
    bgColor: "bg-green-400",
  },
  {
    name: "Sad",
    value: "sad",
    icon: FaceFrownIcon,
    iconColor: "text-white",
    bgColor: "bg-yellow-400",
  },
  {
    name: "Thumbsy",
    value: "thumbsy",
    icon: HandThumbUpIcon,
    iconColor: "text-white",
    bgColor: "bg-blue-500",
  },
  {
    name: "I feel nothing",
    value: null,
    icon: XMarkIcon,
    iconColor: "text-gray-400",
    bgColor: "bg-transparent",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Feeds() {
  const [feeds, setFeeds] = useState([]);

  const { readDocumentsWithQuery, loading } = useFirestore("feed");

  const fetchFeeds = async () => {
    const result = await readDocumentsWithQuery([orderBy("timestamp", "desc")]);

    setFeeds(result);
  };

  useEffect(() => {
    fetchFeeds();

    const docRef = collection(db, "feed");

    const q = query(docRef);

    // Mise en place de l'écoute des changements en temps réel
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        fetchFeeds();
      },
      (err) => {
        console.error(err);
      }
    );

    // Nettoyage : désinscrire l'écoute lorsqu'on démonte le composant
    return () => unsubscribe();
  }, []);

  return (
    <>
      <ul role="list" className="space-y-6">
        {feeds &&
          feeds.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
              <div
                className={classNames(
                  activityItemIdx === feeds.length - 1 ? "h-6" : "-bottom-6",
                  "absolute left-0 top-0 flex w-6 justify-center"
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>

              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {activityItem.type === "shift_paid_updated" && (
                    <CheckCircleIcon
                      className="h-6 w-6 text-sky-600"
                      aria-hidden="true"
                    />
                  )}
                  {activityItem.type === "registration" && (
                    <User2Icon
                      className="h-6 w-6 text-sky-600"
                      aria-hidden="true"
                    />
                  )}
                  {activityItem.type === "application_created" && (
                    <EnvelopeIcon
                      className="h-6 w-6 text-sky-600"
                      aria-hidden="true"
                    />
                  )}
                  {activityItem.type === "application_status_updated" && (
                    <ArrowDownToDot
                      className="h-6 w-6 text-sky-600"
                      aria-hidden="true"
                    />
                  )}

                  {[
                    "shift_clock_in_updated",
                    "shift_clock_out_updated",
                  ].includes(activityItem.type) && (
                    <QrCodeIcon
                      className="h-6 w-6 text-sky-600"
                      aria-hidden="true"
                    />
                  )}

                  {![
                    "shift_clock_in_updated",
                    "shift_clock_out_updated",
                    "shift_paid_updated",
                    "registration",
                    "application_created",
                    "application_status_updated",
                  ].includes(activityItem.type) && (
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  )}
                </div>
                <p className="flex-auto flex flex-col py-0.5 text-xs leading-5 ">
                  <span className="text-sky-600">
                    {activityItem.name} &nbsp;
                    <span className="text-gray-600">
                      {activityItem.message}
                    </span>
                  </span>
                </p>
                <time
                  dateTime={activityItem.timestamp.toDate()}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  {moment(activityItem.timestamp.toDate()).fromNow()}
                </time>
              </>
            </li>
          ))}
      </ul>
    </>
  );
}

import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { reset } from "../redux/authentication";
import useFirestore from "../hook/firestore";
import { SpinnerLoadData } from "../components/utils/spinner-load";

function ResetPassword() {
  const [email, setEmail] = useState(null);
  const { callableFunction, loading } = useFirestore("mail");

  const [error, setError] = useState(false);
  const [exist, setExist] = useState(false);

  async function resetPassword(e) {
    e.preventDefault();
    await callableFunction("sendPasswordResetLink", { email })
      .then(() => setExist(true))
      .catch((e) => setError(e));
  }

  return (
    <div className="flex h-screen bg-gray-100 min-h-full items-center  flex-1">
      <main class="w-full max-w-lg mx-auto p-6 relative">
        {loading && <SpinnerLoadData />}
        <div class="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
          <div class="p-4 sm:p-7">
            <div class="text-center">
              <h1 class="block text-2xl font-bold text-gray-800 dark:text-white">
                Forgot password?
              </h1>
              <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Remember your password?
                <Link
                  class="text-sky-600 decoration-2 hover:underline font-medium"
                  to={"/login"}
                >
                  Sign in here
                </Link>
              </p>
            </div>
            {error && (
              <div className="rounded-md bg-red-50 p-4 mt-3">
                <div className="flex">
                  <div className="flex-shrink-0"></div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      {error}
                    </h3>
                  </div>
                </div>
              </div>
            )}
            {exist && (
              <div className="rounded-md bg-green-50 p-4 mt-3">
                <div className="flex">
                  <div className="flex-shrink-0"></div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">
                      An email was sent to you email address.
                    </h3>
                  </div>
                </div>
              </div>
            )}
            <div class="mt-5">
              <form onSubmit={resetPassword}>
                <div class="grid gap-y-4">
                  <div>
                    <label
                      for="email"
                      class="block text-sm mb-2 dark:text-white"
                    >
                      Email address
                    </label>
                    <div class="relative">
                      <input
                        type="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        class="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-sky-500 focus:ring-sky-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                        required
                        aria-describedby="email-error"
                      />
                      <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg
                          class="h-5 w-5 text-red-500"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          aria-hidden="true"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                        </svg>
                      </div>
                    </div>
                    <p
                      class="hidden text-xs text-red-600 mt-2"
                      id="email-error"
                    >
                      Please include a valid email address so we can get back to
                      you
                    </p>
                  </div>

                  <button
                    type="submit"
                    class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-sky-500 text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                  >
                    Reset password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ResetPassword;

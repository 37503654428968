import React from "react";
import { Link } from "react-router-dom";
import useCompany from "../../hook/company";
import useCandidate from "../../hook/candidate";
import { updateProfileCandidate } from "../../redux/candidate";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileCompany } from "../../redux/company";

export function WelcomeMessageCompany({ setInfoShow }) {
  const dispatch = useDispatch();

  const { role, member_name, role_member } = useSelector(
    (state) => state.authenticationSlice
  );
  const { company, onLoad } = useCompany(role === "COMPANY");

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#2d8cff] to-[#f17ce5] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#33a7ff] to-[#9054ff] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>

      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900">
          <strong className="font-semibold">Team TempME</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          {company &&
          company.industry &&
          company.description &&
          company.crn &&
          company.vat &&
          company.user.stripe &&
          company.user.stripe.requirements.currently_due.length === 0
            ? "Your company profile seems be completed "
            : " Thanks to have join us, for a better experience complete your company profile to allow you post job and accept candidates"}
        </p>
        {company &&
        company.industry &&
        company.description &&
        company.crn &&
        company.vat &&
        company.user.stripe &&
        company.user.stripe.requirements.currently_due ? (
          <button
            onClick={() =>
              dispatch(
                updateProfileCompany({
                  id: company.id,
                  params: {
                    visible: true,
                  },
                })
              )
            }
            className="flex-none rounded-full bg-green-600 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Activate my account
            <span aria-hidden="true">&rarr;</span>
          </button>
        ) : (
          <Link
            to="/profile"
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Complete <span aria-hidden="true">&rarr;</span>
          </Link>
        )}
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          onClick={() => setInfoShow(false)}
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
export function WelcomeMessageCandidate({ setInfoShow }) {
  const dispatch = useDispatch();
  const { role, member_name, role_member } = useSelector(
    (state) => state.authenticationSlice
  );
  const { datas, loading } = useCandidate(role === "CANDIDATE");

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#2d8cff] to-[#f17ce5] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#33a7ff] to-[#9054ff] opacity-30"
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        />
      </div>

      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900">
          <strong className="font-semibold">Team TempME</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          {datas &&
          datas?.hourlyRate?.value &&
          datas?.radius &&
          datas?.title &&
          datas?.user?.legalDocumentId?.path &&
          datas.user.stripe &&
          datas.user.stripe.requirements.currently_due.length === 0
            ? "Your profile seems be completed "
            : " Thanks to have join us, for a better experience complete your profile to apply and be visible for companies."}
        </p>
        {datas &&
        datas?.hourlyRate?.value &&
        datas?.radius &&
        datas.title &&
        datas?.user?.legalDocumentId?.path &&
        datas.user.stripe &&
        datas.user.stripe.requirements.currently_due.length === 0 ? (
          <button
            onClick={() =>
              dispatch(
                updateProfileCandidate({
                  id: datas.id,
                  params: {
                    visible: true,
                  },
                })
              )
            }
            className="flex-none rounded-full bg-green-600 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Render me visible for companies{" "}
            <span aria-hidden="true">&rarr;</span>
          </button>
        ) : (
          <Link
            to="/profile"
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Complete <span aria-hidden="true">&rarr;</span>
          </Link>
        )}
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          onClick={() => setInfoShow(false)}
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}

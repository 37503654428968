import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Applications from "../components/application";
import CommandApplication from "../components/command-shift/command-application";
import CommandShift from "../components/command-shift/command-shift";
import ModalActivate from "../components/modal/modal-activate";
import ModalApply from "../components/modal/modal-apply";
import ModalAvailability from "../components/modal/modal-availability";
import ModalComplete from "../components/modal/modal-complete";
import ModalCard from "../components/modal/model-card";
import SuccessScan from "../components/modal/success-scan";
import EditMember from "../components/Teams/Edit-member";
import CommandTeams from "../components/Teams/Teams";
import Roles from "../Enum/UserRoles";
import useCandidate from "../hook/candidate";
import useCompany from "../hook/company";
import useNotifications from "../hook/notifications";
import Candidate from "./candidate";
import Map from "./candidate/Dashboard/Map";
import Company from "./company";
import Application from "./company/Application";

import BottomMobilCompany from "../components/layout/bottom-desktop-company";
import BottomMobileCandidate from "../components/layout/bottom-mobile-candidate";
import Header from "../components/layout/header";
import SidebarDesktop from "../components/layout/side-bar-desktop";
import SidebarMobile from "../components/layout/side-bar-mobile";
import {
  WelcomeMessageCandidate,
  WelcomeMessageCompany,
} from "../components/layout/welcome-message";
import { SpinnerLoad } from "../components/utils/spinner-load";
import useGeolocation from "../hook/geolocation";
import NotificationsCompany from "../components/notification/notification-company";
import NotificationsCandidate from "../components/notification/notification-candidate";
import useFirestore from "../hook/firestore";
import { auth } from "../firebase";
import moment from "moment";
import ModalRepost from "../components/modal/modal-repost";
import { Shift } from "../Enum/shift";

const componnent = [
  {
    role: "CANDIDATE",
    componnent: <Candidate />,
  },
  {
    role: "COMPANY",
    componnent: <Company />,
  },
];

const componnentUtils = [
  {
    role: "CANDIDATE",
    componnent: [
      <ModalAvailability />,
      <ModalApply />,
      <NotificationsCandidate />,
      <ModalComplete />,
      <SuccessScan />,
    ],
  },
  {
    role: "COMPANY",
    componnent: [
      <NotificationsCompany />,
      <CommandShift />,
      <CommandApplication />,
      <Applications />,
      <ModalActivate />,
      <ModalCard />,
      <CommandTeams />,
      <EditMember />,
      <ModalRepost />,
    ],
  },
];

export default function Layout() {
  useGeolocation();
  const { role, OwnerUID } = useSelector((state) => state.authenticationSlice);
  const [pushToken, setPushToken] = useState(null);
  const currentUser = [Roles.manager, Roles.member].includes(role)
    ? OwnerUID
    : auth.currentUser;

  const { updateDocument: updatePushToken } = useFirestore(
    Roles.candidate === role ? "candidates" : "companies"
  );

  const { createDocumentCurrentUser, updateDocument, readDocument } =
    useFirestore("notifs_log");

  const { readDocument: readShift, updateDocument: updateShift } =
    useFirestore("shifts");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [infoShow, setInfoShow] = useState(true);
  const location = useLocation();
  const { filterOpen } = useSelector((state) => state.FilterSlice);
  const { notifOpen } = useSelector((state) => state.conversationSlice);
  const { applicationLaunch, candidateLaunch } = useSelector(
    (state) => state.ApplicationSlice
  );
  const { datas } = useCandidate(role === "CANDIDATE");
  const { company } = useCompany(role === "COMPANY");
  const { shifPunch } = useSelector((state) => state.ShiftSlice);

  const handleOpenNotification = async () => {
    const id = currentUser.uid;
    const docSnapShot = await readDocument(id);
    if (!docSnapShot) {
      await createDocumentCurrentUser({
        date: moment.utc().unix(),
      });
    } else {
      await updateDocument(id, {
        date: moment.utc().unix(),
      });
    }
  };

  useEffect(() => {
    (async () => {
      updatePushToken(currentUser.uid, {
        pushToken,
      });
    })();
  }, [pushToken]);

  useEffect(() => {
    if (notifOpen) {
      handleOpenNotification();
    }
  }, [notifOpen]);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({data : "check-token"}));
    }
  }, []);

  useEffect(() => {
    // Attacher la fonction à window lors du montage du composant
    window.reactNativeFunctions = {
      test: function (param) {
        if (window.ReactNativeWebView) {
          alert(param);
        }
      },

      pushToken: function (param) {
        if (window.ReactNativeWebView) {
          setPushToken(param);
        }
      },
      ClockInOut: async function (param) {
        if (!window.ReactNativeWebView) return;

        // Détection du séparateur (in ou out)
        let separator = "-in-";
        if (param.includes("-out-")) {
          separator = "-out-";
        }

        // Séparer en utilisant le séparateur détecté
        const [uuid, id, temper] = param.split(separator);

        // Déterminer le type (in ou out) en fonction du séparateur
        const type = separator === "-in-" ? "in" : "out";

        const checkExist = await readShift(id);

        if (!checkExist.id || checkExist.candidate.id !== temper) {
          alert("Scanned Qr code is not valid ! 🔴");
          return;
        }

        const clockType = type === "in" ? "clock_in" : "clock_out";
        const clockData = checkExist[clockType];

        if (clockData.uuid === uuid) {
          clockData.status = Shift.OK;
          await updateShift(id, {
            [clockType]: clockData,
            status: type === "in" ? Shift.IN_PROGRESS : Shift.PENDING_PAYMENT,
          });
          alert(
            `Clock ${type} for ${checkExist.job.name} scanned with success ! 🟢`
          );
        }
      },
    };

    // Nettoyage lors du démontage du composant (bonne pratique)
    return () => {
      delete window.reactNativeFunctions; // Supprime la fonction de window pour éviter des fuites de mémoire
    };
  }, []);

  return (
    <>
      <div className="relative">
        <div>
          <Transition show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={() => setSidebarOpen(false)}
            >
              <TransitionChild
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </TransitionChild>

              <div className="fixed inset-0 flex">
                <TransitionChild
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <TransitionChild
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </TransitionChild>
                    <SidebarMobile setSidebarOpen={setSidebarOpen} />
                  </DialogPanel>
                </TransitionChild>
              </div>
            </Dialog>
          </Transition>
          <SidebarDesktop />
          <div className="lg:pl-72 ">
            <Header setSidebarOpen={setSidebarOpen} />

            <main
              className={`${
                ["/messages", "/candidate"].includes(location.pathname)
                  ? "pb-0"
                  : "pb-16"
              }  lg:pb-0`}
            >
              <div className="xl:pr-[21vw]">
                <div className="px-0 py-0 sm:px-7 lg:px-0 lg:py-0">
                  {infoShow && company && !company.user.visible && (
                    <WelcomeMessageCompany setInfoShow={setInfoShow} />
                  )}
                  {infoShow && datas && !datas.user.visible && (
                    <WelcomeMessageCandidate setInfoShow={setInfoShow} />
                  )}

                  {componnent.map((el) => {
                    if (
                      [Roles.member, Roles.company, Roles.manager].includes(
                        role
                      )
                    ) {
                      if (el.role === Roles.company) {
                        return el.componnent;
                      }
                    } else if (el.role === role) {
                      return el.componnent;
                    }
                    return null; // Retourner null si aucune condition n'est remplie
                  })}
                </div>
              </div>
            </main>

            <aside
              className={`fixed inset-y-0 right-0 hidden w-[21vw] -z-10 overflow-y-auto border-l border-gray-200  ${
                role === Roles.candidate && "py-0 px-4"
              }  sm:px-2 lg:px-0 xl:flex ${
                [Roles.company, Roles.manager, Roles.member].includes(role) &&
                "pt-[calc(4rem+10px)] px-2 "
              } `}
            >
              {role === Roles.candidate && <Map />}
              {[Roles.company, Roles.manager, Roles.member].includes(role) && (
                <Application />
              )}
            </aside>
          </div>
        </div>
        {componnentUtils.map((el) => {
          if ([Roles.member, Roles.company, Roles.manager].includes(role)) {
            if (el.role === Roles.company) {
              return el.componnent;
            }
          } else if (el.role === role) {
            return el.componnent;
          }
          return null; // Retourner null si aucune condition n'est remplie
        })}

        {[Roles.company, Roles.manager, Roles.member].includes(role) &&
          !["/messages", "/candidate"].includes(location.pathname) &&
          !applicationLaunch &&
          !candidateLaunch &&
          !shifPunch &&
          !notifOpen && <BottomMobilCompany setSidebarOpen={setSidebarOpen} />}
        {role === Roles.candidate &&
          !["/messages"].includes(location.pathname) &&
          !filterOpen &&
          !notifOpen && (
            <BottomMobileCandidate setSidebarOpen={setSidebarOpen} />
          )}
        <SpinnerLoad />
      </div>
    </>
  );
}
